$color-blue: #4e71ff;
$color-text: #3f4956;
$color-p-label: #222222;
$color-text-input: #212129;
$color-icon: #c1c7d3;
$color-backgroung-button: #f2f4f7;
$color-dropdown-btn: #aab4c7;
$color-background-disabled: #f2f4f7;

// $color-form-border: #e6e9ef;
$color-form-border: #d5dbe7;

$color-border-recip: #c2cadb;
$color-border-line: #c2cadb;
$color-border: #cdd3df;
$color-border-finalpage: #c2cadb;
$color-text-system-msg: #727989;
$color-icon-hover: #727989;
$color-icon-regular: #bac2d4;
$color-placeholder: #a9afbd;

$mobile-breakpoint: 767px;
$tablet-breakpoint: 1111px;
$normal-breakpoint: 1390px;
$large-breakpoint: 1500px;
$x-large-breakpoint: 1700px;
