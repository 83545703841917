.form7-personal-company {
  &.update-cmps {
    overflow-y: hidden;
    display: contents !important;
  }
  &.page-1 {
    .add-recipient {
      margin: 10px 45px;
      padding: 25px 0;
      border-top: 1px solid #c2cadb;
      @include larger-than-tablet {
        // margin-top: 20px;
      }
      align-self: right;
      display: flex;
      align-items: center;
      .tooltip {
        display: flex;
        flex-direction: row;
        .top {
          top: -15px;
          right: -35px;
          opacity: 1;
        }
      }
      h3 {
        margin: 0 10px;
        font-size: 15px;
        @include larger-than-tablet {
          font-size: 25px;
          font-weight: 600;
        }
      }
    }
  }
  .input-container {
    .ContactList-container {
      width: 100%;
      @include larger-than-mobile {
        width: 300px;
      }
    }
  }
  .dropdown-address-container {
    width: 100%;
    @include larger-than-mobile {
      width: 300px;
    }
    .dropdown-list {
      width: 100%;
      @include larger-than-mobile {
        width: 300px;
      }
    }
  }
  .extra-height {
    input[type="text"] {
      @include larger-than-mobile {
        width: 300px;
      }
    }
  }
  h2 {
    align-self: right;
  }

  &.self-form-style {
    .modal-content-container {
      padding: 0;
    }
    h2.large-title {
      align-self: flex-start;
      margin-top: 30px;
      font-size: 30px;
      margin-bottom: 0;
    }
  }
  p {
    font-size: 19px;
    opacity: 0.65;
    &.error-label {
      font-size: 12px;
      @include larger-than-mobile {
        font-size: 15px;
      }
    }
    color: #222222;
    // margin-bottom: 20px;
  }

  .tooltip {
    .top {
      opacity: 1;
      font-size: 19px;
    }
  }

  .field {
    // width: 100%;
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }

    .icon-input {
      input {
        font: 400 15px "Almoni";
      }
    }

    &.manager-field {
      @include larger-than-mobile {
        width: 270px;
        input[type="text"] {
          width: 270px;
        }
      }
    }
    &.small-update {
      width: 80px;
      @include larger-than-mobile {
        width: 90px;
      }
    }
  }
  &.large {
    @include larger-than-mobile {
      width: 300px;
    }
  }
  .comments-container {
    flex-direction: column;
    grid-row-gap: 0px;
    width: 100%;
    @include larger-than-mobile {
      width: unset;
    }
    .field {
      height: fit-content;
    }
  }
  .checkboxes {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #c2cadb;
    width: 100%;
    padding: 20px 0;
    // margin-bottom: 20px;
    @include larger-than-mobile {
      flex-direction: row;
    }
    label {
      margin-bottom: 0;
    }
    .tooltip {
      .top {
        opacity: 1;
      }
    }
    .evening-action {
      flex-direction: column;
      grid-row-gap: 0px;
      justify-content: center;
      width: 100%;
      font-family: "AlmoniMedium";
      .tooltip {
        .top {
          top: -15px;
        }
      }
      .checkbox-container {
        border-bottom: 1px solid #e6e9ef;
        padding-bottom: 10px;
      }
      @include larger-than-mobile {
        width: auto;
        margin-top: 0;
        label {
          width: max-content;
        }
        .checkbox-container {
          border-left: 1px solid #c2cadb;
          margin-left: 15px;
          padding-left: 15px;
          border-bottom: unset;
          // margin-bottom: unset;
          padding-bottom: unset;
          .checkbox-content {
            .regular {
              font-family: "Almoni";
            }
          }
        }
      }
      label {
        margin-top: 0;
        font-size: 19px;
      }
      .field {
        height: fit-content;
      }
    }
  }
  .save-contact-container {
    flex-direction: column;
    grid-row-gap: 0px;
    width: 100%;
    display: inline-block;
    margin-top: 20px;
    .tooltip {
      .top {
        top: -15px;
        right: -40px;
        opacity: 1;
      }
    }
    @include larger-than-mobile {
      margin-top: 0;
    }
    .save-contact-checkbox {
      padding-left: 10px;
    }
    label {
      margin-top: 0;
    }
    .field {
      height: fit-content;
    }
  }

  .form-main-toggle {
    --btn-height: 60px;
  }
  p {
    &.regular {
      margin-bottom: 10px;
    }
  }
}
