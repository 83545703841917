.icon-wrapper {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: relative;
    background: white;
    background-color: #4e71ff;
    // display: none;

    .UserMsgs-container {
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        &.open {
            height: auto;
        }

        .notifications-list {
            position: absolute;
            z-index: 1;
            padding-top: 0;
            height: 0;
            opacity: 0;
            transition:
                height,
                opacity 0.3s ease-in-out,
                box-shadow 0.3s ease-in-out;
            .notifications-header {
                display: none;
            }

            &.open {
                background: white;
                box-shadow:
                    0px 0px 10px rgba(0, 0, 0, 0.1),
                    0px 2px 4px rgba(0, 0, 0, 0.1);
                height: auto;
                opacity: 1;
                padding-top: 20px;
                .notifications-header {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                    .svg-div {
                        display: flex;
                        border-radius: 4px;
                        justify-content: center;
                        align-items: center;
                        padding: 0px;
                        width: 28px;
                        height: 28px;
                        margin-left: 15px;
                        &:hover {
                            background-color: #dcdfec;
                        }
                        img {
                            height: 16px;
                            width: 16px;
                        }
                    }
                }
            }
            .notifications-list-title {
                border-bottom: none;
                display: none;
                &.is-visible {
                    display: block;
                }
            }

            .action-info-msg-list {
                z-index: 1;
                position: absolute;
                background: white;
                padding: 10px -16px;
                // width: 420px;
                width: 100%;
                padding: 0 30px;
                padding: 0;
                top: 70px;
                right: 0;
                // max-width: 470px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                height: 0;
                opacity: 0;
                transition: box-shadow 0.3s ease-in-out;

                &.is-visible {
                    height: calc(var(--app-height) / 0.8 - 60px - 260px - 65px);
                    opacity: 1;
                    box-shadow:
                        0px 0px 10px rgba(0, 0, 0, 0.1),
                        0px 2px 4px rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}

.icon-wrapper::after {
    content: attr(data-number);
    width: 19px;
    height: 19px;
    background-color: #fc486c;
    // background-color: #d32b2b;
    color: #fff;
    display: grid;
    place-content: center;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    right: -5px;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
    pointer-events: none;
    @include larger-than-mobile {
        padding-top: 2px;
    }
}

.icon-wrapper.big::after {
    width: auto;
    padding: 2px 3px;
    top: -8px;
    right: -16px;
}

.icon-wrapper[data-number="0"]:hover::after,
.icon-wrapper[data-number="0"]::after {
    opacity: 0;
    transform: translateY(0);
    transition:
        opacity 0.25s,
        transform 0.25s;
    display: none;
}

.icon-wrapper::after {
    opacity: 1;
    transform: translateY(0);
    transition:
        opacity 0.25s,
        transform 0.25s;
}

.bell-icon {
    max-width: 100%;
}

.icon-wrapper:hover .bell-icon {
    animation: shake 1s forwards;
}

.shake {
    animation: shake 1s forwards;
}

@keyframes shake {
    10% {
        transform: rotate (15deg);
    }
    20% {
        transform: rotate(-15deg);
    }
    30% {
        transform: rotate (15deg);
    }
    50% {
        transform: rotate (0deg);
    }
}
