.recipients {
  h2.large-title {
    @include larger-than-tablet {
      margin: 30px;
    }
  }
  h2.title {
    text-align: center;
  }

  h3 {
    font-weight: 500;
  }

  .input-container {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 15px;
    position: relative;
    flex-direction: column;
    background-color: white;
    @include larger-than-tablet {
      background-color: transparent;
    }
    label {
      display: inline-block;
      width: fit-content;
    }
    grid-row-gap: 0px;

    @include larger-than-tablet {
      box-shadow: none;
      border-radius: unset;
      border-bottom: 1px solid $color-border-recip;
      padding: unset;
      flex-direction: row;
      label {
        display: inline-block;
      }
      grid-row-gap: 12px;
    }

    flex-wrap: nowrap;
    width: 100%;

    @include larger-than-tablet {
      // width: fit-content;
      justify-content: space-between;
      align-items: center;
    }
    margin-bottom: 10px;
    &:first-of-type {
      @include larger-than-tablet {
        border-top: 1px solid $color-border-recip;
      }
    }

    // padding-bottom: 10px;
    display: flex;
    .btn-container {
      flex-direction: row;
    }
    .field {
      margin-top: 20px;
      flex-direction: row;
      width: 100%;
      // align-items: center;
      @include larger-than-tablet {
        flex-direction: column;
      }
      &:first-child {
        input {
          width: fit-content;
          opacity: 1;
        }
      }
      input {
        color: #727989;
        // opacity: 0.65;
        border: none;
      }
    }

    .btn-container {
      display: flex;
      position: absolute;
      top: 10px;
      left: 5px;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 0px;
      margin-right: 0px;

      @include larger-than-tablet {
        position: relative;
        margin-right: 10px;
      }
      img {
        cursor: pointer;
        width: 25px;
        aspect-ratio: 1;
        @include larger-than-tablet {
          width: 35px;
          aspect-ratio: 1;
        }
        aspect-ratio: 1;

        &:hover {
          transform: scale(0.95);
        }
      }
      .icon-edit {
        margin-left: 15px;
      }
    }
    input {
      &.disabled {
        height: unset;
        padding: 0;
        @include larger-than-tablet {
          &.small {
            width: 120px;
          }
          background-color: $color-background-disabled;
          border: 1px solid $color-form-border;
          width: 280px;
          height: 60px;
          padding: 25px;
        }
        &.long {
          @include larger-than-tablet {
            width: 300px;
          }
        }
      }
    }
    .field {
      height: unset;
      padding: 0;
      margin: 0;
      align-items: center;
      // background: lightcoral;
      @include larger-than-tablet {
        height: 120px;
        margin-top: 20px;
        align-items: unset;
      }
      label {
        margin: 0;
        @include larger-than-tablet {
          margin: 3px;
        }
      }
      input {
        margin-right: 7px;
        @include larger-than-tablet {
          margin-right: 0;
        }
        color: #727989;
        border: none;
        width: fit-content;
      }
      span {
        color: #727989;
        font-size: 15px;
        border: none;
      }
      &:first-child {
        input {
          opacity: 1;
          @include larger-than-tablet {
            color: #727989;
          }
        }
      }

      // &:last-child {
      //   cursor: pointer;
      //   h3 {
      //     margin: 0;
      //     margin-right: 15px;
      //   }

      //   margin-top: 20px;
      //   display: flex;
      //   align-items: center;
      // }
    }
  }
  .add-recipient {
    @include larger-than-tablet {
      margin-top: 20px;
    }
    align-self: right;
    display: flex;
    align-items: center;

    h3 {
      margin: 0 10px;
      font-size: 15px;
      @include larger-than-tablet {
        font-size: 25px;
        font-weight: 600;
      }
    }
  }
}
