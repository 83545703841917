.personal-delivery {
  h2 {
    margin-top: 20px;
    @include larger-than-mobile {
      margin-top: 50px;
    }
    margin-bottom: 20px;
  }
  p.subtitle {
    margin-bottom: 5px;
    &:last-of-type {
      margin-bottom: 25px;
    }
  }
}
