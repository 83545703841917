.form7-addresses {
  display: flex;
  flex-direction: column;

  h2 {
    align-self: center;
    margin-top: 30px;
  }

  h3 {
    @include larger-than-mobile {
      margin-top: 20px;
    }
  }

  label {
    margin-bottom: 3px;
  }

  .input-container {
    flex-wrap: wrap;
    // border-bottom: 1px solid $color-border;
    @include larger-than-tablet {
      flex-wrap: nowrap;
    }
    &:first-of-type {
      // padding: 15px;
      border-top: 1px solid $color-border;
      // border-bottom: 1px solid $color-border;
    }

    &:last-child {
      margin-top: 0;
      // padding-top: 0;
    }

    justify-content: space-between;
    border-bottom: 1px solid $color-border;
    padding: 15px 0 0 0;

    .radio-btn {
      width: 20px;
      aspect-ratio: 1;
      transform: translateY(50%);
    }

    &:first-of-type {
      border-top: 1px solid $color-border;
    }
    &:last-child {
      border-bottom: unset;
    }

    .disabled {
      background-color: $color-background-disabled;
      border: 1px solid $color-form-border;
      font-size: 15px;
      @include larger-than-mobile {
        font-size: 20px;
      }
    }

    .med {
      width: 100%;
      @include larger-than-mobile {
        width: 260px;
      }
      @include larger-than-normal {
        width: 285px;
      }
    }

    .small {
      @include larger-than-mobile {
        max-width: 115px;
      }
    }
  }
}

.new-address {
  margin: 15px 0;
  h3 {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
  }
  display: flex;
  @include larger-than-tablet {
    display: inline-block;
  }
  align-items: center;
  // justify-content: center;
  gap: 15px;
  .radio-btn {
    width: 20px;
    height: 20px;
  }
}

.mobile-address-container {
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  margin: 10px 0;
  box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);
  border-radius: 10px;
  p {
    opacity: 0.65;
    font-size: 14px;
    &:first-child {
      opacity: 1;
    }
    line-height: 1.2;
  }

  .radio-btn {
    width: 20px;
    height: 20px;
  }
}
