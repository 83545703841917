.progress-bar {
  display: flex;
  align-items: center;

  .step {
    display: flex;
    max-width: 250px;
    flex-direction: column;
    margin-left: 5px;
    width: 0;
    flex-grow: 1;

    > div {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .step-circle {
        max-width: 20px;
        flex-grow: 1;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: #bccee8;
        //   margin-bottom: 6px;
        margin-left: 5px;

        &.complete {
          background-color: $color-blue;
        }
      }
      .line {
        flex-grow: 9;
        height: 1px;
        border-bottom: 1px dashed #bccee8;

        &.complete {
          border-bottom: 1px solid $color-blue;
        }
      }
    }

    &:last-child {
      .step-circle {
        width: 10%;
        flex-grow: unset;
      }
      .line {
        display: none;
        // border-color: transparent;
      }
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 12px;
      @include larger-than-mobile {
        font-size: 17px;
      }
      @include larger-than-normal {
      }
    }
  }

  @include larger-than-normal {
    // margin-right: 20px;

    .step {
      width: 120px;
      flex-grow: unset;
    }
  }
  @include larger-than-large {
    .step {
      width: 150px;
    }
  }
}

.progress-bar-mobile {
  display: flex;
  justify-content: center;
  border-top: 1px solid #e6e9ef;
  border-bottom: 1px solid #e6e9ef;
  padding: 10px 25px 5px 0;
}
