.form7-urgency {
  &.form7-urgency-update {
    min-height: 270px;
    display: contents !important;
  }
  &.in-form {
    .modal-content-container {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
  h2.large-title {
    margin-bottom: 25px;
    @include larger-than-mobile {
      margin-bottom: 40px;
    }
  }
  p.regular {
    margin-top: 10px;
    text-align: center;
    opacity: 0.65;
    &.very-bold {
      opacity: 0.9;
      font-family: "AlmoniDemiBold";
    }
  }
  .label {
    max-width: 480px;
    opacity: 0.65;
    &.apostil-label {
      margin-top: 10px;
      font-size: 18px;
      opacity: 0.8;
      font-family: "AlmoniBold";
    }
  }
  .tooltip-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    .top {
      top: -15px;
      left: -50px;
    }
    .btn.info {
      font-family: "Almoni";
      height: 35px;
      background-color: transparent;
      border: 1px solid $color-icon-regular;
      padding: 5px 10px;
      border-radius: 7px;
      white-space: nowrap;
      font-size: 15px;
      &:hover {
        border: 1px solid $color-text;
      }
    }
    .tooltip {
      .top {
        opacity: 1;
      }
    }
  }
  .second-alignment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
    padding-bottom: 30px;
    h2.large-title {
      max-width: 100%;
    }
  }
  .extra-handling-urgency {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    margin-top: 20px;
    width: 100%;
    border-top: 1px solid #c2cadb;
    .text-toggle {
      align-self: center;
      .block-select {
        margin-left: 0;
        .form-main-toggle {
          margin: 0;
          --btn-width: 175px;
          --btn-height: 65px;
          span {
            border-radius: 100vw !important;
          }
          @include larger-than-mobile {
            --btn-width: 175px;
            --btn-height: 70px;
            border-radius: 100vw !important;
          }
        }
      }
    }
    .extra-handling-text {
      font: 700 35px "AlmoniDemiBold";
      margin-bottom: 0px;
      margin-top: 20px;
    }
    .main-btn {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
    }
  }
}
