.form-main-toggle {
  align-self: center;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  --btn-width: 160px;
  --btn-height: 60px;

  &.toggle-three {
    --btn-width: 110px;
    @include larger-than-mobile {
      --btn-width: 160px;
    }
  }
  &.large {
    --btn-width: 110px;
    @include larger-than-mobile {
      --btn-width: 185px;
    }
  }
  &.x-large {
    --btn-width: 120px;
    @include larger-than-mobile {
      --btn-width: 200px;
    }
  }
  &.xx-large {
    --btn-width: 140px;
    @include larger-than-mobile {
      --btn-width: 250px;
    }
  }

  width: fit-content;
  position: relative;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;

  border-radius: 100vw !important;
  // overflow: hidden;
  border: 1px solid $color-border;

  &.small {
    --btn-width: 215px;
    --btn-height: 60px;
  }

  &.left {
    span {
      right: var(--btn-width);
    }
    button:nth-child(3) {
      color: #fff;
    }
  }

  &.right {
    span {
      right: 0;
    }
    button:nth-child(2) {
      color: #fff;
    }
  }
  @include larger-than-mobile {
    --btn-width: 201px;
    --btn-height: 80px;
  }

  &.end {
    span {
      right: calc(var(--btn-width) * 2);
    }

    button:nth-child(4) {
      color: #fff;
    }
  }

  span {
    background-color: $color-blue;
    border-radius: 100vw !important;
    width: var(--btn-width);
    height: var(--btn-height);
    position: absolute;
    z-index: 0;
    right: 0px;
    transition: 0.1s;
    &:active {
      border: 0;
    }
  }

  button {
    color: $color-text;
    background: transparent;
    border: none;
    width: var(--btn-width);
    height: var(--btn-height);
    display: flex;
    align-items: center;
    justify-content: center;
    font: 400 17px "Almoni";
    z-index: 0;
    transition: 0.1s;
    &:active {
      border: 0;
    }

    @include larger-than-mobile {
      font: 400 21px "Almoni";
    }
  }
}
