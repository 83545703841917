.welcome {
    height: 100%;
    width: 100%;
    @include larger-than-mobile {
        border-radius: 40px;
        min-height: 750px;
        width: 650px;
    }
    position: relative;
    > svg {
        position: absolute;
        z-index: 1;
        top: 35px;
        left: 35px;
        cursor: pointer;
    }
    .system-msg {
        position: relative;
        min-height: 880px;
        // background: url("../../images/6464.png"), linear-gradient(180deg, #edf3fc, #fff, #fff);
        background: linear-gradient(180deg, #edf3fc, #fff, #fff);
        @include larger-than-mobile {
            min-height: 880px;
            width: fit-content;
        }
        background-size: 100% 100%;

        background-color: #fff;
        background-repeat: no-repeat;
        border: 1px solid $color-border;
        padding: 0 30px;
        border-radius: none;
        @include larger-than-mobile {
            border-radius: 40px;

            // padding: 30px 50px;
        }
        width: 100%;
        height: 100%;
        @include larger-than-mobile {
            height: 100%;
            width: 100%;
        }
        display: flex;
        padding-top: 120px;
        justify-content: flex-start;
        flex-direction: column;

        @include larger-than-mobile {
            // justify-content: center;
        }
        align-items: center;
        gap: 15px;
        box-shadow: 5px 5px 10px 1px #0002;
        .btn-close {
            width: 40px;
            aspect-ratio: 1;
            cursor: pointer;
            position: absolute;
            z-index: 5;
            top: 20px;
            left: 20px;
        }
        p {
            font-size: 17px;
        }
        .link {
            text-decoration: underline;
            cursor: pointer;
            margin-top: 15px;
        }
        .msg-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            // margin: 15px 0;
            gap: 10px;
            @include larger-than-mobile {
                gap: 20px;
                // margin-top: 90px;
            }
            // margin-top: 130px;
            .main-title {
                text-align: center;
                font: 700 35px"Almoni";
                margin-bottom: 10px;
                @include larger-than-mobile {
                    font: 700 50px"Almoni";
                    margin-bottom: 20px;
                }
            }
            h3 {
                font-size: 40px;
                text-align: center;
                margin: 0;
            }
            p.decription {
                opacity: none;
                font-size: 19px;
                text-align: center;
                color: #000;
                margin-bottom: 20px;
                > span {
                    margin-right: 8px;
                }
            }
            .add-address {
                display: flex;
                flex-direction: column;
                text-align: -webkit-center;
                h4 {
                    font-size: 20px;
                    text-align: right;
                    padding-bottom: 15px;
                    font: 600 19px"Almoni";
                    @include larger-than-mobile {
                        padding-bottom: 20px;
                        font: 600 21px"Almoni";
                    }
                }
                button {
                    font-size: 21px;
                    align-self: center;
                }
                .fields {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    // justify-content: center;
                    justify-content: space-between;
                    margin-bottom: 0;
                    @include larger-than-mobile {
                        margin-bottom: 30px;
                        width: 600px;
                    }
                    .field {
                        width: 100%;
                        // margin-left: 10px;

                        @include larger-than-mobile {
                            width: unset;
                            height: 120px;
                        }
                        height: 120px;
                    }
                    .error-label {
                        text-align: right;
                    }
                    .field.extra-height {
                        height: 140px;
                    }
                    .field.small {
                        width: 90px;
                        height: 120px;
                    }
                    // .field.large {
                    //     width: 300px;
                    //     height: 120px;
                    // }
                    .dropdown-address-container {
                        width: 100%;
                        @include larger-than-mobile {
                            width: 295px;
                        }
                        .dropdown-input {
                            width: 100%;
                            @include larger-than-mobile {
                                width: 295px;
                            }
                        }
                    }
                    .checkbox-container {
                        width: 100%;
                        margin-top: 5px;
                        margin-right: 5px;
                        .checkmark {
                            top: 0;
                        }
                        // margin-bottom: 25px;
                    }
                    input {
                        width: 100%;
                        @include larger-than-mobile {
                            width: 295px;
                        }
                        &.small {
                            width: 100%;
                            @include larger-than-mobile {
                                width: 86px;
                            }
                        }
                    }
                }
            }
        }
    }
}
