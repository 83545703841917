.delivery-package-size {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    width: 100%;
    padding: 20px 0;
    .titles {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        @include larger-than-mobile {
            gap: 20px;
            flex-direction: row;
            margin-bottom: 10px;
        }
        h3 {
            font-size: 24px;
            margin-bottom: 0;
        }
    }
    .styled-choises {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        margin-bottom: 10px;
        @include larger-than-mobile {
            flex-direction: row;
        }
        .tooltip {
            .top {
                right: 0;
                top: -15px;
                .triangle {
                    left: 80%;
                }
            }
        }
    }
}
