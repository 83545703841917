.sidebar {
  border-left: 1px solid $color-form-border;
  display: none;
  @include larger-than-tablet {
    display: block;
    width: 450px;
  }
}

.sidebar-content {
  position: sticky;
  top: 0;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @include larger-than-tablet {
    width: 420px;
  }
  .inner-container {
    width: 100%;
    padding: 40px 30px;
  }
  .sidebar-title {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: right;
    gap: 15px;
    text-align: right;
    margin-bottom: 25px;
    h3 {
      font-size: 25px;
    }
    img {
      width: 30px;
    }
  }

  .orders-preview-container {
    width: 100%;
    height: 250px;
    @include larger-than-mobile {
      height: 337px;
    }
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    .order-preview {
      width: 100%;
      border: 1px solid $color-form-border;
      border-radius: 15px;
      padding: 15px 15px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      // margin-bottom: 40px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        gap: 10px;
        > div {
          display: flex;
          align-items: center;
          gap: 10px;
          .icon {
            height: 36px;
          }
          h4 {
            font-size: 16px;
          }
        }
        .btn-container {
          display: flex;
          justify-content: left;
          gap: 7px;
          svg {
            cursor: pointer;
            &:hover {
              path {
                fill: $color-icon-hover;
              }
            }
          }
        }
      }
      .order-summary {
        border-bottom: 1px solid $color-form-border;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .summary-item {
        display: flex;
        justify-content: space-between;
        &:last-child {
          padding-bottom: 10px;
        }
        .item-title {
          font: 600 14px "Almoni";
          @include larger-than-mobile {
            font: 600 15px "Almoni";
          }
        }
        p {
          font: 400 14px "Almoni";
        }
      }
      .summary-total {
        display: flex;
        justify-content: space-between;
        .item-title {
          font: 600 14px "Almoni";
          @include larger-than-mobile {
            font: 600 15px "Almoni";
          }
        }
        p {
          font: 500 15px "Almoni";
        }
      }
    }
  }
  .empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    align-self: center;
    width: 80%;
    text-align: center;
    gap: 10px;
    color: #37383f;
    h3 {
      font-size: 25px;
      font-family: "AlmoniDemiBold";
    }
    p {
      font-size: 20px;
    }
  }
  .cart-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    position: relative;
  }
  .badge {
    position: absolute;
    font-size: 10px;
    line-height: 1.6;
    top: 13px;
    right: 0px;
    width: 14px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: $color-text;
    color: white;
  }

  .cart-summary {
    text-align: center;
    .total {
      border-top: 1px solid $color-form-border;
      border-bottom: 1px solid $color-form-border;
      padding: 15px 0;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      .total-item {
        display: flex;
        justify-content: space-between;

        p.title {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
    .total-final {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      > p {
        font-size: 19px;
      }
    }
    .security-note {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      > div {
        display: flex;
        gap: 5px;
        p {
          font-size: 13px;
        }
      }
      p {
        font-size: 10px;
      }
    }
  }
}
