.checkout-users-dropdown {
    width: 100%;
    @include larger-than-mobile {
        width: 325px;
    }
    .dropdown-input {
        // justify-content: flex-end;
        // padding-right: 25px;
    }
    .dropdown-list {
        width: 100%;
        .dropdown-option {
            justify-content: flex-start;
            label {
                font-size: 17px;
                font-family: "AlmoniMedium";
            }
            input {
                height: 15px;
            }
            &.users {
                input {
                    height: 15px;
                    width: 25px;
                    margin-right: 5px;
                }
            }
        }
    }
}
