.document-ready {
  h2.large-title{
    margin-bottom: 40px;
  }
  p.subtitle {
    @include larger-than-mobile {
      margin-bottom: 15px;
    }
  }
  h3 {
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 60px;
  }

  .input-date {
    width: 240px;
    margin-bottom: 60px;
  }
  // .date-picker {
  //   margin-bottom: 50px;
  // }
  // .MuiOutlinedInput-root {
  //   border-radius: 15px;
  // }
}
// .date-picker-radius {
//   border-radius: 15px;
// }
// .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.css-1smlk3z-MuiPaper-root {
//   border-radius: 20px;
// }
