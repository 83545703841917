//INPUTS

.input-label-container {
  display: flex;
  flex-direction: column;

  label {
    display: block;
  }

  input {
    font: 400 15px "Almoni";
    @include larger-than-mobile {
      font: 400 19px "Almoni";
    }
    color: $color-text-input;
    border: 1px solid $color-form-border;
  }
  .courtname {
    padding-inline-end: 65px;
  }
}

//FORM-INPUTS

.input-container {
  display: flex;
  flex-wrap: wrap;

  // flex-wrap: nowrap;
  @include larger-than-mobile {
    // flex-wrap: nowrap;
  }
  grid-column-gap: 13px;
  grid-row-gap: 12px;

  .input-label {
    font-size: 20px;
    // @include larger-than-mobile {
    //   font-size: 17px;
    // }
    // margin-bottom: 5px;
    color: #222222;
  }

  .field {
    width: 100%;
    @include larger-than-mobile {
      width: fit-content;
    }
    margin-bottom: 10px;
    p {
      font-size: 15px;
      margin-bottom: 0;
      margin-top: 2px;
    }
  }
}
.label-with-info {
  margin-bottom: 2.5px;
  label {
    margin: 0;
  }
}

// INPUT WITH ICON FOR TOOLTIP
.field {
  height: 110px;
  @include larger-than-mobile {
    height: 130px;
  }
  display: flex;
  flex-direction: column;
  width: fit-content;
  &.small {
    width: 85px;
    height: 125px;
    margin-bottom: 10px;
    > p {
      line-height: 1.2em;
    }
    @include larger-than-mobile {
      width: 116.5px;
      height: 150px;
    }
  }
  &.extra-height {
    height: 150px;
  }

  .label-with-info {
    font-size: 16px;
    @include larger-than-mobile {
      font-size: 15px;
      margin-bottom: 5px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

//ICON-INPUT

.icon-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  overflow: hidden;

  border: 1px solid $color-form-border;
  border-radius: 15px;
  &:focus-within,
  &:hover {
    border: 1px solid black;
  }

  @include larger-than-mobile {
    width: 300px;
  }

  input,
  .input {
    border: none;
    border-radius: 0 15px 15px 0;
    @include larger-than-mobile {
      font: 500 18px "Almoni";
      // height: 60px;
    }
    width: 100%;
    padding: 0 15px;
    text-align: right;
    cursor: text;
    &:focus-within,
    &:hover {
      border: none;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 15px;
    border-right: 1px solid $color-form-border;
    background-color: white;
  }
}

//INPUT SIZE

input {
  &.large {
    width: 350px;
    @include larger-than-mobile {
      width: 588px;
    }
  }
  &.small {
    width: 80px;
    @include larger-than-mobile {
      width: 116.5px;
    }
  }
  &.small-update {
    width: 80px;
    @include larger-than-mobile {
      width: 90px;
    }
  }
}

//ERROR / SUCCESS

.success {
  background: url(../../../images/success.svg) no-repeat scroll 5px 5px;
  @include larger-than-mobile {
    background: url(../../../images/success.svg) no-repeat scroll 5px 50%;
  }
  padding-left: 30px;
}

.error {
  // background-color: #f7f9fa;
  // border: 1px solid #dd2b30;
  background: url(../../../images/error-icon.svg) no-repeat scroll 5px 5px #f7f9fa;
  @include larger-than-mobile {
    background: url(../../../images/error-icon.svg) no-repeat scroll 5px 50%;
  }
  padding-left: 30px;
}

input:-internal-autofill-selected {
  background-color: red !important;
}

.courtname {
  &:hover {
    border: 1px solid $color-text;
  }
}

/* Chrome, Safari, Edge, Opera Remove arrows from input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox Remove arrows from input type number*/
input[type="number"] {
  -moz-appearance: textfield;
}
