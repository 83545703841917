.form7-deliveryAfter {
  h2 {
    @include larger-than-mobile {
      margin-top: 40px;
    }
  }
  .first-toggle {
    margin: 10px 0 20px 0;
    @include larger-than-mobile {
      margin: 0px 0 50px 0;
    }
    align-self: center;
  }
  h3 {
    margin-bottom: 0;
    margin-bottom: 8px;
    font: 700 30px "AlmoniDemiBold";
    text-align: center;
    @include larger-than-mobile {
      font: 700 38.5px "AlmoniDemiBold";
    }
  }

  p.subsubtitle {
    margin-bottom: 10px;
  }
  p.subtitle {
    margin-bottom: 10px;
  }

  .delivery-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    min-height: 130px;
    width: 100%;
    padding: 35px 0;
    &.border-top {
      border-top: 1px solid $color-form-border;
    }
  }

  .radio-btn-choice {
    padding-top: 15px;
    border-top: 1px solid $color-form-border;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    h3 {
    }
    input {
      height: unset;
    }
    label {
      font-size: 16px;
      opacity: 1;
      margin: 0;
    }
    .radio-btn-container {
      display: flex;
      flex-direction: row;
      justify-content: right;
      align-items: center;
      gap: 10px;
    }
    input[type="radio"] {
      width: 25px;
      height: 25px;
      padding: 0;
    }
  }
}
