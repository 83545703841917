.pop-up-login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
  backdrop-filter: blur(3px);

  &.slowly {
    animation: fade-in 2s forwards;
    @keyframes fade-in {
      0% {
        background-color: rgba(0, 0, 0, 0);
      }
      100% {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    .container {
      animation: fade-in 2s forwards;
      @keyframes fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  &.create-user {
    background: rgba(0, 0, 0, 0.2);
    .container {
      .header-btns {
        justify-content: flex-end;
      }
      .content {
        .login-step {
          form {
            .main-title {
              text-align: center;
              font-size: 30px;
              @include larger-than-mobile {
                font-size: 40px;
              }
            }
          }
        }
      }
    }
  }

  .container {
    position: relative;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #edf3fc, #fff, #fff);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }

    @include larger-than-mobile {
      border-radius: 40px;
      padding: unset;
      height: unset;
      width: unset;
    }

    .header-btns {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      padding: 40px 35px;
      &.first-login-header {
        justify-content: flex-end;
      }
      .login-btn {
        height: 50px;
        color: #37383f;
        font: 400 16px "Almoni";
        opacity: 0.75;
        background: transparent;
        border: 1px solid #c2cadb;
        border-radius: 15px;
        width: 150px;
        &:hover {
          transform: scale(0.9);
        }
      }

      .close-icon {
        cursor: pointer;
      }

      .back-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        width: 125px;
        height: 50px;
        background-color: transparent;
        border: 1px solid $color-icon-regular;

        .back-small-icon {
          height: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2.5px;
        }

        p {
          font-size: 16px;
          color: #96a1b8;
        }

        svg {
          // margin-top: 2px;
        }

        &:hover {
          border: 1px solid $color-icon-hover;
          .back-small-icon {
            path {
              stroke: $color-icon-hover;
            }
          }
          p {
            color: $color-icon-hover;
          }
        }
      }
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 0 35px;

      &::-webkit-scrollbar {
        display: none;
      }

      @include larger-than-mobile {
        border-radius: 40px;
        min-height: 750px;
        width: 650px;
        &::-webkit-scrollbar {
          display: inline-block;
          background: transparent;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
      }

      &.blocked-msg {
        display: flex;
        flex-direction: column;
        gap: 30px;
        // justify-content: center;
        align-items: center;
        .msg-content {
          .main-title {
            text-align: center;
            margin-bottom: 15px;
          }
          .decription {
            font-size: 19px;
            text-align: center;
            color: #000;
            margin-bottom: 20px;
          }
        }
      }

      .red-terms {
        color: red;
      }

      .login-step {
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
        padding-block-end: 24px;
        @include larger-than-mobile {
          border-radius: unset;
        }
        &.forgot-password {
          gap: 30px;
          > div.forgot-password-header {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            .main-title {
              text-align: center;
            }
            > p {
              font-size: 20px;
            }
          }
        }

        .back-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 7px;
          @include larger-than-mobile {
            border-radius: 15px;
          }
          width: 80px;
          height: 30px;
          @include larger-than-mobile {
            width: 125px;
            height: 50px;
          }
          background-color: transparent;
          border: 1px solid $color-icon-regular;
          p {
            font-size: 12px;
            color: #96a1b8;
            @include larger-than-mobile {
              font-size: 16px;
            }
          }
          .back-small-icon {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &:hover {
            border: 1px solid $color-icon-hover;
            .back-small-icon {
              path {
                stroke: $color-icon-hover;
              }
            }
            p {
              color: $color-icon-hover;
            }
          }
        }
        // .login-signup {
        //   height: 50px;
        //   color: #37383f;
        //   font: 400 16px "Almoni";
        //   opacity: 0.75;
        //   background: transparent;
        //   border: 1px solid #c2cadb;
        //   border-radius: 15px;
        //   width: 150px;
        //   &:hover {
        //     transform: scale(0.9);
        //   }
        // }
        .separator {
          margin: 10px 0;
          display: flex;
          gap: 30px;
          justify-content: center;
          align-items: center;
          div.line {
            width: 200px;
            height: 1px;
            border-bottom: 1px solid #bccee8;
          }
        }
        .titles {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
        }
        .main-title {
          // margin-top: 40px;
          font-size: 40px;
          text-align: center;
          &.bold {
            font-weight: bold;
          }
        }
        .sub-title {
          font: 500 20px "AlmoniMedium";
        }
        .checkbox-container {
          input {
            position: unset;
          }
        }
        .main-btn.long.option {
          background-color: transparent;
          color: #37383f;
          border: 1px solid #c2cadb;
          display: flex;
          gap: 10px;
          justify-content: center;
          align-items: center;
        }
        .main-btn.long.option.facebook {
          background-color: $color-blue;
          color: white;
        }
      }

      form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
        button.main-btn {
          &.long {
            margin-top: 20px;
          }
        }
        .signup-input {
          height: 50px;
          &::placeholder {
            opacity: 0.65;
          }
        }
        p {
          > span {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .register {
        margin-top: 20px;
      }
      .register-link {
        display: flex;
        gap: 10px;
        p {
          font-size: 17px;
        }
        .link {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .security-note {
        margin-top: 20px;
        margin-bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        > div {
          display: flex;
          grid-gap: 10px;
          gap: 10px;
          justify-content: center;
          align-items: center;
          width: 100%;
          p {
            font-size: 18px;
            width: 231px;
            @include larger-than-mobile {
              font-size: 21px;
            }
            font-weight: bold;
          }
          img {
            height: 20px;
          }
        }
      }
      .field {
        height: 70px;
        width: 100%;
        @include larger-than-mobile {
          width: 350px;
        }
        input {
          width: 100%;
        }
        input[type="number"] {
          @include larger-than-mobile {
            width: 350px;
          }
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
        // @include larger-than-mobile {
        //   width: unset;
        // }
        &.password-confirm-field {
          height: 100px;
        }
      }
    }
  }
  .select-account {
    width: 350px;
    height: 50px;
    border: 1px solid $color-form-border;
    border-radius: 15px;
    padding: 15px;
    option {
    }
  }

  .dropdown-container {
    align-self: flex-start;
    display: flex;
    position: relative;
    width: 100%;
    @include larger-than-mobile {
      width: 350px;
    }

    &.open {
      .dropdown-input button img {
        transform: scale(1, -1);
      }
      .dropdown-list {
        width: 100%;
        z-index: 1;
        @include larger-than-mobile {
          width: 350px;
        }
        height: 150px;
        border: 1px solid $color-form-border;
        box-shadow: 5px 5px 10px 1px #0001;
        border: 1px solid transparent;
      }
    }

    .dropdown-input {
      // position: absolute;
      width: 100%;
      padding-right: 15px;
      color: $color-text;
      opacity: 1;
      cursor: pointer;
      @include larger-than-mobile {
        width: 350px;
      }
      right: 0;
      display: flex;
      height: 55px;
      border: 1px solid $color-form-border;
      justify-content: space-between;
      align-items: center;
      border-radius: 15px;
      overflow: hidden;
      &:hover,
      :active {
        border: 1px solid black;
      }
      .dropdown-text {
        opacity: 0.65;
      }

      button {
        > img {
          margin: 0;
        }
        width: 50px;
        align-self: stretch;
        border: none;
        background-color: #f6f7f9;
        opacity: 0.5;
        border-right: 1px solid $color-form-border;
      }
    }

    .dropdown-list {
      overflow-y: scroll;
      position: absolute;
      right: 0;
      transform: translateY(60px);
      transform-origin: bottom center;
      display: flex;
      flex-direction: column;
      width: 350px;
      border-radius: 15px;
      height: 0;
      overflow: hidden;
      transition: height 0.1s;
      background-color: #fff;
    }

    .dropdown-option {
      display: flex;
      align-items: center;
      gap: 15px;
      cursor: pointer;
      padding: 15px;
      border-bottom: 1px solid $color-form-border;
      &:hover {
        background-color: $color-blue;
        color: white;
      }

      &:last-child {
        border-bottom: unset;
      }

      > * {
        width: unset;
      }

      input {
        height: unset;
      }
      input[type="radio"] {
        width: 20px;
        height: 20px;
        padding: 0;
      }

      label {
        margin: 0;
      }
    }
  }
  .separator-long {
    width: 70%;
    height: 1px;
    margin: 15px 0;
    border-bottom: 1px solid #e0e4e9;
  }
  .checkbox-container {
    width: 350px;
    gap: 7px;
    padding: 7px 0;
    margin: unset;
    .checkmark {
      top: 7px;
    }
    label {
      font-family: "Almoni";
      padding-inline-start: 25px;
      margin: 0;
      opacity: unset;
      line-height: 1;
      a {
        font-family: "Almoni";
        color: blue;
      }
    }
  }
  .forgot {
    width: 350px;
    display: flex;
    justify-content: space-between;
    p.forgot-link {
      font-size: 15px;
      text-decoration: underline;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 7px;
      label {
        margin: 0;
        opacity: unset;
      }
    }
  }
  &.update-email-pop-up {
    .container {
      .content {
        background: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        .main-title{
          font-size: 50px;
        }
        .decription{
          font-size: 18px;
        }
        .main-btn {
          .spinner {
            svg {
              height: 50px;
              width: 50px;
            }
          }
        }
      }
    }
  }
}
