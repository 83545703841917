@mixin larger-than-mobile {
  @media (min-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin larger-than-tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin larger-than-normal {
  @media (min-width: $normal-breakpoint) {
    @content;
  }
}

@mixin larger-than-large {
  @media (min-width: $large-breakpoint) {
    @content;
  }
}

@mixin larger-than-x-large {
  @media (min-width: $x-large-breakpoint) {
    @content;
  }
}
