.branch-selecting {
  display: flex;
  flex-direction: column;
  align-items: center;
  .subtitle {
    margin-bottom: 20px;
  }
  &.update-cmps {
    display: contents !important;
    width: 100%;
  }
  .modal-content-container {
    padding-top: 30px;
    .med-title {
      margin-bottom: 0;
    }
  }

  .branches {
    width: 100%;
    .branch {
      display: flex;
      gap: 10px;
      background-color: #fff;
      padding: 0 15px;
      margin: 15px 0;
      border: 1px solid $color-form-border;
      box-shadow: 5px 5px 10px 1px #0001;
      width: 100%;
      height: 60px;
      justify-content: flex-start;
      align-items: center;
      border-radius: 15px;
      .city-title {
        margin: 0;
        color: #3f4956;
        margin: 0;
        border: #707070;
        font-family: "AlmoniDemiBold";
      }
      label {
        min-height: 75px;
        margin-bottom: 0;
        p:not(.mail) {
          display: inline;
          @include larger-than-mobile {
            display: block;
          }
        }
      }
      p {
        color: $color-text;
        line-height: 1.2;
      }
      p.mail {
        color: $color-text;
        opacity: 0.65;
      }
      .tooltip {
        height: 20px;
        width: 20px;
        .top {
          color: white;
          font-family: "Almoni";
          font-size: 18px;
          font-weight: unset;
          right: 20px;
          top: 120px;
          @include larger-than-mobile {
            min-width: 450px;
            right: unset;
            top: -5px;
          }
          .branch-tooltip {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            text-align: right;
            .title-type {
              font-family: "AlmoniBold";
              width: 100px;
            }
            .title {
              font-family: "AlmoniBold";
            }
            span {
              text-align: justify;
              width: 100%;
            }
          }
        }
        img {
          height: 20px;
          width: 20px;
        }
      }

      @include larger-than-mobile {
        width: 200px;
        height: 175px;
        border-radius: 30px;
        flex-direction: column;
        padding: 15px;
        align-items: center;
        justify-content: center;
        position: relative;
        p {
          text-align: center;
          font-weight: 600;
          font-size: 21px;
        }
        input[type="radio"] {
          position: absolute;
          top: 15px;
          right: 15px;
          appearance: none;
          border: 1px solid $color-form-border;
          border-radius: 50%;
          background-clip: content-box;
          padding: 3px;
        }
        .checkmark {
          position: absolute;
          top: 20px;
          right: 22.5px;
          width: 5px;
          height: 9px;
          border: solid white;
          border-width: 0 2.5px 2.5px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &.blue {
          background-color: $color-blue;
          label {
            opacity: 1;
          }
          p,
          .city-title {
            color: white;
          }
        }
        .tooltip {
          position: absolute;
          top: 15px;
          left: 15px;
        }
      }
    }
    @include larger-than-mobile {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
    &.list {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .select-container {
    display: flex;
    gap: 30px;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 175px;
      border-radius: 30px;
      font-size: 21px;
      font-weight: bold;
      cursor: pointer;
      &.blue {
        background-color: #4e71ff;
        > p {
          opacity: unset;
          color: #fff;
        }
      }
      &.white {
        background-color: #fff;
        > p {
          opacity: unset;
        }
      }
    }
  }

  .search-field {
    align-self: center;
    width: 100%;
    background: white;
    @include larger-than-mobile {
      width: 350px;
    }
    padding: 0 15px;
    margin: 10px 0 25px 0;
    border: 1px solid $color-form-border;
    border-radius: 15px;
    display: flex;
    gap: 15px;
    .search-input {
      border: none;
      color: $color-text;
      width: 350px;
      font-size: 17px;
      padding: 0;
      opacity: 1;
      &::placeholder {
        color: $color-text;
        font-size: 16px;
        @include larger-than-mobile {
          font-size: 25px;
        }
        opacity: 0.65;
      }
      &:hover,
      &:focus {
        border: none;
      }
    }
    &:hover,
    &:focus-within {
      border: 1px solid black;
    }
  }
}
