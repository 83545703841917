.delivery-confirm {
  font-size: 15px;
  background-color: #fff;
  border: 1px solid black;
  height: 1123px;
  width: 794px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;
  position: relative;
  margin-bottom: 20px;
  .logo {
    width: fit-content;
    align-self: end;
    opacity: 0.5;
    margin-bottom: 20px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .client {
      font-weight: 600;
      text-align: center;
    }
    .client-name {
      text-align: right;

      padding: 20px;
      height: 110px;
      width: 260px;
      border: 1px solid black;
    }
    .docs {
      font-weight: 600;
      text-align: right;
      gap: 5px;
      max-width: 55%;
      .doc-details {
        display: flex;
      }
    }
  }
  .recipient {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    align-items: center;
    > div {
      font-size: 19px;
      text-align: right;
      display: flex;
      gap: 8px;
    }
  }
  .visit {
    display: flex;
    gap: 11px;
  }
  .under-line {
    line-height: 3.4;
    text-decoration: overline;
  }
  .checkbox {
    height: 15px;
    width: 15px;
    border: 1px solid $color-text;
  }
  .actions {
    display: flex;
    margin-top: 50px;
    .container-right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      gap: 15px;
      > div {
        display: flex;
        gap: 15px;
        > div {
          display: flex;
          gap: 3px;
        }
      }
    }
    .action {
      width: 100%;
      display: flex;
      gap: 5px;
      text-align: initial;
      align-items: top;
      p {
        width: 85%;
        line-height: 1.5;
      }
      .checkbox {
        margin-top: 5px;
      }
    }
    .container-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
      width: 50%;
      .title {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
  .sign {
    margin-top: 60px;
    display: flex;
    gap: 30px;
    justify-content: center;
    p {
      border-top: 1px solid $color-text;
      font-size: 16px;
      width: 21%;
    }
  }
  .order-num {
    justify-self: flex-end;
    position: absolute;
    bottom: 50px;
    right: 30px;
  }
}

.btn.download {
  background-color: white;
  margin-top: 15px;
  width: 180px;
  height: 60px;
  text-align: center;
  font-family: "Almoni";
  font-size: 18px;
  border-radius: 15px;
  border: 1px solid $color-border;
  line-height: 2;
  transition: all 0.2;
  &:hover {
    transform: scale(0.98);
    border: 1px solid $color-text;
  }
}
.collect-or-upload {
  position: relative;
  .modal-content-container {
    padding-top: 65px;
    .blockSelect {
      width: 100%;
      display: flex;
      justify-content: center;
      padding-bottom: 30px;
    }
  }
}
.delivery-confirms {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
