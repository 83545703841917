.tooltip-forms-extra {
  display: inline-block;
  text-align: center;
  position: absolute;
  // position: relative;
  top: -40px;
  transform: translate(-25%, -100%);
  bottom: 100%; /* Position the tooltip above the parent div */
  left: 50%; /* Horizontally center the tooltip within the parent div */
  transform: translateX(-50%) translateY(-50%); /* Center the tooltip both horizontally and vertically */
  z-index: 1;

  .top {
    font-weight: 500;
    padding: 10px 20px;
    background-color: #323338;
    color: white;
    border-radius: 10px;
    font-size: 20px;
    opacity: 1;
    white-space: nowrap; /* Prevent text from wrapping */
  }

  .triangle {
    position: absolute;
    top: 90%; /* Position the triangle above the tooltip */
    left: 50%;
    width: 0;
    height: 0;
    border-left: 24px solid transparent;
    border-right: 24px solid transparent;
    border-top: 24px solid #323338; // Change the color of the triangle here
    transform: translateX(-50%); // To horizontally center the triangle
  }
}
