.main-btn {
  width: 289px;
  height: 60px;

  @include larger-than-mobile {
    width: 250px;
    height: 65px;
  }

  border: unset;
  font: 500 23px "Almoni";
  background-color: $color-blue;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;

  &.upload {
    height: 65px;
    font: 500 15px "Almoni";
  }

  &:hover {
    background-color: #4568f2;
    color: #fff;
    font-size: 21px;
    transform: scale(0.95);
    transition: 0.1s;
  }

  &.long {
    width: 350px;
    font-size: 21px;
  }

  &.small {
    width: 120px;
    height: 45px;
    font: 500 15px "Almoni";
  }
}

.back-icon {
  &:hover {
    .back-svg {
      stroke: $color-icon-hover;
    }
  }
}

.btn-add {
  cursor: pointer;
  width: 25px;
  height: 25px;
  @include larger-than-mobile {
    width: 35px;
    height: 35px;
  }
  border: none;
  border-radius: 10px;
  font-size: 25px;
  background-color: $color-blue;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(0.95);
    font-size: 22px;
  }
}

.btn-remove {
  &:hover {
    transform: scale(0.95);
    font-size: 22px;
  }
}

// .btn-close {
//   width: 40px;
//   aspect-ratio: 1;
//   cursor: pointer;
//   &:hover {
//     transform: scale(0.95);
//   }
// }

.close-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: none;
}

.close-icon {
  &:hover {
    .close-svg {
      stroke: $color-icon-hover;
    }
  }
}

.btn-draft {
  display: flex;
  justify-content: right;
  padding-right: 25px;
  align-items: center;
  gap: 10px;
  font: 400 16px "Almoni";
  background-color: transparent;
  border: 1px solid $color-icon-regular;
  border-radius: 20px;
  width: 165px;
  height: 50px;

  p {
    color: $color-icon-regular;
  }

  &:hover {
    border: 1px solid $color-icon-hover;
    p {
      color: $color-icon-hover;
    }
    .draft-icon {
      .path {
        fill: $color-icon-hover;
      }
    }
  }
}

.btn-sign {
  width: 150px;
  align-self: center;
  padding: 7px 10px;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid rgb(175, 172, 172);
  border-radius: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  &:hover {
    border: 1px solid black;
  }
  &.approve {
    background-color: $color-blue;
    color: white;
    border: 1px solid $color-blue;
  }
}

.orange-btn {
  width: 150px;
  // letter-spacing: 0.8px;
  min-height: 50px;
  background-color: #fcb948;
  font: 500 17px "AlmoniMedium";
  color: white;
  // border-radius: 40px;
  border-radius: 15px;
  border: none;
  // border: 1px solid #c2cadb;
  &:hover {
    transform: scale(0.96);
  }
}
.new-order-btn {
  width: 200px;
  // letter-spacing: 0.8px;
  min-height: 45px;
  background-color: #fcb948;
  font: 500 18px "Almoni";
  color: white;
  border-radius: 40px;
  border: none;
  &:hover {
    transform: scale(0.96);
  }
}
