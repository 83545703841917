.person-details {
  h2.large-title {
    margin: 40px 0 60px 0;
  }
  .modal-content-container {
    display: flex;
    align-items: center;
  }
  .main-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include larger-than-mobile {
      flex-direction: row;
      max-width: 730px;
    }
    flex-wrap: wrap;
    // width: 938px;
    gap: 30px;
    height: fit-content;

    .input-container {
      display: flex;
      flex-direction: column;
      gap: 0px;
      .field {
        height: unset;
        margin-bottom: 0;
        .icon-input {
          width: 100%;
          @include larger-than-mobile {
            width: 350px;
          }
        }
      }
      textarea {
        width: 100%;
        @include larger-than-mobile {
          width: 730px;
        }
      }
    }

    .toggle-option-radio {
      display: none;
    }
    div.toggle {
      border: 1px solid $color-border;
      background-color: transparent;
      position: relative;
      width: 198px;
      height: 60px;
      display: flex;
      flex-direction: row;
      border-radius: 10px;
      span {
        display: none;
        background-color: $color-blue;
        box-shadow: 3px 3px 5px rgb(77, 98, 185, 0.2);
        border-radius: 10px;
        border: 1px solid $color-blue;
        width: 66px;
        height: 60px;
        position: absolute;
        z-index: 0;
        right: -1px;
        top: -1px;
        transition: 0.1s;
        &:active {
          border: 0;
        }
      }

      // overflow: hidden;
      .togglt-option {
        background-color: transperent;
        z-index: 1;
        height: 60px;
        width: 66px;
        display: flex;
        // border-radius: 10px;
        font-size: 20px;
        justify-content: center;
        align-items: center;

        &:nth-child(2) {
          border-left: 1px solid $color-form-border;
        }
        &:nth-child(1) {
          border-left: 1px solid $color-form-border;
        }
      }

      &.left {
        .togglt-option:nth-child(3) {
          color: #fff;
          opacity: unset;
        }
        .togglt-option:nth-child(2) {
          border: none;
        }
        span {
          right: 132px;
          display: unset;
        }
      }
      &.unset {
        .togglt-option:nth-child(2) {
          border: none;
          color: #fff;
          opacity: unset;
        }
        .togglt-option:nth-child(2),
        .togglt-option:nth-child(1) {
          border: none;
        }
        span {
          right: 65px;
          display: unset;
        }
      }
      &.right {
        .togglt-option:nth-child(1) {
          color: #fff;
          border: none;
          opacity: unset;
        }
        span {
          right: -1px;
          display: unset;
        }
      }
    }
  }
  p.note {
    margin-bottom: 50px;
    margin-top: 20px;
    width: 100%;
    @include larger-than-mobile {
      width: 730px;
    }
  }
  .add-recipient {
    width: 100%;
    margin: 25px 45px;
    padding: 25px 0;
    border-top: 1px solid #c2cadb;
    @include larger-than-tablet {
      // margin-top: 20px;
    }
    align-self: right;
    display: flex;
    align-items: center;
    .tooltip {
      display: flex;
      flex-direction: row;
      .top {
        top: -15px;
        right: -35px;
        opacity: 1;
      }
    }
    h3 {
      margin: 0 10px;
      font-size: 15px;
      @include larger-than-tablet {
        font-size: 25px;
        font-weight: 600;
      }
    }
  }
}
