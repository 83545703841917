.main-layout {
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;

  @include larger-than-mobile {
    grid-template-columns: 1rem 1fr 1rem;
  }

  > * {
    grid-column: 2;

    &.full {
      grid-column: 1/-1;
    }
  }
}

.footer-layout {
  height: 190px;
  // grid-template-columns: 290px 1fr 290px;
  // grid-template-columns: 2rem 1fr 2rem;

  @include larger-than-mobile {
    display: grid;
    grid-template-columns: 2rem 1fr 2rem;
  }
  @include larger-than-tablet {
    height: 260px;
    grid-template-columns: 2rem 1fr 2rem;
  }
  @include larger-than-normal {
    grid-template-columns: 5rem 1fr 5rem;
  }
  @include larger-than-large {
    grid-template-columns: 10vw 1fr 10vw;
  }

  > * {
    grid-column: 2;

    &.full {
      grid-column: 1/-1;
    }
  }
}

.page {
  margin: 60px 65px;
  h2 {
    margin-bottom: 25px;
  }
  h3 {
    margin-bottom: 25px;
  }
}
