.main-page {
  // position: relative;
  // @include larger-than-tablet {
  //   overflow-y: scroll;
  //   height: calc(var(--app-height) / 0.78 - 60px - 268px - 65px);
  // }
  // @include larger-than-large {
  //   height: calc(var(--app-height) / 0.8 - 60px - 268px - 65px);
  // }
  // padding-bottom: 15px;

  display: flex;
  //FOR HEADER FIXED
  // padding-top: 60px;

  .grad {
    height: 100%;
    background: linear-gradient(180deg, #edf3fc, #fff, #fff);
  }

  .home-container {
    padding-right: 0;
    width: 100%;
    h1,
    p.home-cover-subtitle {
      color: #37383f;
      @include larger-than-mobile {
        max-width: 640px;
      }
    }

    section.home-page-actions,
    .home-text {
      padding: 0 25px;
      @include larger-than-mobile {
        padding: 0 90px;
      }
    }

    .home-text,
    .css-10ej6ve {
      width: 100%;
      text-align: center;
      h1 {
        font-size: 30px;
        font-family: "AlmoniBold";
      }
      p.home-cover-subtitle {
        max-width: 100%;
        font-size: 17px;
        opacity: 0.75;
        color: #37383f;
      }
      @include larger-than-mobile {
        h1 {
          font-size: 45px;
          text-align: right;
        }
        p.home-cover-subtitle {
          font-size: 25px;
          text-align: right;
        }
      }
    }
    @include larger-than-large {
      .home-text,
      .css-10ej6ve {
        text-align: right;
        padding: 0 85px;
      }
    }
  }
}

.actions-title {
  margin-bottom: 2em;

  display: flex;
  flex-direction: column;
  @include larger-than-mobile {
    flex-direction: column;

    &.all {
    }
  }
  text-align: right;

  border-bottom: 1px solid $color-form-border;
  h2.h2-home {
    font-size: 19px;
    font-family: "AlmoniBold";
    @include larger-than-mobile {
      font-size: 25px;
    }
    color: #37383f;
  }

  p.p-home {
    margin-bottom: 10px;
    font-size: 15px;
    @include larger-than-mobile {
      font-size: 22px;
    }
  }
  input {
    width: 180px;
    height: 50px;
    font-size: 30px;
    color: $color-placeholder;
    border: none;
    line-height: 1;
  }
  .search-field {
    width: 100%;
    @include larger-than-mobile {
      width: 237px;
    }
    padding: 0 15px;
    margin: 10px 0 25px 0;
    border: 1px solid $color-form-border;
    border-radius: 15px;
    display: flex;
    gap: 15px;
    .search-input {
      color: $color-text;
      width: 350px;
      font-size: 17px;
      padding: 0;
      opacity: 1;
      &::placeholder {
        color: $color-text;
        font-size: 16px;
        @include larger-than-mobile {
          font-size: 17px;
        }
        opacity: 0.65;
      }
      &:hover,
      &:focus {
        border: none;
      }
    }
  }
}

.actions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  gap: 15px;
  justify-content: center;
  .info-tooltip {
    position: absolute;
    top: 15px;
    left: 15px;
    .tooltip {
      z-index: 1;
      &:hover {
        z-index: unset;
        .top {
          z-index: 2;
        }
      }
      .top {
        // z-index: 2;
        min-width: 300px;
        text-align: right;
        opacity: 1;
        cursor: auto;
        @include larger-than-mobile {
          // min-width: 450px;
          // width: 550px;
        }
        p,
        span {
          font-size: 20px;
        }
        .details {
          margin-bottom: 10px;
        }
        .link-to {
          text-decoration: underline;
          // font-weight: 600;
          color: #2196f3;
          cursor: pointer;
        }
      }
    }
    &.user-display {
      top: 90px;
      left: 15px;
    }
  }
  .star-tooltip {
    position: absolute;
    top: 15px;
    left: 15px;
    .tooltip {
      .top {
        min-width: 300px;
        text-align: right;
        opacity: 1;
        font-size: 17px;
        cursor: auto;
      }
    }
  }
  .special {
    background-color: #4e71ff;
    color: white !important;
    opacity: 1;
    &:hover {
      background-color: #4e71ff;
    }
  }
  .special-pop {
    background-color: #3650b7;
    color: white;
    opacity: 1;
    font-family: "AlmoniDemiBold";
    font-size: 18px;
    clip-path: polygon(23% 0%, 71% 0%, 90% 100%, 9% 100%);
  }

  .card-details {
    width: 100%;
    h3.card-title {
      font-size: 20px;
      font-family: "AlmoniMedium";
      color: #373c43;
      font-weight: 400;
      // white-space: nowrap;
      @include larger-than-mobile {
        font-size: 23px;
        max-width: 200px;
        min-width: 180px;
      }
    }
    p.card-price {
      font-size: 18px;
      @include larger-than-mobile {
        font-size: 20px;
        // line-height: 1.6;
      }
    }
  }
  .card-sub-title {
    font-size: 16px;
    text-align: justify;
    opacity: 1;
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // line-height: 1.8;
    // font-family: "AlmoniMedium";
  }

  .css-1rm2yuk {
    height: 120px;
  }
}

.home-cover {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 250px;
  @include larger-than-mobile {
    height: 320px;
  }
  // background-image: linear-gradient(to bottom right, #f1f5fc, #fdfdfd);
  background: linear-gradient(180deg, #edf3fc, #fff, #fff);

  .home-text {
    white-space: initial;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    h1 {
      // padding-top: 50px;
      line-height: 1.1em;
      margin-bottom: 20px;
    }
    .home-cover-subtitle {
      line-height: 1.7em;
      // font-size: 22px;
    }
  }

  @include larger-than-tablet {
    white-space: nowrap;
    // height: 430px;
  }

  img {
    position: absolute;
    left: 0;
    width: 360px;
    @include larger-than-mobile {
      width: 610px;
    }
  }
}
