.side-menu {
  position: absolute;
  //FOR HEADER FIXED
  // position: fixed;
  background: $color-blue;
  top: 60px;
  height: 100%;
  padding: 30px 0 70px 0;
  display: flex;
  z-index: 12;
  flex-direction: column;
  align-items: center;
  transform: translateX(100%);
  transition: transform 0.3s;
  width: 100%;
  @include larger-than-mobile {
    width: 450px;
  }
  &.open {
    transform: translateX(0);
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.side-appbar-logo {
  width: 260px;
  @include larger-than-mobile {
    width: 50%;
  }
  align-self: center;
}

.new-order {
  width: 250px;
  // letter-spacing: 0.8px;
  min-height: 55px;
  background-color: #fcb948;
  font: 500 26px "Almoni";
  color: white;
  border-radius: 40px;
  border: none;
  margin: 50px 0;
  &:hover {
    transform: scale(0.96);
  }
}

.links-container {
  align-self: baseline;
  width: 100%;
  padding: 0 50px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .side-app-bar-links {
    list-style-type: none;

    .link-item {
      &:first-child {
        border-top: 1px solid #6482ff;
        padding-top: 25px;
      }
      &:last-child {
        // border-bottom: 1px solid #6482ff;
      }
      padding: 5px 0;
      a {
        position: relative;
        width: fit-content;
        display: block;
        padding-bottom: 5px;
        font-size: 25px;
        color: #fff;
        transition: all 0.2s ease-in-out;
        color: #fff;
        font-weight: 400;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: 0%;
          height: 0.5px;
          background: #fcb948;
          transition: all 0.2s ease-in-out;
        }
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
    &.small {
      .link-item-small {
        &:first-child {
          border-top: 1px solid #6482ff;
          padding-top: 20px;
        }
        padding: 7px 0;
        a {
          color: #fff;
          font-weight: 300;
          font-size: 18px;
        }
      }
    }
  }
  .logout {
    border-top: 1px solid #6482ff;
    padding: 25px 0;
    display: flex;
    gap: 15px;
    cursor: pointer;
    align-self: flex-start;
    align-items: center;
    // background-color: red;
    p {
      color: #fff;
      font-weight: 300;
      font-size: 18px;
      font-family:
        "AlmoniMedium",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    }
  }
}
