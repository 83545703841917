.user-address {
  background: linear-gradient(180deg, #edf3fc, #fff, #fff);
  padding: 10px 20px;
  @include larger-than-tablet {
    padding: 40px 57px;
  }
  h3 {
    font-size: 21px;
    margin-top: 15px;
    @include larger-than-tablet {
      font-size: 28px;
      margin-top: 0;
    }
  }
  .address-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .user-address-preview {
      width: 100%;
      margin: 30px 0;
      @include larger-than-mobile {
        width: 300px;
      }
      display: flex;
      flex-direction: column;
      gap: 15px;
      cursor: pointer;
      padding: 25px 20px 25px 20px;
      border: 1px solid #e6e9ef;
      border-radius: 23px;
      background-color: white;
      &.selected {
        border: 1px solid #929292;
      }
      .title {
        display: flex;
        justify-content: space-between;
        p {
          font-size: 19px;
          font-weight: 600;
        }
        .btn-container {
          margin-right: 15px;
          min-width: 60px;
          display: flex;
          gap: 10px;
          img {
            width: 25px;
            max-height: 25px;
            aspect-ratio: 1;
            &:hover {
              transform: scale(0.95);
            }
          }
        }
      }
      &:hover {
        border: 1px solid #929292;
      }
    }
  }
  .add-address {
    display: flex;
    flex-direction: column;
    .fields {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 100%;
      @include larger-than-mobile {
        // width: 620px;
        gap: 20px;
        width: 660px;
      }
      margin: 30px 0 0 0;
      .field {
        width: 100%;
        height: 90px;
        position: relative;
        @include larger-than-mobile {
          width: unset;
          height: 120px;
        }
        textarea {
          width: 100%;
          height: 60px;
          @include larger-than-mobile {
            width: 300px;
          }
        }
        input{
          height: 60px;
        }
      }
      .field.extra-height{
        height: 140px;
      }
      .field.large{
        width: 100%;
        @include larger-than-mobile {
          width: 300px;
        }
      }
      .field.small {
        width: 86px;
        height: 120px;
      }
      input {
        width: 100%;
        @include larger-than-mobile {
          width: 300px;
        }
        &.small {
          width: 100%;
          @include larger-than-mobile {
            width: 86px;
          }
        }
      }
    }
  }
  .main-btn {
    margin: 20px 0;
    width: 300px;
    align-self: center;
    @include larger-than-mobile {
      align-self: unset;
    }
  }
}
