.small-claim-defendant {
  .modal-content-container {
    .dropdown {
      margin-bottom: 20px;
      .dropdown-address-container {
        .type-of-defendant-text {
          font-size: 18px;
          opacity: 0.65;
          padding-right: 10px;
        }
        .type-of-defendant-list {
          // height: 130px;
          // height: auto;
          overflow-y: hidden;
        }
        .dropdown-option {
          display: flex;
          align-items: center;
          // width: 100%;
          // gap: 15px;
          padding: 5px;
          justify-content: right;
          border-bottom: 1px solid $color-form-border;

          &:last-child {
            border-bottom: unset;
          }

          > * {
            width: unset;
          }

          input {
            height: unset;
          }
          input[type="radio"] {
            width: 20px;
            height: 20px;
            padding: 0;
          }

          label {
            font-size: 18px;
            margin: 0;
            width: 100%;
          }
        }
        &.open {
          .dropdown-list {
            height: auto;
            overflow-y: hidden;
          }
        }
      }
    }
    .add-defendant {
      display: flex;
      align-items: center;
      padding-bottom: 25px;
      h3 {
        margin: 0 10px;
        font-size: 15px;
        @include larger-than-tablet {
          font-size: 25px;
          font-weight: 600;
        }
      }
    }
  }
  .dropdown-address-container {
    width: 100%;
    @include larger-than-mobile {
      width: 325px;
    }
    .dropdown-list {
      width: 100%;
      @include larger-than-mobile {
        width: 325px;
      }
    }
  }
  .checkbox-container {
    padding: 0;
    .checkbox-content {
      font-weight: 600;
    }
  }
  .extra-height {
    input[type="text"] {
      width: 325px;
    }
  }
  h2 {
    align-self: right;
  }

  p {
    font-size: 19px;
    opacity: 0.65;
    &.error-label {
      font-size: 12px;
      @include larger-than-mobile {
        font-size: 15px;
      }
    }
    color: #222222;
    margin-bottom: 20px;
  }

  .field {
    // width: 100%;
    // width: 325px;
    // height: auto;
    // height: 130px;
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
    .disabled {
      background-color: $color-background-disabled;
      // border: 1px solid $color-form-border;
      // font-size: 15px;
      // @include larger-than-mobile {
      //   font-size: 17px;
      // }
    }
    .disable-input {
      border-radius: unset;
    }
    &.extra-height {
      height: 160px;
    }
    &.small-plus {
      width: 150px;
      input {
        width: 100%;
      }
    }
    .postal-code-tooltip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 5px;
      label,
      .tooltip {
        width: fit-content;
        img {
          height: 17px;
          width: 17px;
        }
      }
      .top {
        top: -3px;
        right: calc(100% - 150px);
        @include larger-than-mobile {
          right: unset;
          top: -2px;
        }
        color: #222222;
        opacity: 1;
      }
    }
  }
  &.large {
    width: 325px;
  }

  .tooltip {
    .top {
      opacity: 1;
      top: 141px;
    }
  }
  .address-locate-tooltip {
    .tooltip {
      .top {
        top: 188px;
      }
    }
  }
  .id-locate-tooltip {
    .tooltip {
      .top {
        right: 0;
        top: 167px;
      }
    }
  }
  .company-name {
    position: relative;
    width: 100%;
    @include larger-than-mobile {
      width: unset;
    }
    .tooltip-company-name {
      // position: unset;
      position: absolute;
      right: -20px;
      top: 4px;
      @include larger-than-mobile {
        right: -30px;
        top: 3px;
      }
      .info-icon {
        width: 17px;
        height: 17px;
        @include larger-than-mobile {
          width: 20px;
          height: 20px;
        }
      }
      .tooltip {
        // position: absolute;
        .top {
          text-align: right;
          top: 170px;
          left: -350px;
          min-width: 350px;
          max-width: 350px;
        }
      }
    }
  }
}
