@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap");

// @font-face {
//   font-family: "Almoni";
//   src: url(../../assets/fonts/almoni-regular-aaa.otf) format("otf");
// }

@font-face {
  font-family: "Almoni";
  src: url(../../assets/fonts/almoni-regular-aaa.eot) format("eot"),
    url(../../assets/fonts/almoni-regular-aaa.woff2) format("woff2"),
    url(../../assets/fonts/almoni-regular-aaa.woff) format("woff");
}
@font-face {
  font-family: "AlmoniBold";
  src: url(../../assets/fonts/webfont_files/bold/almoni-bold-aaa.eot) format("eot"),
    url(../../assets/fonts/webfont_files/bold/almoni-bold-aaa.woff2) format("woff2"),
    url(../../assets/fonts/webfont_files/bold/almoni-bold-aaa.woff) format("woff");
}
@font-face {
  font-family: "AlmoniDemiBold";
  src: url(../../assets/fonts/webfont_files/demibold/almoni-demibold-aaa.eot) format("eot"),
    url(../../assets/fonts/webfont_files/demibold/almoni-demibold-aaa.woff2) format("woff2"),
    url(../../assets/fonts/webfont_files/demibold/almoni-demibold-aaa.woff) format("woff");
}
@font-face {
  font-family: "AlmoniMedium";
  src: url(../../assets/fonts/webfont_files/medium/almoni-medium-aaa.eot) format("eot"),
    url(../../assets/fonts/webfont_files/medium/almoni-medium-aaa.woff2) format("woff2"),
    url(../../assets/fonts/webfont_files/medium/almoni-medium-aaa.woff) format("woff");
}
@font-face {
  font-family: "AlmoniLight";
  src: url(../../assets/fonts/webfont_files/light/almoni-light-aaa.eot) format("eot"),
    url(../../assets/fonts/webfont_files/light/almoni-light-aaa.woff2) format("woff2"),
    url(../../assets/fonts/webfont_files/light/almoni-light-aaa.woff) format("woff");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  /* scroll-behavior: smooth; */
}

html {
  height: 100%;
  -webkit-locale: "he-IL";
  scroll-behavior: smooth;
}

:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: var(--app-height);
  // zoom: 0.9;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body {
  zoom: 0.9;
  width: 100%;
  height: 100%;

  @include larger-than-mobile {
    zoom: 0.8;
    width: 100%;
    height: 100%;
  }
  @include larger-than-tablet {
    height: 100%;
    width: 100%;
    zoom: 0.78;
  }
  @include larger-than-large {
    zoom: 0.8;
  }
  -webkit-locale: "he-IL";
  overflow: hidden;
}

body {
  line-height: 1.15;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  font-family: "Almoni", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.QChatButton {
  display: flex !important;
  @include larger-than-tablet {
    // display: flex !important;
    z-index: 1 !important;
  }
}
.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  // overflow-x: hidden;

  .main-page {
    flex-grow: 1;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @include larger-than-tablet {
    // overflow-y: hidden;

    &::-webkit-scrollbar {
      display: inline-block;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &.open {
    overflow: hidden;
  }
}

#root > div {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-text;
  margin: 0;
  border: #707070;
}
p,
a {
  color: $color-text;
}

p,
a,
input,
button {
  font-family: "Almoni";
}

h1 {
  font: 700 45px "AlmoniDemiBold";
}

h2 {
  font: 700 50px "AlmoniDemiBold";
}

h3 {
  font: 600 27px "AlmoniDemiBold";
}
h4 {
  font: 600 22px "AlmoniDemiBold";
}

p.p1 {
  font: 400 19px "Almoni";
  line-height: 30px;
  opacity: 0.65;
}

p.p2 {
  font: 400 17px "Almoni";
}

p.p3 {
  font: 400 15px "Almoni";
  line-height: 30px;
  opacity: 0.65;
}

p.p4 {
  font: 400 14px "Almoni";
}

p.bold {
  font: 900 1em "AlmoniBold";
}
span.bold {
  font: 900 1em "AlmoniBold";
}

input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  width: 100%;

  @include larger-than-mobile {
    width: 300px;
  }

  height: 60px;
  border: 1px solid $color-form-border;
  border-radius: 15px;
  // background-color: transparent;
  background-color: white;
  font: 400 16px "Almoni";
  // font-family: "Almoni";
  font-weight: 400;
  // font-size: 15px;
  padding: 25px;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover,
  &:focus-visible {
    border: 1px solid $color-text;
  }
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
input[type="radio"] {
  width: 20px;
  height: 20px;
}

input[type="number"] {
  -moz-appearance: textfield;
  &:focus {
    outline: none;
  }
}

input[type="tel"] {
  height: 50px;
  &:focus {
    outline: none;
  }
}

textarea {
  height: 120px;
  resize: none;
}

label {
  text-align: start;
  margin-bottom: 3px;
}

code {
  font-family: "Almoni", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
  display: flex;
}

/* width */
::-webkit-scrollbar {
  background: #e6e9ef;
  width: 0.8em;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4e71ff;
}
/* Handle */
::-webkit-scrollbar-track {
  background: #e6e9ef;
}

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}

.pac-container {
  z-index: 99999;
  border: 1px solid #cdd3df;
  border-radius: 20px;
}

.QChatButton,
.QChatHorizontalButton {
  display: none !important;
  right: unset !important;
  left: 0 !important;
  width: 159px !important;
  // @include larger-than-tablet {
  // left: 22px !important;
  display: flex !important;
  z-index: 1 !important;
  // }
}

// HELPERS

.align-right {
  text-align: right;
}

//checkbox styled

.checkbox-container {
  opacity: 1;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #ccc;
  }

  &:hover input ~ .checkmark {
    // background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: $color-blue;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 6px;
    top: 3px;
    width: 3px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
button {
  cursor: pointer;
}

.react-datepicker-wrapper {
  font: 400 15px "Almoni";
  // background-color: lightcoral;
  width: 240px;
  .react-datepicker__input-container {
    input {
      width: 240px;
      font-size: 20px;
      text-align: center;
    }
  }
}
.react-datepicker__tab-loop {
  .react-datepicker-popper {
    height: fit-content;
    // background-color: gold;
    padding: 0;
    border-radius: 15px;

    > div {
      height: fit-content;

      height: 100%;
      .react-datepicker {
        top: 5px;
        height: fit-content;
        border: 1px solid $color-form-border;
        border-radius: 15px;
        height: 305px;

        // background-color: pink;
        .react-datepicker__navigation {
          top: 12px;
          // right: 10px;
        }
        .react-datepicker__triangle {
          display: none;
        }
        .react-datepicker__month-container {
          margin: 10px 0;
          // background-color: purple !important;
          border-radius: 15px;
          // overflow: hidden;
          .react-datepicker__header {
            padding-top: 10px;
            background-color: white;
            font: 400 15px "Almoni";
            .react-datepicker__day-names {
              padding: 5px 0;
            }
          }
          .react-datepicker__month {
            .react-datepicker__week {
              // background-color: green;
              .react-datepicker__day--selected {
                background-color: $color-blue;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}

u,
button {
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

i._access-icon {
  font-size: 1.8rem !important;
  width: 2rem !important;
  height: 2rem !important;
  background-color: #3a8dc2 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 50% !important;
}
.QChatButton {
  bottom: 70px !important;
  display: flex;
  justify-content: center;
  height: 50px !important;
  padding: 0 0 0 7px !important;
}
.QChatHorizontalButton__text {
  font-size: 14px !important;
}

.uncursor {
  cursor: default !important;
}
