.modal-toolbar {
  position: fixed;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  z-index: 100;
  padding: 0;
  height: 100%;
  width: 100%;
  background: white;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.5s;

  &.open {
    transform: translateX(0);
  }

  .modal-close {
    position: absolute;
    z-index: 1;
    width: 100%;
    text-align: left;
    padding: 20px 30px 0 30px;
    .close-modal-icon {
      object-fit: contain;
      width: 40px;
      line {
        stroke: #111111;
      }
    }
  }

  .modal-title {
    @include larger-than-mobile {
      border-bottom: 1px solid #cdd3df;
    }
    .title {
      font-size: 21px;
      opacity: 1;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    min-height: 70px;
    .arrow-back {
      transform: rotate(90deg);
      path {
        opacity: 1;
      }
    }
  }
  .modal-content {
    // height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
