.small-claim-description {
  padding-bottom: 25px;
  .modal-content-container {
    // padding-top: 40px;
    // padding: 0 65px;
    .details {
      font-size: 17px;
      @include larger-than-mobile {
        font-size: 21px;
      }
    }
    .err-details {
      font-size: 17px;
      display: flex;
      align-self: flex-start;
    }
    input {
      font-size: 15px;
      @include larger-than-mobile {
        font-size: 19px;
      }
    }
    padding-right: 35px;
    @include larger-than-mobile {
      padding-right: 65px;
    }
    .tooltip {
      .top {
        text-align: right;
      }
    }
    .form-title {
      margin-bottom: 10px;
      width: fit-content;
      &.error {
        @include larger-than-mobile {
          width: 170px;
        }
      }
    }
    .tooltip-with-title {
      position: relative;
      width: fit-content;
      display: flex;
      .tooltip {
        position: absolute;
        right: -25px;
        top: 5px;
        height: 20px;
        width: 20px;
        @include larger-than-mobile {
          right: -35px;
        }
        img {
          height: 20px;
          width: 20px;
        }
        .top {
          text-align: right;
          width: 300px;
          right: 20px;
          top: 130px;
        }
      }
      &.money-claimed-tooltip {
        .tooltip {
          .top {
            top: 210px;
          }
        }
      }
      &.claim-reasons-tooltip {
        .tooltip {
          .top {
            width: 300px;
            top: 209px;
            @include larger-than-mobile {
              width: 400px;
              top: 156px;
            }
          }
        }
      }
      &.claim-reasons-files-tooltip {
        .tooltip {
          .top {
            width: 300px;
            top: 342px;
            @include larger-than-mobile {
              width: 400px;
              top: 245px;
            }
          }
        }
      }
      &.witness-tooltip {
        width: 100%;
        .tooltip {
          .top {
            top: 76px;
          }
        }
      }
    }
    .input-container {
      align-items: baseline;
      .input-container-header {
        display: flex;
        flex-direction: column;
        @include larger-than-mobile {
          flex-direction: row;
        }
      }
      .input-container-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        @include larger-than-mobile {
          width: 830px;
        }
        .add-file-btn-container {
          align-self: end;
          // padding-bottom: 17px;
          margin: 8px 0;
          @include larger-than-mobile {
            margin: 0;
            margin-left: 6px;
            padding-bottom: 17px;
          }
        }
        input {
          align-self: end;
        }
        > div {
          @include larger-than-mobile {
            margin-right: 10px;
          }
        }
        .spinner {
          width: 0;
          margin-right: 50px;
          svg {
            width: 50px;
            height: 40px;
          }
        }
      }
      .field {
        height: auto;
        // height: 40px;
        height: 50px;
        &.small-plus {
          width: 100px;
          input {
            width: 100%;
          }
        }
        &.large {
          width: 350px;
          input {
            width: 100%;
          }
        }
        input {
          height: 50px;
          padding: 10px;
          // padding: 10px;
          // height: 40px;
        }
        textarea {
          width: 100%;
          height: 170px;
          white-space: break-spaces;
          overflow-y: auto;
          @include larger-than-mobile {
            width: 730px;
          }
        }
      }
      .text-field {
        height: 170px;
      }
      .add-file {
        display: flex;
        margin-top: 5px;
        img {
          height: 25px;
          width: 25px;
        }
        h3 {
          margin-right: 5px;
          margin-bottom: 0;
          font-size: 21px;
        }
      }
      &.claim-reasons {
        margin: 10px 0;
        flex-direction: column;
        align-content: flex-start;
        align-items: flex-start;
        .reason {
          height: 110px;
          width: 100%;
          @include larger-than-mobile {
            // margin-bottom: 50px;
            margin-bottom: 0;
          }
        }
        .checkbox-container {
          margin: 0;
          .checkbox-content {
            font-weight: 600;
          }
        }
        .add-file {
          margin-top: 0;
          @include larger-than-mobile {
            margin-right: 10px;
          }
        }
        .files-container {
          width: 100%;
          @include larger-than-mobile {
            width: 830px;
          }
          .file-upload {
            .details {
              width: 100%;
              @include larger-than-mobile {
                width: 45%;
              }
            }
          }
        }
      }
      &.witness-container {
        // margin: 10px 0;
        // flex-direction: column;
        padding: 35px 0 0 0;
        .witness {
          width: 100%;
          height: 75px;
          .input-container-body {
            justify-content: space-between;
            @include larger-than-mobile {
              justify-content: right;
              width: 100%;
            }

            input {
              width: 90%;
              @include larger-than-mobile {
                width: 300px;
              }
            }
            .witness-remove-icon {
              cursor: pointer;
              width: 25px;
              height: 25px;
              margin-right: 5px;
              @include larger-than-mobile {
                width: 35px;
                height: 35px;
                &:hover {
                  transform: scale(0.9);
                }
              }
            }
          }
        }
      }
      &.statement-container {
        padding: 30px 0 10px 0;
        .statementA-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: unset;
          @include larger-than-mobile {
            height: 85px;
            flex-direction: row;
          }
        }
        .details {
          &.center {
            align-self: flex-start;
            @include larger-than-mobile {
              align-self: center;
              margin-left: 12px;
              margin-bottom: 30px;
            }
          }
        }
        h4 {
          width: 100%;
        }
        .field {
          height: 88px;
          @include larger-than-mobile {
          }
          margin-bottom: 0;

          // opacity: 0.8;
          padding-right: 5px;
          // width: 265px;
          // height: 60px;
          display: flex;
          align-items: center;
          input {
            height: 60px;
            font-size: 17px;
          }

          .disabled {
            background-color: $color-background-disabled;
          }
        }
        .source-container {
          width: 100%;
        }
        .dropdown-address-container {
          width: 325px;
          align-self: baseline;
          font-size: 17px;
          align-self: flex-start;
          // opacity: 0.8;
          height: 90px;
          @include larger-than-mobile {
            align-self: center;
            height: 90px;
            margin-left: 12px;
          }
          .dropdown-input {
            .statement-text {
              opacity: 0.8;
              padding-right: 5px;
              width: 265px;
              height: 40px;
              display: flex;
              align-items: center;
            }
          }
          .dropdown-list {
            width: 100%;
            transform: translateY(57px);
            @include larger-than-mobile {
              transform: translateY(63px);
            }

            .dropdown-option {
              padding-right: 5px;
              justify-content: right;
            }
          }
        }
      }
      &.signature-container {
        // height: 175px;
        // padding-bottom: 25px;
        padding-top: 20px;
        justify-content: space-between;
        h4 {
          align-self: center;
          margin-bottom: 20px;
        }
        .signature {
          display: flex;
        }
        .signature-image {
          align-self: center;
          margin-right: 80px;
          width: 50px;
          @include larger-than-mobile {
            width: unset;
          }
        }
        .btn-cont {
          display: flex;
          // width: 100%;
          gap: 15px;
          // justify-content: center;
          // margin-bottom: 30px;
          .spinner {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 20px;
          }
        }
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
        }
        .modal-main {
          position: fixed;
          background: white;
          width: 420px;
          height: 360px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .container {
            .btn-container-modal {
              display: flex;
              gap: 10px;
              justify-content: center;
            }
          }
        }
        .display-block {
          display: block;
        }

        .display-none {
          display: none;
        }
        .sigContainer {
          zoom: 1.1;
          @include larger-than-mobile {
            zoom: 1.265;
          }
          width: 300px;
          height: 200px;
          margin: 20px auto;
          border: 1px solid #e6e9ef;
          .sigPad {
            width: 100%;
            height: 100%;
          }
        }
        .sigImage {
          align-self: center;
          margin-right: 80px;
          width: 50px;
          @include larger-than-mobile {
            width: unset;
          }
        }
      }
    }
    .ul-info {
      margin-right: 20px;
    }
    .court-selection {
      .field {
        height: unset;
        height: 90px;
        margin-bottom: 30px;
      }
      .court-selection-tooltip {
        .tooltip {
          top: -15px;
          @include larger-than-mobile {
            top: -15px;
          }
          .top {
            width: 300px;
            top: 1628px;
            @include larger-than-mobile {
              width: 900px;
              top: 965px;
            }
          }
        }
      }
      .dropdown-address-container {
        width: 100%;
        @include larger-than-mobile {
          width: 375px;
        }
        .dropdown-list {
          width: 100%;
          .dropdown-option {
            p {
              font-size: 19px;
            }
          }
        }
        &.open {
          .dropdown-list {
            height: 259px;
          }
        }
      }
    }
    .details {
      display: flex;
      align-items: center;
    }
    .files-container {
      // margin-top: 20px;
      // width: 100%;
      margin: 0 auto;
      &.spinner {
        // padding-block-start: 50px;
        margin: 40px auto 0 auto;
      }
      // @include larger-than-mobile {
      //   margin-top: 25px;
      // }

      // h3 {
      //   text-align: right;
      //   // margin-top: 20px;
      //   margin-bottom: 10px;
      //   @include larger-than-mobile {
      //     // margin-top: 5px;
      //   }
      // }
      width: 100%;
      @include larger-than-mobile {
        // width: 85%;
      }
      .titles {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        > p {
          width: 45%;
          text-align: right;
          @include larger-than-mobile {
            width: 100%;
          }
        }
        .btn-titles {
          display: flex;
          justify-content: flex-end;
          width: 10%;
          display: flex;
          gap: 15px;
        }
      }
      .file-upload {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-top: 1px solid $color-form-border;
        height: 60px;
        &:last-child {
          border-bottom: 1px solid $color-form-border;
        }

        .details {
          display: flex;
          width: 45%;
          padding-left: 20px;
          gap: 10px;
          align-items: center;
          @include larger-than-mobile {
            width: 100%;
          }
          > p {
            font-size: 17px;
            // @include larger-than-mobile {
            //   font-size: 21px;
            // }
            text-align: right;
          }
        }
        .status {
          width: 40%;
          text-align: right;
          align-self: center;
        }

        .btn-container {
          display: flex;
          align-items: left;
          justify-content: left;
          // width: 20%;
          img {
            cursor: pointer;
            width: 25px;
            height: 25px;
            @include larger-than-mobile {
              width: 35px;
              height: 35px;
              &:hover {
                transform: scale(0.9);
              }
            }
          }
          display: flex;
          gap: 8px;
          @include larger-than-mobile {
            gap: 15px;
          }
        }
      }
    }
    .signature-error {
      width: fit-content;
      padding-bottom: 25px;
    }
    .input-idx {
      align-self: end;
      margin-bottom: 12px;
      margin-left: 5px;
    }
  }
}
