.notarized-translation {
  display: flex;
  padding: 30px 0;
  @include larger-than-mobile {
    padding: 70px 0;
  }
  .blockSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h2.large-title {
    margin-bottom: 20px;
  }
  .language {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: right;
    padding: 15px 0 25px 0;
    margin-block-start: 10px;
    margin: 20px 0 0 0;
    border-top: 1px solid #e6e9ef;
    border-bottom: 1px solid #e6e9ef;
    @include larger-than-mobile {
      flex-direction: row;
      justify-content: right;
      align-items: center;
      gap: 15px;
      padding: 25px 0;
    }

    > p,
    input {
      margin-bottom: unset;
      font-size: 16px;
      @include larger-than-mobile {
        font-size: 21px;
      }
    }
    > input {
      width: 100%;
      height: 45px;

      @include larger-than-mobile {
        width: 350px;
      }
    }
  }
}
