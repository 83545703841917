.tooltip {
  display: inline-block;
  position: relative;
  text-align: center;
}
.tooltip .top {
  min-width: 300px;
  top: -5px;
  font-weight: 500;
  left: 0%;
  transform: translate(0%, -100%);
  padding: 10px 20px;
  // background-color: #f8fafd;
  // color: rgb(126, 126, 126);
  background-color: #323338;
  color: white;
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  // border: 1px solid #e1e5ec;
  visibility: hidden;
  //   opacity: 0;
  //   transition: opacity 0.4s;
  p,
  .top {
    opacity: 1;
    color: white;
  }
}
.tooltip .top .tooltip-details {
  color: rgb(126, 126, 126);
  color: white;
  > p {
    color: rgb(126, 126, 126);
    color: white;
  }
}

.tooltip:hover .top {
  visibility: visible;
  //   opacity: 1;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  right: 90%;
  margin-left: -15px;
  width: 30px;
  height: 15px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #f8fafd;
  // border: 1px solid #e1e5ec;
}
.tooltip .triangle {
  position: absolute;
  top: 90%; /* Position the triangle above the tooltip */
  left: 50%;
  width: 0;
  height: 0;
  border-left: 24px solid transparent;
  border-right: 24px solid transparent;
  border-top: 24px solid #323338; // Change the color of the triangle here
  transform: translateX(-50%); // To horizontally center the triangle
}
