.action-in-office {
  width: 100%;
  // padding-top: 65px;
  &.power-of-attorney {
    padding-top: 35px;
  }
  &.file-count-container {
    .modal-content-container {
      padding-right: 0;
    }
  }
  .modal-content-container {
    padding-top: 30px;
    padding-bottom: 30px;
    .btm {
      margin-bottom: 30px;
    }
  }
  &.collect-file-details {
    .modal-content-container {
      padding-top: 65px;
      h2.large-title {
        margin-bottom: 0;
      }
      .server-details {
        h3,
        p {
          text-align: right;
          margin-bottom: 0;
        }
      }
    }
  }
  &.from-action-office {
    .modal-content-container {
      padding-right: 0;
    }
  }
  p.subtitle.details {
    margin-bottom: 10px;
  }
  h2.large-title {
    margin-bottom: 25px;
  }
  .label {
    max-width: 480px;
    opacity: 0.65;
  }
  .file-count {
    width: 100%;
    .small {
      font-size: 19px;
      @include larger-than-mobile {
        font-size: 24px;
      }
      font-family: "AlmoniDemiBold";
    }
    border-radius: 40px;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  .number-input {
    background-color: green;
    width: 100px;
    height: 40px;
    background-color: $color-blue;
    border-radius: 40px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input.quantity {
      color: white;
      background-color: $color-blue;
      text-align: center;
      width: 40%;
      height: 40px;
      border: none;
      padding: 0;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
    .minus,
    .plus {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #3b55bf;
      border: none;
      color: white;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.2;
    }
  }
  .hidden-content {
    align-self: center;
    margin-top: 30px;
    h3.small {
      font-size: 19px;
      text-align: center;
    }
    input {
      text-align: center;
      font: 400 19px "Almoni";
      color: #212129;
    }
    p {
      text-align: right;
    }

    .icon {
      font-size: 35px;
    }
  }

  .server-details {
    width: 100%;
    p {
      // text-align: center;
    }
    align-self: center;
    // margin: 20px 0 0 0;
  }
  .add-recipient {
    margin-bottom: 10px;
    display: flex;
    gap: 15px;
    h3 {
      font-size: 20px;
      margin: 0;
    }
    align-items: center;
  }
  .field {
    height: 100px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    &.file {
      width: 270px;
      @include larger-than-mobile {
        width: 350px;
      }
    }
  }
  .input-container {
    grid-column-gap: unset;
    gap: 10px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .file-det-container {
      display: flex;
      gap: 10px;
    }
  }

  .remove-contain {
    height: 100px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn-remove {
      margin-top: 15px;
      height: 40px;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      color: white;
      font-size: 15px;
      border-radius: 5px;
      background-color: transparent;
      border: none;
    }
  }

  //For landSupervisor
  .modal-content-container {
    .collect-or-upload {
      height: auto !important;
      width: 100%;
      .modal-content-container {
        padding: 20px 0;
        .main-title {
          font-size: 40px;
          margin-bottom: 10px;
        }
        .subtitle {
          font-size: 20px;
          margin-bottom: 10px;
        }

        .collect-upload-toggle {
          --btn-width: 175px;
          --btn-height: 70px;
        }
      }
    }
    .text-toggle {
      .form-main-toggle {
        --btn-width: 75px;
      }
    }
  }
}
