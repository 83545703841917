.inputs-container {
  margin-bottom: 40px;
  input {
    border-radius: 50px;
  }
  display: flex;
  flex-direction: column;
  .inputs-container {
    display: flex;
    margin-bottom: 20px;
    .field {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 15px;
    }
  }
}
