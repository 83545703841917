.form7-what-served {
  // height: 100%;
  // p {
  //   margin-bottom: 20px;
  //   text-align: center;
  // }

  display: flex;
  flex-direction: column;
  align-items: center;

  h2.large-title {
    @include larger-than-mobile {
      margin-bottom: 0px;
      padding-top: 30px;
    }
  }

  p.subtitle {
    @include larger-than-mobile {
      margin-bottom: 20px;
      &.first-sub {
        margin-bottom: 0;
      }
    }
  }
  .selected-doc-name {
    display: flex;
    flex-direction: column;
    @include larger-than-mobile {
      gap: 50px;
      width: 350px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  p.p-med-bold {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    @include larger-than-mobile {
      font-size: 21px;
      font-weight: 600;
      // margin-left: 30px;
    }
  }

  .dropdown-container {
    position: relative;
    // padding: 10px;
    display: flex;
    justify-content: center;

    &.open {
      .dropdown-list {
        animation: open-dropdown-list 0.3s forwards;
      }

      .dropdown-input {
        border-radius: 15px 15px 0px 0px;
        button {
          transform: scale(1, -1);
        }
      }
    }

    .dropdown-input {
      // position: absolute;
      left: 50%;
      // transform: translate(-50%);
      display: flex;
      border: 1px solid $color-border;
      border-radius: 15px;
      overflow: hidden;
      width: 320px;
      transition: border-radius 0.3s;

      @include larger-than-mobile {
        width: 650px;
      }

      input {
        border: none;
        background: #fff;
        border-radius: unset;
        flex-grow: 1;
        font-style: italic;
        font-size: 18px;
      }

      button {
        height: 60px;
        width: 60px;
        font-size: 50px;
        background-color: #aab4c7;
        border: none;
        border-right: 1px solid $color-backgroung-button;
        background-color: $color-backgroung-button;
        // opacity: 0.1;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s;
      }
    }

    .dropdown-list {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      width: 320px;
      border: 1px solid transparent;
      height: 0;
      top: 61px;
      padding: 0 10px;

      @include larger-than-mobile {
        width: 650px;
      }

      border-radius: 0px 0px 15px 15px;
      max-height: 500px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        background: transparent;
        width: 7px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        background: $color-blue;
        // border: 1px solid $color-blue;
        // border: 6px solid transparent;
        // box-shadow: inset 0 0 7px 7px $color-blue;
      }

      transition: height 0.3s;

      display: flex;
      flex-direction: column;

      z-index: 5;

      p {
        opacity: unset;
      }
    }

    @keyframes open-dropdown-list {
      0% {
        height: 0;
        border-color: #0000;
        background: transparent;
      }
      10% {
        border-color: $color-border;
        background: #fff;
      }
      100% {
        height: 379px;
        border-color: $color-border;
        background: #fff;
      }
    }

    .dropdown-option {
      cursor: pointer;
      display: flex;
      height: 60px;

      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      border-bottom: 1px solid $color-form-border;
      p {
        width: 200px;
        font-family: "AlmoniMedium";
        @include larger-than-mobile {
          width: unset;
        }
      }
      .btn-add {
        font-size: 30px;
        img {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .btn-option {
    border: none;
    height: 35px;
    width: 35px;
    cursor: pointer;
    background-color: none;
    border-radius: 10px;
    margin-right: 15px;

    &.remove {
      background-color: $color-backgroung-button;
    }
  }

  .icon-info {
    margin-left: 15px;
    cursor: pointer;
  }

  .selected-options {
    position: relative;
    margin-top: 80px;
    align-self: center;
    width: 100%;

    > p {
      border: none;
    }

    h3 {
      margin-bottom: 10px;
    }

    .tooltip-container {
      .tooltip {
        .triangle{
          top: 94%;
        }
        .top {
          top: -15px;
          left: -110px;
        }
      }
      .btn.info {
        font-family: "Almoni";
        height: 35px;
        background-color: transparent;
        border: 1px solid $color-icon-regular;
        padding: 5px 10px;
        border-radius: 7px;
        white-space: nowrap;
        font-size: 15px;
        &:hover {
          border: 1px solid $color-text;
        }
      }
    }

    .selected-item {
      position: relative;
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @include larger-than-mobile {
        flex-direction: row;
        height: 90px;
        align-items: center;
      }
      font-weight: bold;
      color: $color-text;
      width: 100%;
      border-bottom: 1px solid $color-form-border;

      &:nth-child(2) {
        border-top: 1px solid $color-form-border;
      }

      &:last-child {
        border-bottom: none;
      }

      input {
        width: 100%;
        font-size: 15px;
        height: 45px;
        margin-bottom: 15px;
        border-radius: 15px;
        @include larger-than-mobile {
          width: 250px;
          margin-bottom: 0px;
          padding: 10px;
        }
        height: 45px;
        font: 400 16px "Almoni";
        margin-left: 20px;
      }
      ::placeholder {
        opacity: 0.65;
      }

      p.p-bold {
        opacity: unset;
        padding-left: 30px;
        padding-bottom: 10px;
        font-weight: 500;
        @include larger-than-mobile {
          font-weight: 600;
        }
      }
    }
  }
  .selected-options-title {
  }
  .info-tooltip{
    .tooltip{
      .top{
        left: -140px;
        top: -16px;
      }
      .triangle{
        top: 97%;
      }
    }
  }
  .btn-container {
    position: absolute;
    top: 12px;
    left: 0;
    @include larger-than-mobile {
      height: 100%;
      position: relative;
      top: unset;
    }
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: none;
    flex-grow: 1;
    // background-color: #cccc;
    text-align: left;
    img {
      cursor: pointer;
      &.btn-remove {
        width: 25px;
        aspect-ration: 1;
        @include larger-than-mobile {
          width: 35px;
          aspect-ration: 1;
        }
      }
    }
  }
}
