.pa-container {
    margin-top: 30px;
    text-align: right;
    background-color: white;
    width: 90%;
    @include larger-than-mobile {
      width: 50%;
    }
    display: flex;
    flex-direction: column;
    gap: 25px;
    height: fit-content;
    margin-bottom: 40px;
    border: 1px solid $color-form-border;
    p {
      font-size: 16px;
      @include larger-than-tablet {
        font-size: 17px;
      }
    }
    &.hidden {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }