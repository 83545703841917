.user-dash {
  display: flex;
  width: 100%;
  height: 100%;
  .content {
    width: 100%;
    background: linear-gradient(180deg, #edf3fc, #fff, #fff);
  }

  .user-msgs {
    min-width: 360px;
    // width: 470px;
    // width: 30%;
    // width: 600px;
    // width: 35%;
    // width: 50%;
    // max-width: 470px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .msgs-title {
      height: 65px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      color: #37383f;
      font-size: 19px;
      // font-weight: 600;
      font-family: "AlmoniDemiBold";
      &.notifications-list-title {
        font-size: 26px;
        font-family: "AlmoniBold";
        padding: 0;
        display: flex;
        justify-content: right;
        align-items: flex-start;
        line-height: 1;
        border-bottom: 1px solid #c2cadb;
        height: 50px;
      }
    }

    .msg-list {
      background-color: #f4f7fd;
      // height: 125vh;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      
    }
    &.notifications-list {
      min-width: 420px;
      max-width: 470px;
      padding-right: 50px;
      @include larger-than-tablet {
        padding: 0 30px;
      }
    }
  }

  .user-main {
    width: 100%;
    .nav {
      // background-color: lightcoral;
      flex-grow: 1;
      display: flex;
      height: 65px;
      display: flex;
      align-items: center;
      gap: 40px;
      padding-right: 57px;
      .nav-title {
        display: flex;
        align-items: center;
        line-height: normal;
        height: 67px;
        font-size: 24px;
        padding: 0 10px;
        border-bottom: 3px solid transparent;
        border-top: 3px solid transparent;
        // font-weight: 600;
        p {
          font-family: "AlmoniDemiBold";
          color: #37383f;
          border: #707070;
          opacity: 0.75;
        }
        &.active {
          p {
            color: #37383f;
            opacity: 1;
          }
          border-bottom: 3px solid $color-blue;
        }
      }
    }
  }
}
