.user-msgs {
  padding: 0 0 25px 0;
  // background: linear-gradient(180deg, #edf3fc, #fff, #fff);
  @include larger-than-tablet {
    background: transparent;
  }
  @include larger-than-tablet {
    padding: 0;
  }

  .msgs-title {
    padding: 0;
    align-items: flex-start;
    @include larger-than-mobile {
      padding: 0px 30px;
    }

    background: #f4f7fd;
    height: 40px;
    display: flex;
    // font-size: 21px;
    // font-weight: 600;

    font: 700 26px "AlmoniBold";
    background: transparent;
    @include larger-than-tablet {
      font-size: 17px;
      font-weight: 500;
      height: 70px;
    }
  }
  .msg-list {
    overflow-y: hidden;
    height: 100%;
    // height: 110vh;
    border-left: 1px solid #eeeeee;
    @include larger-than-tablet {
      overflow-y: scroll;
      height: calc(var(--app-height) / 0.78 - 60px - 260px - 65px);
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @include larger-than-large {
      height: calc(var(--app-height) / 0.8 - 60px - 260px - 65px);
    }
    // height: 900px;

    // background: linear-gradient(180deg, #edf3fc, #fff, #fff);
    @include larger-than-tablet {
      // background: #f4f7fd;
      overflow-y: scroll;
      // height: calc(100vh / 0.8);
    }
  }

  .user-msg-item {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    border-top: 1px solid #eeeeee;
    background: #f4f7fd;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 1px;
    &:hover {
      background-color: #dcdfec !important;
    }
    @include larger-than-mobile {
      padding: 20px 30px;
    }

    &:last-child {
      border-bottom: 1px solid #eeeeee;
    }

    .order-notification-title {
      font-family:
        "AlmoniDemiBold",
        "Almoni",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
      border: #707070;
      color: #37383f;
      font-size: 21px;
      text-align: right;
    }

    .title {
      display: flex;
      justify-content: space-between;
      p {
        // font-family: "AlmoniDemiBold", "Almoni", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        // border: #707070;
        // color: #37383f;
        // font-size: 21px;
        padding-left: 5px;
        // text-align: right;
      }
      .time,
      .date {
        font-family: "Almoni";
        font-size: 17px;
        border: #707070;
      }
      > div {
        display: flex;
        gap: 10px;
      }
      .date-title {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .order-num {
      margin-bottom: 15px;
      .order-notification-title {
        font-size: 18px;
      }
    }
    .description {
      text-align: justify;
      .description {
        color: #222222;
        border: #707070;
        font-size: 17px;
        opacity: 0.75;
      }
    }
    &.action-info {
      cursor: auto;
      background-color: transparent;
      // padding-right: 0;
    }
    &.not-clicked {
      background: #f2f4f7;
      background: #cce5ff;
      cursor: pointer;
    }
    &.clickable {
      cursor: pointer;
    }
    &.action-info:first-child {
      border-top: unset;
    }
    .to-order {
      font-size: 15px;
      margin-top: 10px;
      align-self: end;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 7px;
      @include larger-than-mobile {
        border-radius: 7px;
      }
      // width: 100px;
      // height: 30px;
      @include larger-than-mobile {
        // width: 125px;
        // height: 30px;
      }
      background-color: transparent;
      border: 1px solid $color-icon-regular;
      span {
        padding: 5px 15px;
      }
      p {
        font-size: 12px;
        color: #96a1b8;
        @include larger-than-mobile {
          font-size: 16px;
        }
      }
      &:hover {
        border: 1px solid $color-icon-hover;
        .back-small-icon {
          path {
            stroke: $color-icon-hover;
          }
        }
        p {
          color: $color-icon-hover;
        }
      }
    }
  }
}
