.user-profile {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding: 40px 30px 0 30px;
  margin-bottom: 20px;

  .details {
    text-align: center;

    .avatar {
      background-color: $color-blue;
      padding: 3px;
      opacity: 0.55;
      vertical-align: middle;
      border-radius: 50%;
      width: 125px;
      // height: 125px;
      margin-bottom: 25px;
    }
    .name {
      font-size: 30px;
      font-weight: 500;
      font-family: "AlmoniMedium";
    }
    .sub-name {
      font-size: 22px;
      margin-top: 5px;
      opacity: 0.75;
    }
  }
  .new-order {
    height: 55px;
    width: 250px;
    font-size: 26px;
    margin: 0 15px 15px;
    &:hover {
      transform: scale(0.96);
    }
  }

  .progress {
    background-color: #d8d8d8;
    border-radius: 20px;
    position: relative;
    margin: 10px 0;
    height: 10px;
    width: 380px;
  }

  .progress-done {
    background-color: $color-blue;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
  }

  .stats {
    display: flex;
    gap: 20px;
    .stats-item {
      text-align: center;
      span {
        font-size: 27px;
        font-family: "AlmoniDemiBold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
          "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      }
      p {
        font-size: 22px;
        opacity: 0.75;
        font-family: "Almoni", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
          "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
      }
    }
  }
  .devider {
    height: 2px;
    width: 300px;
    border-bottom: 1px solid #eeeeee;
  }

  .links {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-self: baseline;
    gap: 15px;
    margin-bottom: 20px;
    .links-title {
      font-size: 26px;
      font-family: "AlmoniDemiBold", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }
    .link {
      color: inherit;
      text-decoration: inherit;
      transition: all 0.2s ease-in-out;
      position: relative;
      width: fit-content;
      display: block;
      padding-bottom: 5px;
      font-size: 22px;
      transition: all 0.2s ease-in-out;
      // font-weight: 400;
      opacity: 0.8;

      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0%;
        height: 0.5px;
        background: #fcb948;
        transition: all 0.2s ease-in-out;
      }
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
  .logout {
    align-self: flex-start;
  }

  //mobile

  .menu {
    display: flex;
    flex-direction: column;
    align-self: baseline;
    width: 100%;
    .menu-item {
      padding: 20px 0;
      height: 60px;
      width: 100%;
      border-top: 1px solid #dae0ec;
      &:last-child {
        border-bottom: 1px solid #dae0ec;
      }
    }
  }
  .logout {
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    line-height: 1;
    .logout-icon {
      path,
      line {
        stroke: $color-text;
      }
    }
    p {
      font-size: 22px;
    }
  }
}

.users {
  width: 100%;
}
