.small-claim-contract {
  .modal-content-container {
    padding-right: 35px;
    @include larger-than-mobile {
      padding-right: 65px;
    }
    .form-title {
      padding-bottom: 10px;
    }
    .section-container {
      position: relative;
      width: fit-content;
      display: flex;
      padding: 15px 0;
      .details {
        position: relative;
        margin-right: 30px;
        text-align: justify;
        .numbering {
          position: absolute;
          top: 0;
          right: -30px;
        }
        .sub-title {
          text-decoration: underline;
        }
      }
      .tooltip {
        position: absolute;
        right: -25px;
        top: 18px;
        height: 20px;
        width: 20px;
        @include larger-than-mobile {
          right: -35px;
          top: 20px;
        }
        img {
          height: 20px;
          width: 20px;
        }
        .top {
          text-align: right;
          width: 300px;
          @include larger-than-mobile {
            width: 400px;
          }
          right: 20px;
        }
      }

      &.discussion-invitation-tooltip {
        .tooltip {
          .top {
            top: 371px;
            @include larger-than-mobile {
              top: 291px;
            }
          }
        }
      }

      &.section-c-tooltip {
        .tooltip {
          .top {
            top: 432px;
            @include larger-than-mobile {
              top: 330px;
            }
          }
        }
      }

      &.section-d-tooltip {
        .tooltip {
          .top {
            top: 235px;
            @include larger-than-mobile {
              top: 186px;
            }
          }
        }
      }
      &.section-declaration {
        padding: 0;
        display: flex;
        flex-direction: column;
        position: unset;
        @include larger-than-mobile {
          height: 60px;
        }
        .declaration {
          .declaration-span {
            cursor: pointer;
            background-color: transparent;
            border: 1px solid rgb(175, 172, 172);
            border-radius: 10px;
            padding: 5px 8px;
            &:hover {
              border: 1px solid black;
            }
            &.add-deco {
              text-decoration: line-through;
            }
          }
        }
      }
    }
    .btn-sign {
      align-self: flex-start;
      margin-bottom: 5px;
      &.approve {
        margin-right: 5px;
      }
    }

    .signature {
      display: flex;
      padding: 20px 0;
      h4 {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
    }
    .modal-main {
      position: fixed;
      background: white;
      width: 420px;
      height: 360px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .container {
        .btn-container-modal {
          display: flex;
          gap: 10px;
          justify-content: center;
        }
      }
    }
    .display-block {
      display: block;
    }

    .display-none {
      display: none;
    }
    .sigContainer {
      zoom: 1.1;
      @include larger-than-mobile {
        zoom: 1.265;
      }
      width: 300px;
      height: 200px;
      margin: 20px auto;
      border: 1px solid #e6e9ef;
      .sigPad {
        width: 100%;
        height: 100%;
      }
    }
    .sigImage {
      align-self: center;
      margin-right: 80px;
      width: 50px;
      @include larger-than-mobile {
        width: unset;
      }
    }

    .btn-error-container {
      height: 80px;
      &.sign-b {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 10px 0;
      }
    }
    .sign-btn-container {
      width: fit-content;
      display: flex;
      text-align: center;
      // justify-content: space-between;
      .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
      }
    }

    .signature-b-container {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      margin-top: 20px;
      @include larger-than-mobile {
        height: 200px;
        margin-top: unset;
        flex-direction: row;
      }
      .date-sign-title {
        text-align: center;
        border-top: 1px solid black;
        width: 100%;
      }
      .date-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 150px;
        p {
          line-height: 1;
        }
      }
      .signature-b {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .signature {
          align-items: center;
          justify-content: center;
          @include larger-than-mobile {
            height: 100px;
          }
          div {
            text-align: center;
            // background-color: transparent;
          }
        }
        .btn-sign {
          margin-bottom: 0;
        }
      }
    }

    .error-label {
      width: fit-content;
    }
  }
}
