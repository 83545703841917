.small-claim-claimant {
  .title-tooltip-header {
    display: flex;
    align-items: baseline;
    width: fit-content;
    position: relative;
    .tooltip {
      @include larger-than-mobile {
        position: absolute;
        left: 130px;
        top: 3px;
      }
      .top {
        top: 20px;
        left: auto;
        text-align: right;
        transform: unset;
        max-width: 320px;
        opacity: 1;
        text-align: right;
      }
    }
    h2 {
      padding-right: 10px;
      @include larger-than-mobile {
        padding-right: 0;
      }
    }
  }
  .add-claimant {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    h3 {
      margin: 0 10px;
      font-size: 15px;
      @include larger-than-tablet {
        font-size: 25px;
        font-weight: 600;
      }
    }
  }
  .input-container {
   

    .field {
      &.small-plus {
        width: 150px;
        input {
          width: 100%;
        }
      }
      .postal-code-tooltip {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 5px;
        label,
        .tooltip {
          width: fit-content;
          img {
            height: 17px;
            width: 17px;
          }
        }
        .top {
          top: -3px;
          right: calc(100% - 150px);
          @include larger-than-mobile {
            right: unset;
            top: -1px;
          }
          color: #222222;
          opacity: 1;
        }
      }
    }
  }
}
