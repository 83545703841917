.screen {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 11;
  transition: background-color 0.3s, backdrop-filter 0.3s;

  &.open {
    background-color: #0006;
    backdrop-filter: blur(3px);
    pointer-events: all;
    &.no-header {
      top: 60px;
    }
  }
}
