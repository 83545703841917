.styled-choise-container {
  width: 100%;
  @include larger-than-mobile {
    width: 100%;
  }
  .styled-choise {
    display: flex;
    gap: 10px;
    background-color: #fff;
    // padding: 0 15px;
    border: 1px solid $color-form-border;
    box-shadow: 5px 5px 10px 1px #0001;
    width: 100%;
    height: 60px;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    p {
      color: $color-text;
      line-height: 1.2;
      min-width: 65px;
      user-select: none;
      margin-bottom: 5px;
    }
    .icon-choise {
      height: 40px;
      @include larger-than-mobile {
        height: 37px;
      }
    }
    .label-img-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      justify-content: space-around;
      @include larger-than-mobile {
        justify-content: center;
        flex-direction: column;
      }
    }
    input[type="radio"] {
      position: absolute;
      top: 15px;
      right: 15px;
      appearance: none;
      border: 1px solid $color-form-border;
      border-radius: 50%;
      background-clip: content-box;
      padding: 3px;
    }
    .checkmark {
      position: absolute;
      top: 20px;
      right: 22.5px;
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2.5px 2.5px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &.blue {
      background-color: $color-blue;
      p {
        color: white;
      }
    }
    @include larger-than-mobile {
      display: flex;
      flex-direction: row;
      width: 125px;
      height: 125px;
      padding: 10px;
      align-items: center;
      justify-content: center;
      position: relative;
      p {
        text-align: center;
        font-size: 18px;
        font-family: "AlmoniMedium";
        width: 60%;
      }
      // input[type="radio"] {
      //   position: absolute;
      //   top: 15px;
      //   right: 15px;
      //   appearance: none;
      //   border: 1px solid $color-form-border;
      //   border-radius: 50%;
      //   background-clip: content-box;
      //   padding: 3px;
      // }
      // .checkmark {
      //   position: absolute;
      //   top: 20px;
      //   right: 22.5px;
      //   width: 5px;
      //   height: 9px;
      //   border: solid white;
      //   border-width: 0 2.5px 2.5px 0;
      //   -webkit-transform: rotate(45deg);
      //   -ms-transform: rotate(45deg);
      //   transform: rotate(45deg);
      // }

      .tooltip {
        position: absolute;
        top: 15px;
        left: 15px;
      }
    }
  }
}
