.add-recipient-popup {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 15px;
  height: 50px;
  min-width: 300px;
  width: auto;
  padding: 10px;
  transform: translateX(-50%);
  background-color: #02864d;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  .add-recipient-popup-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding-left: 25px;
    .check-icon-white {
      img {
        height: 27px;
        width: 27px;
      }
    }
    p {
      color: white;
      opacity: 1;
      font: 500 19px "AlmoniMedium";
    }
  }
  .close-icon-white {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border-radius: 7px;
    &:hover{
      background-color: #037343;
    }
    img {
      height: 15px;
      width: 15px;
    }
  }
}
