.user-company {
  padding: 40px 57px 0 27px;
  padding-right: 45px;
  padding-top: 15px;
  @include larger-than-mobile {
    padding: 40px 57px 0 27px;
  }
  .user-company-title {
    font-family: "AlmoniBold";
    font-size: 28px;
    width: fit-content;
  }

  .form-main-toggle {
    --btn-width: 25px;
    --btn-height: 25px;
    border: 1px solid #e6e9ef;
    margin-left: 10px;
    span {
      &.gray {
        background-color: #d6dce8;
      }
    }
    &.un-pointer {
      button {
        cursor: default;
      }
    }
  }

  .company-name-container {
    border-bottom: 1px solid #c2cadb;
    // height: 135px;
    padding-bottom: 40px;
    input {
      font-size: 19px;
      height: 50px;
      margin-bottom: 8px;
    }
    .title-edit {
      display: flex;
      align-items: center;
      // margin-bottom: 5px;
      .user-company-title {
        margin-left: 10px;
      }
      .pencil {
        cursor: pointer;
      }
      .main-btn {
        width: 135px;
        height: 35px;
        margin-right: 10px;
        font-size: 17px;
        .spinner {
          height: 100%;
        }
      }
      input {
        // margin-bottom: 5px;
      }
    }
    .company-id {
      font-family: "AlmoniMedium";
      font-style: italic;
      font-size: 17px;
      color: #37383f;
    }
  }

  .internal-use-container {
    padding: 35px 0;
    border-bottom: 1px solid #c2cadb;
    .sub-title {
      opacity: 0.75;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .internal-use-options {
      .option-container {
        display: flex;
        justify-content: right;
        align-items: center;
        position: relative;
        .tooltip {
          position: absolute;
          right: -30px;
          top: 24px;
          .top {
            right: 0;
            font-family: "Almoni";
            @include larger-than-mobile {
              right: unset;
            }
          }
          .info-icon {
            height: 17px;
            width: 17px;
          }
        }
        .option-title {
          font-family: "AlmoniDemiBold";
          font-size: 19px;
          color: #37383f;
          min-width: 100px;
        }
        .form-main-toggle {
          --btn-width: 25px;
          --btn-width: 70px;
          --btn-height: 25px;
          --btn-height: 45px;
          margin-left: 10px;
          border-radius: 10px !important;
          background-color: white;
          color: #3f4956;
          span {
            border-radius: 10px !important;
            &.gray {
              background-color: #3f4956;
            }
          }
          button {
            font-size: 17px;
          }
        }
      }
    }
  }

  .users-of-company-container {
    padding: 35px 0;
    .user-company-title {
      margin-bottom: 10px;
    }
    .users-alert-title {
      text-align: center;
      padding-top: 30px;
    }
    .search-field {
      width: 100%;
      font-size: 17px;
      background-color: white;
      height: 55px;
      justify-content: space-between;
      @include larger-than-mobile {
        width: 300px;
      }
      &:focus-within {
        border-color: #727989;
      }
      padding: 0 15px;
      margin: 10px 0 10px 0;
      // margin: 10px 0 25px 0;
      border: 1px solid $color-form-border;
      // border: 1px solid #3f4956;
      border-radius: 15px;
      display: flex;
      gap: 15px;
      img {
        color: #e6e9ef;
        height: 50px;
        width: 20px;
      }
      .search-input {
        color: $color-text;
        width: 350px;
        font-size: 17px;
        padding: 0;
        opacity: 1;
        color: #212129;
        border: 0;
        height: auto;
        &::placeholder {
          color: $color-text;
          color: #a1a9b0;
          font-size: 16px;
          // @include larger-than-mobile {
          //   font-size: 25px;
          // }
          opacity: 0.65;
        }
        &:hover,
        &:focus {
          border: none;
        }
      }
    }
    .users-table {
      width: 100%;
      td {
        padding-bottom: 20px;
        // text-align: center;
        p {
          color: #37383f;
        }
        .top{
          color: white;
        }
      }
      .users-table-header {
        .users-table-title {
          // padding-left: 15px;
          font-family: "AlmoniBold";
          font-size: 18px;
          text-align: center;
        }
      }

      .spinner {
        height: 15px;
      }
      .is-master-td {
        // max-width: 100px;
        display: flex;
        // justify-content: center;
        align-items: center;
        position: relative;
        h4 {
          min-width: 100px;
        }
        .tooltip {
          position: absolute;
          right: -30px;
          top: 6px;
          img {
            height: 17px;
            width: 17px;
          }
          // top: 15px;
        }
        // img,
        // p {
        //   margin-right: 40px;
        //   cursor: pointer;
        // }
        .spinner {
          justify-content: flex-start;
          margin-right: 42px;
        }
      }
      .name-td {
        text-align: center;
        h4 {
          min-width: 70px;
        }
        p {
          font-size: 20px;
          font-family: "AlmoniDemiBold";
          padding: 0px 5px;
        }
      }
      .user-name-td {
        font-family: "Almoni";
        text-align: center;
        p {
          opacity: 0.75;
          font-size: 17px;
        }
      }
      .user-status-td {
        // min-width: 80px;
        // min-height: 80px;
        text-align: center;
        p {
          text-align: center;
          // margin-right: 15px;
          opacity: 0.75;
          font-size: 16px;
        }
      }
      .user-send-details-td {
        display: flex;
        justify-content: center;
        h4 {
          text-align: center;
        }
      }
      .user-block-td {
        text-align: center;
      }
    }

    .btn {
      font-family: "Almoni";
      border: 1px solid #e6e9ef;
      border-radius: 20px;
      width: 150px;
      background-color: transparent;
      padding: 10px 10px;
      margin-top: 3px;
      &:hover {
        transform: scale(0.96);
      }
      .spinner {
        svg {
          height: 18px;
        }
      }
    }

    .users-spinner {
      height: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .users-cards {
      .user-card {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 60px;
        height: 60px;
        border: 1px solid #e6e9ef;
        border-radius: 10px;
        margin-bottom: 10px;
        transition: 0.5s;
        padding: 15px;
        flex-direction: column;
        .user-card-details {
          opacity: 0;
          // display: none;
          // transition: 1s;
        }
        &.open-user-card {
          transition: 0.5s;
          // height: unset;
          min-height: 215px;
          // height: 225px;
          // padding-bottom: 100px;
          align-items: flex-start;
          .user-card-details {
            transition: 0.5s;
            opacity: 1 !important;
            // display: block !important;
            .user-details-p {
              margin-top: 5px;
              font-size: 17px;
              color: #37383f;
            }
            .user-card-action {
              .type-title {
                font-size: 17px;
                margin-left: 10px;
                color: #37383f;
              }
              > div {
                display: flex;
                flex-direction: row;
                align-items: center;
                min-height: 45px;
              }
              .user-status {
                p {
                  text-align: center;
                  font-size: 15px;
                  color: #3b4148;
                  opacity: 0.75;
                }
              }
            }
          }
        }
        .user-card-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .name {
            font-size: 19px;
            font-family: "Almoni";
          }
          img {
            cursor: pointer;
            height: 12px;
            width: 12px;
          }
        }
      }
    }
    .add-user-container {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .btn-add {
        margin-left: 10px;
        font-size: 40px;
      }
      p {
        font-family: "AlmoniMedium";
        font-size: 18px;
      }
    }
  }
}
