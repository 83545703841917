.certificate-method {
  .modal-content-container {
    padding-top: 65px;
    .form-main-toggle {
      margin-bottom: 30px;
    }
    .subtitle {
      .underline {
        text-decoration: underline;
      }
    }
  }
}
