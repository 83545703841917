.apostil-container {
  padding-top: 30px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  .dropdown-icon-align {
    display: flex;
    flex-direction: column;
    @include larger-than-mobile {
      flex-direction: row;
      gap: 30px;
    }
    align-items: right;
    gap: 15px;

    .country-msg {
      display: flex;
      align-items: center;
      gap: 10px;
      > p {
        margin: 0;
        font-size: 19px;
      }
    }
  }

  .section-choose {
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    padding-top: 40px;

    border-top: 1px solid $color-form-border;
    flex-direction: column;
    h3 {
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 8px;
      font-size: 15px;
      .url-info {
        text-decoration: underline !important;
        cursor: pointer;
      }
      @include larger-than-mobile {
        font-size: 20px;
      }
    }
    .note {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
      .textTop {
        font-size: 19px;
      }

      .textBottom {
        font-size: 14px;
      }
    }
    .dropdown-container {
      align-self: flex-start;
      display: flex;
      position: relative;
      width: 100%;
      @include larger-than-mobile {
        width: 450px;
      }
      &.open {
        .dropdown-input button img {
          transform: scale(1, -1);
        }
        .dropdown-list {
          height: 290px;
          border: 1px solid $color-form-border;
          box-shadow: 5px 5px 10px 1px #0001;
          border: 1px solid transparent;
          overflow-y: scroll;
        }
      }

      .dropdown-input {
        right: 0;
        display: flex;
        width: 100%;
        @include larger-than-mobile {
          width: 450px;
        }
        height: 60px;
        border: 1px solid $color-form-border;
        justify-content: space-between;
        align-items: center;
        border-radius: 15px;
        overflow: hidden;

        > input {
          border: none;
          font-size: 19px;
        }

        button {
          > img {
            margin: 0;
          }
          width: 50px;
          align-self: stretch;
          border: none;
          background-color: #f6f7f9;
          opacity: 0.5;
          border-right: 1px solid $color-form-border;
        }
      }
      .dropdown-list {
        position: absolute;
        right: 0;
        transform: translateY(61px);
        display: flex;
        flex-direction: column;
        width: 100%;
        @include larger-than-mobile {
          width: 450px;
        }
        border-radius: 15px;
        height: 0;
        overflow: hidden;
        transition: height 0.2s;
        background-color: #fff;
        z-index: 2;
      }

      .dropdown-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // gap: 15px;
        border-bottom: 1px solid $color-form-border;

        > p {
          margin: 0;
          width: 100%;
        }
        > div {
          display: flex;
          justify-content: flex-end;
        }

        &:last-child {
          border-bottom: unset;
        }

        > * {
          // width: 100%;
          cursor: pointer;
          padding: 15px;
        }

        input {
          height: unset;
        }

        label {
          margin: 0;
        }
      }
    }
  }
}

.certificate-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0px 0 20px 0;
  .example-container {
    padding: 10px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: 1px solid #e6e9ef;
    align-items: center;
    > a {
      width: 100%;
      color: inherit;
      .example-icon {
        width: 100%;
        // background-color: #f2f4f7;
        background-color: #4e71ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;
        // color: #9c9fa3;
        color: white;
        font-size: 13px;
        border-radius: 5px;
        margin-bottom: 12px;
        height: 25px;
        padding: 25px;
        font-family: "AlmoniBold";
        &.apostilSubmission-btn {
          background-color: #2fe071;
        }
        &.apostilInCourt-btn {
          background-color: #9969ca;
        }
        @include larger-than-mobile {
          width: 100%;
          height: 35px;
          border-radius: 10px;
          padding: 20px;
          font-size: 15px;
          &:hover {
            transform: scale(0.9);
          }
        }
      }
    }
  }

  h3 {
    text-align: right;
    margin-top: 20px;
    margin-bottom: 10px;
    @include larger-than-mobile {
      margin-top: 5px;
    }
  }
  width: 100%;
  @include larger-than-mobile {
    width: 100%;
  }
  .titles {
    display: flex;

    margin-bottom: 10px;
    > * {
      font-size: 15px;
      font-weight: 600;
      line-height: 1;
      text-align: right;
      &:first-child {
        width: 20%;
      }
      &:nth-child(2) {
        width: 16%;
      }
      width: 26%;
      &:last-child {
        width: 7%;
      }
    }
  }
  .certificate {
    display: flex;
    flex-direction: column;

    padding: 15px 15px 0 15px;
    border: 1px solid $color-form-border;
    box-shadow: 5px 5px 10px 1px #0001;
    border-radius: 15px;

    width: 100%;
    @include larger-than-mobile {
      width: 350px;
      // height: 328px;
      height: 376px;
      &.no-height {
        height: unset;
      }
    }
    height: fit-content;
    @include larger-than-mobile {
      width: 350px;
    }

    .details {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      > p {
        font-size: 17px;
        font-weight: 600;

        @include larger-than-mobile {
          font-size: 21px;
          font-weight: 600;
        }
        text-align: right;
      }
    }
    .status {
      display: flex;
      align-items: right;
      border-top: 1px solid $color-form-border;
      padding: 10px 0;

      label.container {
        color: $color-text;
        opacity: unset;
        display: flex;
        align-items: top;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      &.with-info {
        justify-content: space-between;
        .info-icon {
          width: 20px;
          height: 20px;
        }
        .tooltip {
          .top {
            top: -15px;
            left: -150px;
            width: 300px;
          }
        }
      }
      &.number {
        display: flex;

        align-items: center;
        .number-input {
          width: 100px;
          height: 40px;
          background-color: $color-blue;
          border-radius: 40px;
          padding: 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          input.quantity {
            color: white;
            background-color: $color-blue;
            text-align: center;
            width: 40%;
            height: 40px;
            border: none;
            padding: 0;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            -moz-appearance: textfield;
          }
          .minus,
          .plus {
            background-color: #3b55bf;
            border: none;
            color: white;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
          }
        }
      }

      > div {
        display: flex;
      }
      p {
        // max-width: 200px;
        line-height: 1.2;
        margin: 0 10px;
        &.checkbox-text {
          padding-right: 30px;
        }
        font-size: 15px;
        @include larger-than-mobile {
          font-size: 17px;
          font-weight: 500;
        }
      }
      text-align: right;
    }

    .btn-container {
      display: flex;

      // a {
      //   color: inherit;
      //   .example-icon {
      //     background-color: #f2f4f7;
      //     display: flex;
      //     justify-content: center;
      //     align-items: center;
      //     line-height: 1;
      //     color: #9c9fa3;
      //     font-size: 12px;
      //     border-radius: 5px;
      //     width: 32px;
      //     height: 25px;
      //     @include larger-than-mobile {
      //       width: 42px;
      //       height: 35px;
      //       border-radius: 10px;
      //       font-size: 14px;
      //       &:hover {
      //         transform: scale(0.9);
      //       }
      //     }
      //   }
      // }
      img {
        cursor: pointer;
        width: 25px;
        height: 25px;
        @include larger-than-mobile {
          width: 35px;
          height: 35px;
          &:hover {
            transform: scale(0.9);
          }
        }
      }
      display: flex;
      gap: 10px;
    }
  }
}
