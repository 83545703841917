.actions-summary {
  .summary-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    h3 {
      margin-bottom: 10px;
    }
    .deposit {
      .deposit-header {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .btn-container {
        display: flex;
        gap: 10px;
        margin-bottom: 11px;
        > img {
          cursor: pointer;
          &:hover {
            transform: scale(0.95);
          }
        }
      }
    }
    .collect {
      .submitter-files-list {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        flex-wrap: wrap;
        .submitter-file-preview {
          width: 100%;
          @include larger-than-mobile {
            width: 300px;
          }
          display: flex;
          flex-direction: column;
          gap: 15px;
          cursor: pointer;
          padding: 25px 20px 25px 20px;
          border: 1px solid #e6e9ef;
          border-radius: 23px;
          background-color: white;
          &.selected {
            border: 1px solid #929292;
          }
          .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              font-size: 19px;
              font-weight: 600;
            }
            .btn-container {
              margin-right: 15px;
              display: flex;
              gap: 10px;
              img {
                width: 25px;
                max-height: 25px;
                &:hover {
                  transform: scale(0.95);
                }
              }
            }
          }
          &:hover {
            border: 1px solid #929292;
          }
        }
      }
      .deposit-details {
        margin-block-end: 40px;
      }
      .submiter-details {
        display: flex;
        gap: 30px;
        align-items: center;
        .btn-container {
          display: flex;
          gap: 10px;
          > img {
            object-fit: contain;
            height: 30px;
            cursor: pointer;
            &:hover {
              transform: scale(0.95);
            }
          }
        }
      }
      .input-container {
        margin-top: 15px;
        display: flex;
        align-items: center;
        > img {
          margin-block-start: 14px;
          object-fit: contain;
          height: 30px;
          cursor: pointer;
          &:hover {
            transform: scale(0.95);
          }
        }
        .field {
          height: 85px;
        }
        input {
          background-color: #eee;
          color: $color-text;
        }
      }
    }
  }
  .add-recipient {
    display: flex;
    gap: 15px;
    align-items: center;
    h3 {
      margin: 0;
      font-size: 20px;
    }
  }
}
