.filters-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  z-index: 100;
  padding: 0;
  height: 100%;
  width: 90%;
  background: white;
  top: 0;
  right: 0;
  padding: 30px 20px;

  transform: translateX(100%);
  transition: transform 0.3s;

  &.open {
    transform: translateX(0);
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-weight: 500;
      font-size: 25px;
    }
    .close-icon {
      background-color: white;
      box-shadow: 1px 5px 10px 1px #0001;
      img {
        height: 13.5px;
        width: 13.5px;
      }
    }
  }

  .filter-title {
    font-size: 16px;
    margin-bottom: 7px;
  }
  .filters {
    flex-direction: column;
    border-bottom: 1px solid #e6e9ef;
    padding-bottom: 30px;
    .filter {
      padding: 20px 0;
      .dropdown-address-container {
        width: 100%;
        .dropdown-input {
          width: 100%;
          button {
            width: 50px;
          }
        }
        .dropdown-list {
          width: 100%;
          .dropdown-option {
          }
        }
      }
    }
  }
  .search {
    padding: 30px 0;
    .title-search-container {
      padding-top: 0;
    }
  }

  .main-btn {
    align-self: center;
    margin-top: auto;
  }
}
