.user-details {
  h3.title {
    margin-top: 15px;

    font-size: 21px;
    @include larger-than-mobile {
      font-size: 28px;
      margin-top: 0;
    }
  }
  padding: 10px 20px 0 20px;
  @include larger-than-tablet {
    padding: 40px 57px 0 57px;
  }
  .main-input-container {
    margin-bottom: 25px;
  }
  .field {
    height: 110px;
    margin-bottom: 0;
  }
  input {
    width: 100%;
    @include larger-than-mobile {
      width: 325px;
    }
    &.disabled {
      background-color: rgba(238, 238, 238, 0.856);
    }
  }
  .main-btn {
    width: 325px;
    margin-bottom: 10px;
    &.update-email-btn {
      height: 45px;
      width: 250px;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
    }
  }
  .btn-container {
    display: flex;
    gap: 20px;
    margin: 15px 0;
    .btn {
      font-family: "Almoni";
      border: 1px solid #e6e9ef;
      &:hover {
        border: 1px solid #9c9ea2;
      }
      border-radius: 20px;
      width: 150px;
      background-color: transparent;
      padding: 10px 10px;
    }
  }
  .icon-input {
    width: 100%;
    @include larger-than-mobile {
      width: 325px;
    }
    input {
      font-size: 17px;
      padding: 0 25px;
    }
  }
  h3.btms-titles {
    margin: 20px 0 10px 0;
  }
  .license {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 14px;
      color: #222;
      opacity: 0.65;
    }
  }
}
