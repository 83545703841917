.document-ready-smaller {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  // border-bottom: 1px solid $color-border-line;
  @include larger-than-mobile {
    flex-direction: row;
  }
  .tooltip{
    display: flex;
    flex-direction: row;
  }
  p.subtitle {
    @include larger-than-mobile {
      margin-bottom: 15px;
    }
  }
  h3 {
    white-space: nowrap;
    margin-bottom: 0;
  }
  h3.small {
    font-size: 19px;
    opacity: 1;
    width: 100%;
    text-align: right;
    margin-top: 0;
    @include larger-than-mobile {
      width: fit-content;
      display: flex;
      align-self: center;
      font-size: 24px;
      margin-left: 20px;
    }
  }

  .input-date {
    // width: 240px;
    display: flex;
    flex-direction: row;
    width: 100%;
    > div {
      width: 100%;
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
    @include larger-than-mobile {
      > div {
        width: unset;
        .react-datepicker.wrapper {
          width: unset;
        }
      }
    }
    h3 {
      font-size: 21px;
      align-self: center;
      margin-left: 20px;
    }
    input {
      font-size: 19px;
      width: 100%;
    }
  }
  .block-select {
    border-bottom: unset;
    display: flex;
    align-self: flex-start;
    align-items: center;
    @include larger-than-mobile {
      margin-left: 30px;
    }
    &.border-left {
      border-left: 1px solid #d5dbe7;
    }
    .form-main-toggle {
      --btn-width: 55px;
      --btn-height: 45px;
      margin-left: 30px;
      border-radius: 10px !important;
      background-color: white;
      color: #3f4956;
      margin-bottom: 15px;
      @include larger-than-mobile {
        margin-bottom: 10px;
      }
      span {
        border-radius: 10px !important;
        &.gray {
          background-color: #acb3c2;
        }
      }
      button {
        font-size: 20px;
      }
    }
  }

  &.isSign {
    .block-select {
      margin-left: 0;
    }
  }
}
