.final-page {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  gap: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  @include larger-than-mobile {
    gap: 50px;
  }

  h2 {
    font-size: 35px;
    margin-bottom: 20px;
  }

  h3 {
    margin: 0;
    text-align: right;
    font-size: 28px;
    margin-bottom: 10px;
  }
  h4 {
    margin-bottom: 5px;
  }

  .left {
    position: sticky;
    top: 0;
    border: none;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 60px;
    @include larger-than-mobile {
      // width: fit-content;
      max-width: 480px;
      border: 1px solid $color-border-finalpage;
      border-radius: 15px;
      padding: 30px;
    }
    &.no-price-action {
      border: none;
    }
    height: fit-content;
    .cost-list-item {
      border-bottom: 1px solid $color-border-finalpage;

      padding: 10px 0;
      display: flex;
      flex-direction: column;
      .cost-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .cost-item-description {
          display: flex;
          justify-content: space-between;
          p.sud-title {
            width: 80%;
          }
          .price {
            width: 100px;
          }
          > .cost-item-sub-description {
            color: #222222;
            opacity: 0.65;
            // @include larger-than-mobile {
            //   width: unset;
            // }
          }
        }
        .cost-item-text {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .price {
      font-style: italic;
      width: 100px;
      text-align: left;
      margin-right: 20px;
    }
    .bold {
      font-weight: 600;
      color: $color-text;
      opacity: unset;
    }
    .extras {
      // border-top: 1px solid $color-border-finalpage;
      padding: 10px 0;
      width: 100%;
      .extras-item {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
  .total {
    display: flex;
    // border-top: 1px solid $color-border-finalpage;
    padding-top: 10px;

    flex-direction: column;
    .total-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .price {
        flex-grow: 1;
        &.bid{
          text-align: right;
          margin-right: 0;
        }
      }

    }
  }

  .right {
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid $color-border-finalpage;
    @include larger-than-mobile {
      // width: fit-content;
      border-bottom: none;
      min-width: 420px;
      max-width: 480px;
    }

    .order-summary-list {
      margin-bottom: 20px;
    }

    .order-summary-item {
      padding: 15px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &:first-of-type {
        border-top: 1px solid $color-border-finalpage;
      }
      border-bottom: 1px solid $color-border-finalpage;

      .textarea {
        align-items: flex-start;
        flex-direction: column;
        border-bottom: unset;
      }
      .handed-list {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }
      .submiter-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      img {
        width: 35px;
        height: 35px;
        cursor: pointer;
        &:hover {
          transform: scale(0.95);
        }
      }
      P.details {
        opacity: 0.65;
        line-height: 1.6;
      }
      p {
        line-height: 1.1;
      }
    }
    p.italic {
      font-style: italic;
    }

    .contact {
      display: flex;
      padding-bottom: 15px;
      margin-bottom: 15px;
      flex-direction: column;
      justify-content: right;
      border-bottom: 1px solid $color-border-finalpage;
      p {
        font-weight: 600;
        opacity: unset;
      }

      .contact-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        .name-icon,
        .icon-input {
          font-size: 18px;
          overflow: hidden;
          width: 300px;
          height: 50px;
          border: 1px solid $color-form-border;
          cursor: text;
          input {
            font-size: 20px;
            height: 50px;
          }
          .icon {
            font-size: 20px;
            line-height: 1;
            border-right: 1px solid $color-form-border;
          }
          &:focus-within,
          &:hover {
            border: 1px solid black;
          }
        }
      }

      .name-input {
        width: 300px;
        height: 50px;
        font-size: 20px;
        padding: 10px;
        border: 1px solid $color-form-border;
        cursor: text;
        &:focus-within,
        &:hover {
          border: 1px solid black;
        }
        &.disabled {
          background-color: #eee;
        }
      }
    }
    textarea {
      height: 100px;
      width: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        background: transparent;
        width: 18px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 100vw;
        background: transparent;
        border: 6px solid transparent;
        box-shadow: inset 0 0 7px 7px $color-blue;
      }
      padding: 15px;
      cursor: text;
      margin-bottom: 15px;
    }
    .internal-use {
      h3 {
        margin-bottom: 10px;
      }
      .internal-use-field {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $color-border-finalpage;
        padding-top: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        gap: 15px;
      }
      input {
        width: 275px;
        padding: 15px;
        height: 55;
      }
    }
  }
}
