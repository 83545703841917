.page-layout {
  padding: 30px 25px;
  @include larger-than-mobile {
    padding: 50px 80px;
  }
  min-height: calc(var(--app-height));
  @include larger-than-mobile {
    min-height: calc(var(--app-height) / 0.8 - 60px);
  }
  @include larger-than-tablet {
    min-height: calc(var(--app-height) / 0.78 - 60px - 340px);
  }
  @include larger-than-large {
    min-height: calc(var(--app-height) / 0.8 - 60px - 340px);
  }
}
