.form7-addresses-update {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.update-cmps {
    // overflow-y: hidden;
    display: contents !important;
  }
  .modal-content-container {
    // padding: 0;
    .tooltip {
      .top {
        opacity: 1;
        font-size: 19px;
      }
    }
    .field {
      height: fit-content;
    }
    .choose-address-btn {
      height: 60px;
      width: 125px;
      border-radius: 10px;
      color: #222222;
      background-color: #fff;

      font-size: 20px;
      cursor: pointer;
      border: 1px solid #b3b3b3;
      display: flex;
      justify-content: center;
      align-items: center;
      &.selected {
        background-color: #4e71ff;
        color: #fff;
      }
      &:hover {
        transform: scale(0.98);
      }
    }
    .add-address {
      @include larger-than-tablet {
        margin: 20px 0;
      }
      align-self: right;
      display: flex;
      align-items: center;

      h3 {
        margin: 0 10px;
        font-size: 15px;
        @include larger-than-tablet {
          font-size: 25px;
          font-weight: 600;
        }
      }
    }
  }
  h2.large-title {
    margin: 30px 0;
  }

  &.self-form-style {
    .modal-content-container {
      padding: 0;
    }
    h2.large-title {
      align-self: flex-start;
      margin-top: 30px;
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  h3 {
    @include larger-than-mobile {
      margin-top: 20px;
    }
  }

  label {
    margin-bottom: 3px;
  }
  .input-container {
    &.data-desktop {
      .field {
        position: relative;
        label {
          position: absolute;
          top: -40px;
          color: #222;
          line-height: 1.58em;
          font-size: 17px;
        }
      }
    }
    &.extra-address {
      .radio-container {
        width: 100%;
        border-bottom: none;
        @include larger-than-mobile {
          width: auto;
        }
      }
    }
  }
  .addresses-titles {
    display: flex;
    flex-direction: row;
    grid-column-gap: 13px;
    grid-row-gap: 12px;
    // margin-right: 33px;
    p {
      text-align: right;
    }
    .point {
      width: 20px;
    }
    .large-title {
      width: 285px;
    }
    .small-title {
      width: 115px;
    }
  }

  .input-container {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 1px solid $color-border;
    padding: 15px 0 0 0;
    @include larger-than-tablet {
      flex-wrap: nowrap;
    }

    &:last-child {
      margin-top: 0;
      // padding-top: 0;
      border-bottom: unset;
    }

    .radio-btn {
      width: 20px;
      aspect-ratio: 1;
      transform: translateY(15%);
      @include larger-than-mobile {
        transform: translateY(50%);
      }
    }

    .disabled {
      background-color: $color-background-disabled;
      border: 1px solid $color-form-border;
      font-size: 15px;
      @include larger-than-mobile {
        font-size: 20px;
      }
    }

    .med {
      width: 100%;
      @include larger-than-mobile {
        width: 260px;
      }
      @include larger-than-normal {
        width: 230px;
      }
      @include larger-than-x-large {
        width: 285px;
      }
    }

    .small {
      @include larger-than-mobile {
        max-width: 115px;
      }
    }
  }

  .new-address {
    margin: 15px 0;
    h3 {
      font-size: 15px;
      font-weight: 600;
      margin: 0;
    }
    display: flex;
    @include larger-than-tablet {
      display: inline-block;
    }
    align-items: center;
    // justify-content: center;
    gap: 15px;
    .radio-btn {
      width: 20px;
      height: 20px;
    }
  }

  .mobile-address-container {
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    margin: 10px 0;
    box-shadow: 0px 3px 10px rgb(0 0 0 / 20%);
    border-radius: 10px;
    p {
      opacity: 0.65;
      font-size: 14px;
      &:first-child {
        opacity: 1;
      }
      line-height: 1.2;
    }

    .radio-btn {
      width: 20px;
      height: 20px;
    }
  }
}
