.email-sent {
    height: 100%;
    width: 100%;
    @include larger-than-mobile {
        border-radius: 40px;
        min-height: 750px;
        width: 650px;
    }
    position: relative;
    > svg {
        position: absolute;
        z-index: 1;
        top: 35px;
        left: 35px;
        cursor: pointer;
    }
    .system-msg {
        position: relative;
        background: url("../../images/6464.png"), linear-gradient(180deg, #edf3fc, #fff, #fff);
        @include larger-than-mobile {
            min-height: 750px;
            width: fit-content;
        }
        background-size: 100% 100%;

        background-color: #fff;
        background-repeat: no-repeat;
        border: 1px solid $color-border;
        padding: 0 30px;
        border-radius: none;
        @include larger-than-mobile {
            border-radius: 40px;

            padding: 30px 50px;
        }
        width: 100%;
        height: 100%;
        @include larger-than-mobile {
            height: 100%;
            width: 100%;
        }
        display: flex;
        flex-direction: column;

        @include larger-than-mobile {
            justify-content: center;
        }
        align-items: center;
        gap: 15px;
        box-shadow: 5px 5px 10px 1px #0002;
        .btn-close {
            width: 40px;
            aspect-ratio: 1;
            cursor: pointer;
            position: absolute;
            z-index: 5;
            top: 20px;
            left: 20px;
        }
        .msg-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            width: 100%;
            margin: 15px 0;
            @include larger-than-mobile {
                margin-top: 90px;
            }
            margin-top: 130px;
            .main-title {
                text-align: center;
            }
            h3 {
                font-size: 40px;
                text-align: center;
                margin: 0;
            }
            p.decription {
                opacity: none;
                font-size: 19px;
                text-align: center;
                color: #000;
                margin-bottom: 20px;
                > span {
                    margin-right: 8px;
                }
            }
            .main-btn {
                font-size: 18px;
            }
        }

        // &.blocked-msg {
        //     display: flex;
        //     justify-content: flex-start;
        //     background: linear-gradient(180deg, #edf3fc, #fff, #fff);
        //     border: 0;
        //     border-radius: unset;
        //     @include larger-than-mobile {
        //         min-height: 880px;
        //     }
        // }
    }
}
