.main-form {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }

  @include larger-than-tablet {
    &::-webkit-scrollbar {
      display: inline-block;
      background: transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
  .dropdown-input {
    border-radius: 10px !important;
    background-color: #fff;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"],
  textarea,
  .icon-input {
    background-color: #fff;
    border-radius: 10px;
  }
}

.modal-content-container {
  width: 100%;
  padding: 50px 25px 0 25px;
  @include larger-than-tablet {
    padding: 0 45px;
  }

  &:not(.block) {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    &.final-page {
      flex-direction: row;
    }
  }
  &.block {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    @include larger-than-mobile {
      padding-top: 125px;
      &.low {
        padding-top: 177px;
      }
    }
  }
  &.block-update {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: 0;
    margin-top: 0;
  }

  // FORM- TYPOGRAPHY

  h2.large-title {
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 10px;
    align-self: center;
    text-align: center;
    @include larger-than-mobile {
      font-size: 50px;
      max-width: 80%;
    }
  }

  h2.med-title {
    font-size: 30px;
    margin-bottom: 20px;
    align-self: right;

    @include larger-than-mobile {
      font-size: 35px;
      &.right {
        align-self: right;
        margin-top: 0px;
        @include larger-than-mobile {
          // margin-top: 15px;
          line-height: 0.8;
        }
      }
    }
  }

  h3 {
    font-size: 19px;
    margin-bottom: 8px;
    @include larger-than-mobile {
      font-size: 30px;
      &.small {
        font-size: 26px;
        margin-top: 8px;
      }
    }
    &.line {
      border-top: 1px solid #c2cadb;
      padding-top: 20px;
    }
    &.mobile-line {
      border-top: 1px solid #c2cadb;
      padding-top: 20px;
      @include larger-than-mobile {
        border-top: unset;
        padding-top: unset;
      }
    }
  }

  p {
    color: $color-p-label;
    line-height: 1.58em;
    font-size: 20px;
    &.p-bold {
      font-weight: 600;
      font-size: 16px;
      @include larger-than-mobile {
        font-size: 17px;
        &.p-med-bold {
          font-size: 21px;
        }
      }
    }

    &.subtitle {
      opacity: 0.65;
      align-self: center;
      text-align: center;
      font-size: 18px;
      max-width: 80%;
      margin-bottom: 20px;
      @include larger-than-mobile {
        font-size: 24px;
        margin-bottom: 30px;
      }
    }
    &.subsubtitle {
      opacity: 0.65;
      align-self: center;
      text-align: center;
      font-size: 16px;
      max-width: 80%;
      margin-bottom: 10px;
      @include larger-than-mobile {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
    &.regular {
      opacity: unset;
      font-size: 14px;
      @include larger-than-mobile {
        font-size: 21px;
      }
    }
  }
  label {
    color: $color-p-label;
    // opacity: 0.65;
    &.checkbox-container {
      opacity: 1;
    }
    line-height: 1.58em;
    &.small {
      font-size: 15px;
      @include larger-than-mobile {
        font-size: 17px;
      }
    }
    &.regular {
      font-size: 16px;
      @include larger-than-mobile {
        font-size: 17px;
      }
    }
  }
}

label {
  color: $color-p-label;
  font-size: 15px;
  // opacity: 0.65;
  @include larger-than-mobile {
    font-size: 20px;
  }
}

.header {
  display: flex;
}

.toggleButtonGroup {
  background-color: #4e71ff;
  color: #fff;
}

.nis {
  font-size: 30px;
}

.MuiOutlinedInput-root {
  border-radius: 15px;
  input {
    border: unset;
  }
}
