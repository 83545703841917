.document-transfer-type {
  .modal-content-container {
    padding-top: 45px;

    .toggles {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 15px 0 25px 0;
      // border-bottom: 1px solid #c2cadb;
      .form-main-toggle {
        button {
          font: 400 14px "Almoni";
          @include larger-than-mobile {
            font: 400 21px "Almoni";
          }
        }
      }
    }

    .recip-details-container {
      width: 100%;
      text-align: right;
      .modal-content-container {
        padding: 0;
      }
      .document-ready-smaller {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .sign-options-toggle {
      width: 100%;
      @include larger-than-mobile {
        margin-right: 30px;
        width: 350px;
        margin-left: 10px;
        .dropdown-address-container .dropdown-list {
          width: 350px;
          p {
            font-size: 17px;
          }
        }
      }
      .dropdown-address-container.open .dropdown-list {
        height: 175px;
      }
    }

    // .delivery-package-size {
    //   .styled-choises {
    //     .tooltip {
    //       .top {
    //         right: 0;
    //       }
    //     }
    //   }
    // }

    //Scss styling for dropdown
    // .document-transfer-type-container {
    //   display: flex;
    //   flex-direction: column;
    //   gap: 15px;
    //   align-items: center;
    //   text-align: center;
    //   align-self: flex-start;
    //   width: 100%;
    //   margin-bottom: 30px;
    //   margin-top: 15px;
    //   @include larger-than-mobile {
    //     width: unset;
    //   }
    //   .block {
    //     padding-top: 45px;
    //   }
    //   h2.large-title {
    //     margin-bottom: 20px;
    //     @include larger-than-mobile {
    //       margin-top: 20px;
    //       margin-bottom: 5px;
    //     }
    //   }
    //   p.subtitle {
    //     display: none;
    //     @include larger-than-mobile {
    //       display: inline-block;
    //       margin-bottom: 15px;
    //     }
    //     max-width: unset;
    //   }
    //   // h3 {
    //   //   width: 100%;
    //   //   text-align: right;
    //   //   font-size: 30px;
    //   // }
    //   // .styled-choises {
    //   //   display: flex;
    //   //   flex-direction: column;
    //   //   gap: 15px;
    //   //   width: 100%;
    //   //   @include larger-than-mobile {
    //   //     flex-direction: row;
    //   //   }
    //   //   .tooltip {
    //   //     .top {
    //   //       right: 0;
    //   //     }
    //   //   }
    //   // }

    //   .dropdowns {
    //     margin-top: 20px;
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     @include larger-than-tablet {
    //       width: 80%;
    //       flex-direction: row;
    //     }
    //     align-items: center;
    //     justify-content: center;
    //   }

    //   .transfer-type {
    //     width: 100%;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     gap: 10px;

    //     @include larger-than-mobile {
    //       flex-direction: row;
    //       justify-content: flex-start;
    //     }
    //     // width: 100%;

    //     .dropdown-container {
    //       align-self: flex-start;
    //       display: flex;
    //       position: relative;
    //       width: 100%;
    //       @include larger-than-mobile {
    //         width: 375px;
    //       }
    //       .document-transfer-text {
    //         font-size: 20px;
    //         opacity: 0.65;
    //         padding-right: 15px;
    //       }
    //       .dropdown-input {
    //         background-color: #fff;
    //       }
    //       &.open {
    //         .dropdown-input button img {
    //           transform: scale(1, -1);
    //         }
    //         .dropdown-list {
    //           z-index: 1;
    //           width: 100%;
    //           @include larger-than-mobile {
    //             width: 375px;
    //           }
    //           height: 165px;
    //           height: auto;
    //           border: 1px solid $color-form-border;
    //           box-shadow: 5px 5px 10px 1px #0001;
    //           border: 1px solid transparent;
    //         }
    //       }

    //       .dropdown-input {
    //         cursor: pointer;
    //         width: 100%;
    //         @include larger-than-mobile {
    //           width: 375px;
    //         }
    //         right: 0;
    //         display: flex;
    //         height: 60px;
    //         border: 1px solid $color-form-border;
    //         justify-content: space-between;
    //         align-items: center;
    //         border-radius: 15px;
    //         overflow: hidden;

    //         button {
    //           > img {
    //             margin: 0;
    //           }
    //           width: 50px;
    //           align-self: stretch;
    //           border: none;
    //           background-color: #f6f7f9;
    //           opacity: 0.5;
    //           border-right: 1px solid $color-form-border;
    //         }
    //       }

    //       .dropdown-list {
    //         overflow-y: scroll;
    //         position: absolute;
    //         right: 0;
    //         transform: translateY(60px);
    //         transform-origin: bottom center;
    //         display: flex;
    //         flex-direction: column;
    //         width: 375px;
    //         border-radius: 15px;
    //         height: 0;
    //         overflow: hidden;
    //         transition: height 0.1s;
    //         background-color: #fff;
    //       }

    //       .dropdown-option {
    //         display: flex;
    //         align-items: center;
    //         gap: 15px;
    //         padding: 15px;
    //         border-bottom: 1px solid $color-form-border;
    //         cursor: pointer;

    //         &:last-child {
    //           border-bottom: unset;
    //         }

    //         > * {
    //           width: unset;
    //         }

    //         input {
    //           height: unset;
    //         }
    //         input[type="checkbox"] {
    //           width: 20px;
    //           height: 20px;
    //           padding: 0;
    //           cursor: pointer;
    //         }

    //         label {
    //           margin: 0;
    //           cursor: pointer;
    //           font-size: 20px;
    //         }
    //       }
    //     }
    //   }

    //   .checkbox-transfer {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     padding: 0;
    //     input {
    //       margin-left: 5px;
    //     }
    //     input[type="checkbox"] {
    //       height: 21px;
    //       width: 21px;
    //     }
    //     .checkmark:hover {
    //       border: 1px solid #727989;
    //     }
    //     .checkmark {
    //       right: -30px;
    //       top: 1px;
    //     }
    //     .checkbox-text {
    //       font-size: 20px;
    //       line-height: unset;
    //     }
    //   }
    // }
  }
}
