.upload-file-update {
  // font: 600 27px "Almoni";
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;

  .main-btn.long {
    font-size: 16px;
    width: 184px;
    height: 50px;
  }
  .power-of-att-info {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon-copypaste {
      height: 20px;
      width: 20px;
      margin-right: 3px;
    }
  }
  .main-btn.copy-btn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
  }
  .hide-clipboard {
    position: absolute;
    height: 0;
    width: 0;
    left: -9999px;
  }

  &.notarized {
    // background-color: lightcoral;
    // height: fit-content;
  }

  .block {
    padding-top: 45px;
  }
  h2.large-title {
    margin-bottom: 20px;
    @include larger-than-mobile {
      margin-top: 20px;
      margin-bottom: 5px;
    }
  }
  p.subtitle {
    display: none;
    @include larger-than-mobile {
      display: inline-block;
      margin-bottom: 15px;
    }
    max-width: unset;
  }

  > div {
    // background-color: lightgray;
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .upload-container {
    // background-color: lightgreen;
    position: relative;
    // height: 193px;
    margin: 0 auto;
    width: 100%;
    .text-notarized {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    @include larger-than-mobile {
      height: 300px;
      flex-direction: row;
      width: 85%;
    }

    .iGsRvx {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .drop-area {
    position: relative;
    // padding: 35px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    // background-color: red;
    text-align: center;
    // height: 303px;
    // width: 837px;
    width: 100%;
    height: fit-content;
    border: 1.5px dashed #aaaa;
    // margin-bottom: 35px;
    .inside-drop-area {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include larger-than-mobile {
        gap: 15px;
      }
      align-items: center;
      justify-content: space-around;
      @include larger-than-mobile {
        justify-content: space-between;
      }
      height: 100%;
      padding: 15px 0;
      @include larger-than-mobile {
        padding: 30px 0;
      }
      img {
        height: 32px;
        @include larger-than-mobile {
          height: 50px;
        }
      }
      h3 {
        display: none;
        @include larger-than-mobile {
          margin-bottom: 0;
          font-size: 25px;
          font-weight: 500;
          display: inline-block;
        }
      }
      p {
        font-size: 12px;
        color: #999999;
        @include larger-than-mobile {
          font-size: 15px;
          color: $color-text;
        }
      }
      // .main-btn.long {
      //   font-size: 16px;
      //   width: 184px;
      //   height: 50px;
      // }
    }
  }

  .files-container {
    margin-top: 20px;
    // width: 100%;
    margin: 0 auto;
    &.spinner {
      // padding-block-start: 50px;
      margin: 40px auto 0 auto;
    }
    // @include larger-than-mobile {
    //   margin-top: 25px;
    // }

    h3 {
      text-align: right;
      margin-top: 20px;
      margin-bottom: 10px;
      @include larger-than-mobile {
        margin-top: 5px;
      }
    }
    width: 100%;
    @include larger-than-mobile {
      width: 85%;
    }
    .titles {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      > p {
        width: 45%;
        text-align: right;
      }
      .btn-titles {
        display: flex;
        justify-content: flex-end;
        width: 10%;
        display: flex;
        gap: 15px;
      }
    }
    .file-upload {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-top: 1px solid $color-form-border;
      &:last-child {
        border-bottom: 1px solid $color-form-border;
      }

      .details {
        display: flex;
        width: 45%;
        padding-left: 20px;
        gap: 10px;
        align-items: center;
        > p {
          font-size: 12px;
          @include larger-than-mobile {
            font-size: 20px;
          }
          text-align: right;
        }
      }
      .status {
        width: 40%;
        text-align: right;
      }

      .btn-container {
        display: flex;
        align-items: left;
        justify-content: left;
        // width: 20%;
        img {
          cursor: pointer;
          width: 25px;
          height: 25px;
          @include larger-than-mobile {
            width: 35px;
            height: 35px;
            &:hover {
              transform: scale(0.9);
            }
          }
        }
        display: flex;
        gap: 8px;
        @include larger-than-mobile {
          gap: 15px;
        }
      }
    }
  }

  .cKFCzz {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-blue;
  }

  .success-msg {
    margin-top: 10px;
  }

  .dropdowns {
    // background-color: lightgreen;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include larger-than-tablet {
      width: 85%;
    }

    .print-titles {
      text-align: right;
      font: 500 22px "AlmoniBold";
      width: 100%;
    }
    .counter {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 140px;
      height: 45px;
      background-color: #f2f4f7;
      border-radius: 50px;

      .counter-button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        text-align: center;
        width: 35px;
        height: 35px;
        background-color: #d9dde5;
        color: #fff;
        border-radius: 100%;
        cursor: pointer;
        user-select: none;

        &:hover {
          opacity: 0.8;
        }
      }

      .counter-input {
        width: 50px;
        margin: 0 5px;
        text-align: center;
        padding: 0;
        border: none;
        background-color: transparent;
        font: 500 19px "AlmoniMedium";
        color: #222222;
      }
    }

    .printing-properties {
      width: 100%;
      display: flex;
      border: 1px solid #e6e9ef;
      border-radius: 3px;
      padding: 20px 15px;
      flex-direction: column;
      @include larger-than-mobile {
        padding: 20px 30px;
      }
      @include larger-than-tablet {
        flex-direction: row;
        flex-wrap: wrap;
      }
      .regular-properties {
        display: flex;
        flex-direction: column;
        width: 100%;
        @include larger-than-tablet {
          flex-direction: row;
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        .small {
          font: 500 20px "AlmoniBold";
          color: #222222;
          width: 100%;
          text-align: center;
          @include larger-than-mobile {
            text-align: right;
            margin-bottom: 20px;
          }
        }
      }
      .pages-count-container {
        padding-left: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid #e6e9ef;
        @include larger-than-mobile {
          padding-bottom: 0;
          border-bottom: none;
          border-left: 1px solid #e6e9ef;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      }
      .colorfulness-container {
        padding: 10px 0px 10px 30px;
        border-bottom: 1px solid #e6e9ef;
        @include larger-than-mobile {
          padding: 0 30px;
          border-bottom: none;
          border-left: 1px solid #e6e9ef;
          height: 100%;
          display: flex;
          flex-direction: column;
        }
        .block-select {
          flex-direction: row;
          border-bottom: unset;
          display: flex;
          align-self: flex-start;
          .form-main-toggle {
            --btn-width: 98px;
            --btn-height: 45px;
            border-radius: 10px !important;
            background-color: white;
            color: #3f4956;
            margin-top: 0;
            span {
              border-radius: 7px !important;
              &.gray {
                background-color: #acb3c2;
              }
            }
            .toggle-btn {
              color: $color-text;
              background: transparent;
              border: none;
              width: var(--btn-width);
              height: var(--btn-height);
              display: flex;
              align-items: center;
              justify-content: center;
              font: 400 17px "Almoni";
              z-index: 0;
              transition: 0.1s;
              &:active {
                border: 0;
              }

              @include larger-than-mobile {
                font: 400 21px "Almoni";
              }
            }
          }
        }
      }
      .addition-container {
        flex-grow: 2;
        padding-top: 10px;
        width: 100%;
        @include larger-than-mobile {
          padding-top: 0;
          padding-right: 30px;
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .toggles {
          display: flex;
          align-self: start;
          flex-wrap: wrap;
          grid-column-gap: 20px;
          grid-row-gap: 10px;
          flex-direction: column;
          @include larger-than-mobile {
            grid-row-gap: 0;
            grid-row-gap: 12px;
            flex-direction: row;
          }
          // .toggle-container {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   flex-direction: row;
          //   .form-main-toggle {
          //     --btn-width: 25px;
          //     --btn-height: 25px;
          //     border: 1px solid #e6e9ef;
          //     margin-left: 10px;
          //     p {
          //       font-size: 16px;
          //     }
          //     span {
          //       &.gray {
          //         background-color: #d6dce8;
          //       }
          //     }
          //   }
          // }
          // .tooltip {
          //   .top {
          //     font-size: 18px;
          //   }
          // }
        }
      }

      .tooltip {
        display: flex;
        justify-content: right;
        align-items: self-start;
        .checkbox-container {
          margin-top: 15px;
          margin-bottom: 0;
        }
        .triangle {
          top: -15px;
          border-bottom: 24px solid #323338;
          border-top: none;
        }
        .top {
          top: 125px;
          font-size: 18px;
        }
        &.upload-tooltip {
          .top {
            top: 215px;
            right: -85px;
          }
        }
      }
    }

    .copies-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 30px 0;
      .copies-actions {
        display: flex;
        flex-direction: column;
        border: 1px solid #e6e9ef;
        padding: 20px 30px;
        margin-bottom: 15px;
        @include larger-than-mobile {
          margin-bottom: 5px;
          flex-direction: row;
          // justify-content: space-evenly;
        }
        .copies-sub-title {
          text-align: right;
          font: 500 20px "AlmoniBold";
        }

        .copies-text-contianer,
        .copies-toggle {
          padding-bottom: 20px;
          border-bottom: 1px solid #e6e9ef;
          margin-bottom: 20px;

          @include larger-than-mobile {
            border-bottom: none;
            padding-bottom: 0;
            margin-left: 25px;
            border-left: 1px solid #e6e9ef;
            margin-bottom: 0;

            &.copies-text-contianer {
              padding-left: 15px;
              width: 300px;
            }
            &.recip-text-contianer {
              width: 200px;
            }

            &.copies-toggle {
              padding-left: 25px;

              .copies-sub-title {
                text-align: center;
              }
            }
          }

          p {
            text-align: right;
          }
        }
        .copies-summarize-container {
          @include larger-than-mobile {
            width: 30%;
          }
          .summarize-sub-title {
            text-align: right;
            font-size: 18px;
            font-family: "AlmoniDemiBold";
            .price-txt {
              margin-right: 15px;
              font-family: "AlmoniMedium";
            }
          }
        }
      }
    }
  }

  .number-of-copies {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include larger-than-mobile {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
    width: 100%;
    margin: 20px 0;
    @include larger-than-tablet {
      margin: 55px 0;
    }
    gap: 10px;
    @include larger-than-tablet {
      gap: 10px;
    }
    .note {
      @include larger-than-mobile {
        width: 220px;
      }
      // width: 100%;
      // width: 250px;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: center;
      .textTop {
        font-size: 19px;
        font-weight: 600;
      }
      .textBottom {
        font-size: 16px;
        @include larger-than-mobile {
          font-size: 14px;
        }
        opacity: 1;
      }
    }
    .dropdown-container {
      align-self: flex-start;
      display: flex;
      position: relative;
      width: 100%;
      @include larger-than-mobile {
        width: 265px;
      }
      .pages-to-print-text {
        font-size: 15px;
        opacity: 0.65;
        padding-right: 5px;
      }
      &.open {
        .dropdown-input button img {
          transform: scale(1, -1);
        }
        .dropdown-list {
          width: 100%;
          @include larger-than-mobile {
            width: 265px;
          }
          height: 395px;

          &.binds {
            height: 340px;
          }
          border: 1px solid $color-form-border;
          box-shadow: 5px 5px 10px 1px #0001;
          border: 1px solid transparent;
        }
      }

      .dropdown-input {
        cursor: pointer;
        // position: absolute;
        width: 100%;
        @include larger-than-mobile {
          width: 265px;
        }
        right: 0;
        display: flex;
        height: 55px;
        border: 1px solid $color-form-border;
        justify-content: space-between;
        align-items: center;
        border-radius: 15px;
        overflow: hidden;

        button {
          > img {
            margin: 0;
          }
          width: 50px;
          align-self: stretch;
          border: none;
          background-color: #f6f7f9;
          opacity: 0.5;
          border-right: 1px solid $color-form-border;
        }
      }

      .dropdown-list {
        overflow-y: scroll;
        position: absolute;
        right: 0;
        transform: translateY(-402px);
        &.binds {
          transform: translateY(-345px);
        }
        transform-origin: bottom center;
        display: flex;
        flex-direction: column;
        width: 265px;
        border-radius: 15px;
        height: 0;
        overflow: hidden;
        transition: height 0.1s;
        background-color: #fff;
      }

      .dropdown-option {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 15px;
        border-bottom: 1px solid $color-form-border;

        &:last-child {
          border-bottom: unset;
        }

        > * {
          width: unset;
        }

        input {
          height: unset;
        }
        input[type="radio"] {
          width: 20px;
          height: 20px;
          padding: 0;
        }

        label {
          margin: 0;
        }
      }
    }
  }
}
