.pop-up-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 101;

  .system-msg {
    background-color: #fff;
    border: 1px solid $color-border;
    padding: 20px 20px;
    @include larger-than-mobile {
      padding: 30px 40px;
      width: 500px;
      height: 350px;
    }
    border-radius: 20px;
    width: 300px;
    min-height: 250px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 5px 5px 10px 1px #0002;
    margin: 0 20px;
    h3 {
      font-size: 25px;
      @include larger-than-mobile {
        font-size: 30px;
      }
      margin: 0;
    }
    p {
      // margin: 0 23px;
      font-size: 16px;
      @include larger-than-mobile {
        font-size: 24px;
      }
      line-height: 1.5em;
      text-align: center;
      color: $color-text-system-msg;
    }

    .button-container {
      display: flex;
      gap: 10px;
      .main-btn {
        font-size: 18px;
        margin-top: 15px;
        width: 120px;
        height: 50px;
        @include larger-than-mobile {
          width: 165px;
          height: 60px;

          font-size: 23px;
        }
        &.undo {
          background-color: #2222;
          color: #727989;
        }
        &.mobile-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          text-align: center;
          align-items: center;
        }
      }
      &.mobile-container {
        width: 100%;
      }
    }
    .ctrl-r {
      width: 100px;
      @include larger-than-mobile {
        width: 150px;
      }
    }
    .btn-close {
      object-fit: contain;
      width: 35px;
      cursor: pointer;
      @include larger-than-mobile {
        width: 48px;
      }
    }
    .btn-error {
      object-fit: contain;
      width: 65px;
      cursor: pointer;
      @include larger-than-mobile {
        width: 115px;
      }
    }

    .msg-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-block-end: 15px;
    }
  }

  &.filters-popup {
    background: #c2c4c5;
  }
}
