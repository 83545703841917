.main-modal {
  //GENERAL FORM MODAL

  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  padding: 0;
  // height: calc(var(--app-height));
  height: 100%;
  @include larger-than-mobile {
    height: calc(var(--app-height) / 0.8);
  }
  @include larger-than-tablet {
    height: calc(var(--app-height) / 0.78);
  }
  @include larger-than-large {
    height: calc(var(--app-height) / 0.8);
  }
  width: 100%;
  background: #fff;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.5s;

  &.open {
    transform: translateX(0);
  }

  .cmp-line {
    width: 100%;
    border-bottom: 1px solid #c2cadb;
  }

  //MODAL-HEADER (icon, title, btn container)
  .header-border {
    border-bottom: 1px solid #e6e9ef;
    @include larger-than-mobile {
      border-bottom: unset;
    }
  }

  .modal-header {
    min-height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;

    @include larger-than-mobile {
      min-height: 85px;
    }

    .modal-title {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      @include larger-than-mobile {
        gap: 15px;
      }

      .order-title {
        font-weight: 500;
      }
      h3 {
        margin-bottom: 0;
      }

      > img {
        width: 35px;
        aspect-ratio: 1;
      }

      .modal-title-text {
        font-size: 15px;
      }
      .modal-title-sub {
        font-size: 14px;
        line-height: 0;
        @include larger-than-mobile {
          font-size: 17px;
        }
      }
      @include larger-than-mobile {
        > img {
          width: 45px;
          aspect-ratio: 1;
        }
        .modal-title-text {
          font-size: 27px;
          font: 500 27px "AlmoniMedium";
        }
      }
    }
    .mobile-title {
      padding: 0;
    }
    .modal-btn-container {
      display: flex;
      align-self: left;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .btn-draft {
        display: none;
      }
      .close-icon {
        height: 35px;
        width: 35px;
      }
      .arrow-back {
        height: 15px;
        width: 15px;
        color: rgb(63, 73, 86) !important;
      }
      .draft-mobile-icon {
        // display: inline-block;
        display: none;
      }
      @include larger-than-mobile {
        gap: 15px;
        .btn-draft {
          // display: flex;
          // font-size: 16px;
          display: none;
        }
        .close-icon {
          height: 50px;
          width: 50px;
        }
        .draft-mobile-icon {
          display: none;
        }
      }
    }
    .mobile-btns {
      transform: rotate(180deg);
    }
  }

  .mobile-header {
    padding: 0px 15px;
  }

  //User Notifications for Action Info
  .user-msgs {
    // border-right: 1px solid #eeeeee;
    // width: 40%;
    .msgs-title {
      font-size: 25px;
      padding-right: 15px;
    }
    .msg-list {
      border: 0;
      .user-msg-item {
        .title {
          p {
            font-size: 19px;
            color: #37383f;
          }
        }
        .order-num {
          text-align: right;
          margin: 0;
          margin-top: 10px;
        }
        .description {
          text-align: right;
        }
      }
    }
  }

  // MODAL MAIN content LAYOUT

  .modal-main-content {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, #edf3fc, #fff, #fff);
    overflow-y: auto;

    > div {
      display: flex;
      height: 100%;
      width: 100%;
      // background-color: lightcoral;
      flex-direction: column;
      justify-content: space-between;

      .form-container {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        // background-color: lightgreen;

        .main-form {
          // flex-grow: 1;
          height: calc(var(--app-height) / 0.9 - 65px - 100px - 52.3px); // 100vh - header - footer - progressbar
          // height: 60vh;
          @include larger-than-mobile {
            height: calc(var(--app-height) / 0.8 - 115px - 100px - 70.7px);
          }
          @include larger-than-tablet {
            height: calc(var(--app-height) / 0.78 - 115px - 135px);
          }
          @include larger-than-large {
            height: calc(var(--app-height) / 0.8 - 115px - 135px);
          }

          &.update-cmps {
            height: fit-content;
            .urgency-title {
              font: 700 38.5px "AlmoniDemiBold";
              text-align: center;
            }
          }

          display: flex;
          flex-direction: column;
          // background-color: lightblue;
          .modal-content-container {
            flex-grow: 1;
            // background-color: lightgray;
          }
        }
      }
    }
  }

  // ACTION MAIN content LAYOUT
  .modal-action-content {
    flex-grow: 1;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    background: linear-gradient(180deg, #edf3fc, #fff, #fff);
    text-align: -webkit-center;
    align-content: flex-start;
    @include larger-than-normal {
      align-content: unset;
    }
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    // justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    @include larger-than-mobile {
      gap: 50px;
      padding-top: 50px;
    }
    .tab-and-info {
      width: 100%;
    }
    .action-tab {
      height: 75px;
      display: flex;
      border-bottom: 1px solid #c2cadb;
      margin: -1px 0;
      width: 100%;
      margin-bottom: 50px;

      // position: relative;
      //CHECK

      @include larger-than-mobile {
        width: 96%;
      }
      p {
        font-size: 19px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #707070;

        // position: absolute;
        // padding: 20px;
        // top: 0;
        //check
        font-family: "AlmoniMedium";

        &.active {
          color: #37383f;
          border-bottom: 3px solid #4e71ff;
          font-family: "AlmoniDemiBold";
        }
      }
    }

    .action-info-cmps {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: fit-content;
    }

    .action-content-container {
      //FOR DEPLOY
      // width: 100%;
      // position: absolute;
      // top: 100px;
      // flex-grow: 1;
      // justify-content: space-around;

      // justify-content: space-around;
      //MOVED DOWN FOR DEPLOY
      // padding: 0 35px;
      display: flex;

      border: none;
      flex-direction: column;
      @include larger-than-tablet {
        // flex-basis: min-content;
        // max-width: 900px;
      }
      @include larger-than-mobile {
        flex-direction: row;
        flex-grow: 1;
        // justify-content: space-evenly;
        p {
          font-size: 18px;
        }
      }
      .line {
        height: 100%;
        border-left: 1px solid #dae0ec;
      }
      .summary-titles {
        font: unset;
        // font-size: 25px;
        margin-bottom: 20px;
        font-size: 26px;
        font-family: "AlmoniBold";
        text-align: right;
        height: 30px;
        width: 100%;
      }
      .left {
        width: 100%;
        max-width: 480px;
        border-radius: 15px;
        padding: 30px;
      }

      // justify-content: space-around;
      .border {
        border-right: 1px solid #eeeeee;
      }
    }

    .doc-content {
      justify-content: center;
      flex-direction: column;

      // min-width: 420px;
      // max-width: 470px;
      width: 100%;
      max-width: 450px;
      border-right: 1px solid #dae0ec;
      // padding-right: 50px;
      padding: 0 35px;
      @include larger-than-tablet {
        padding: 0 30px;
      }

      @include larger-than-mobile {
        // justify-content: space-around;
        justify-content: space-between;
        justify-content: flex-start;
        padding-left: 30px;
        //MOVED DOWN FOR DEPLOY

        // justify-content: flex-start;
        //ADDED FOR DEPLOY
      }
      .summary-titles {
        margin-bottom: 0;
        @include larger-than-mobile {
          margin-bottom: 20px;
        }
      }
      .docs-container {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        @include larger-than-mobile {
          flex-direction: row;
          border-top: 1px solid #c2cadb;
        }
        .docs {
          // width: 60%;
          //MOVED DOWN FOR DEPLOY
          // width: 100%;
          //ADDED FOR DEPLOY
          padding: 0px 25px;
          display: flex;
          flex: 1;
          flex-wrap: wrap;
          height: fit-content;
          justify-content: center;
          > p {
            margin-top: 5px;
          }
          .doc-card {
            cursor: pointer;
            border: 1px solid #e6e9ef;
            border-radius: 8px;
            padding: 15px;
            margin: 5px;
            height: 225px;
            width: 163px;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: space-between;
            .doc-title {
              font-size: 17px;
              word-break: break-all;
              word-break: break-word;
            }
            .doc-lastModified-title {
              font-size: 13px;
              color: #37383f;
            }
            iframe,
            img,
            a {
              border: 1px solid #cfcfcf;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
              height: 60%;
              height: 95px;
              max-width: 100%;
              margin: 0;
            }
            iframe {
              -webkit-transform: scale(0.8);
              cursor: pointer;
              // z-index: -1;
            }
          }
          .iframe-card {
            // justify-content: end;
            .doc-iframe {
              position: relative;
              a {
                position: absolute;
                height: 95px;
                width: 120px;
                right: 0;
                background: transparent;
                border: 0;
              }
            }
          }
        }
        .docs:first-child {
          border-bottom: 1px solid #dae0ec;
          padding-bottom: 20px;
          @include larger-than-mobile {
            // border-left: 1px solid #dae0ec;
            border-bottom: unset;
            padding-bottom: unset;
          }
        }
        .docs:last-child {
          padding: 20px 25px;
          @include larger-than-mobile {
            padding: 0;
          }
        }
      }
      .content-center {
        justify-content: center;
      }
    }

    .summary-content {
      .optional-details {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        // @include larger-than-mobile {
        //   flex-direction: row;
        // }
        .sub-title {
          border: 1px solid #e6e9ef;
          border-radius: 15px;
          padding: 15px;
          font: 400 15px "Almoni";
          display: flex;
          // justify-content: center;
          // align-items: center;
        }
        .action-notes {
          padding-bottom: 15px;
          text-align: -webkit-right;
          // border-bottom: 1px solid #c2cadb;
          @include larger-than-mobile {
            padding-bottom: unset;
            border-bottom: unset;
            // width: 50%;
          }
          .title {
            margin: 0;
            font-size: 25px;
            margin-bottom: 10px;
          }
          .notes {
            height: 100px;
            overflow: auto;
            margin-bottom: 15px;
          }
        }
        .action-contact {
          // @include larger-than-mobile {
          //   width: 40%;
          // }
          h3 {
            margin-bottom: 10px;
            text-align: right;
            font-size: 25px;
          }
          .authorizer {
            border-top: 1px solid #c2cadb;
            border-bottom: 1px solid #c2cadb;
          }
          .contact {
            align-items: center;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-content: center;
            padding: 15px 0;
            .title {
              font: 600 17px "Almoni";
            }
            h4 {
              margin-bottom: 7px;
            }
            p {
              margin: 0;
              width: 60%;
              height: 60px;
            }
          }
        }
      }
      .price-info-content {
        // width: 100%;
        // @include larger-than-mobile {
        //   width: 60%;
        // }
        padding: 0 30px;
        &.left {
          width: 100%;
        }

        @include larger-than-mobile {
          min-width: 320px;
          max-width: 450px;
          // padding: 30px;
          // border-left: 1px solid $color-border-finalpage;
          // border-right: 1px solid $color-border-finalpage;
          border-radius: 0;
          // border-radius: 15px;
        }
        // .title {
        //   font: unset;
        //   margin: 0;
        //   font-size: 26px;
        //   margin-bottom: 20px;
        //   font-family: "AlmoniBold";
        //   text-align: right;
        //   height: 30px;
        // }
        h3,
        h4 {
          text-align: right;
        }
        margin-bottom: 60px;
        &.no-price-action {
          border: none;
        }
        height: fit-content;
        .cost-list-item {
          border-bottom: 1px solid $color-border-finalpage;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          text-align: right;
          > h4 {
            margin-bottom: 5px;
          }
          .cost-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .cost-item-description {
              display: flex;
              justify-content: space-between;
              p.sud-title {
                width: 80%;
                text-align: -webkit-right;
              }
              p {
                line-height: 1.58em;
              }
              .price {
                width: 125px;
              }
              > .cost-item-sub-description {
                color: #222222;
                opacity: 0.65;
                // @include larger-than-mobile {
                //   width: unset;
                // }
              }
            }
            .cost-item-text {
              display: flex;
              flex-direction: column;
            }
          }
        }
        .cost-list-item:first-child {
          border-top: 1px solid #c2cadb;
        }

        .price {
          font-style: italic;
          width: 100px;
          text-align: left;
          margin-right: 20px;
        }
        .bold {
          font-weight: 600;
          color: $color-text;
          opacity: unset;
        }
        .extras {
          // border-top: 1px solid $color-border-finalpage;
          padding: 10px 0;
          width: 100%;
          .extras-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
      }
      .total {
        display: flex;
        // border-top: 1px solid $color-border-finalpage;
        padding-top: 10px;
        padding-bottom: 15px;
        // border-bottom: 1px solid #c2cadb;

        flex-direction: column;
        .total-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .price {
            flex-grow: 1;
          }
        }
      }

      .summary-info-content {
        // padding-bottom: 20px;
        width: 100%;
        // @include larger-than-mobile {
        //   width: 60%;
        // }
        // border-bottom: 1px solid $color-border-finalpage;
        // border: 1px solid #c2cadb;
        // border-radius: 15px;
        // border-left: 1px solid $color-border-finalpage;
        padding: 0 35px;
        padding-bottom: 0;
        text-align: right;
        // padding: 0px 35px 0 35px;
        height: fit-content;
        @include larger-than-mobile {
          // width: fit-content;
          min-width: 320px;
          // min-width: 420px;
          max-width: 450px;
          // width: 20%;
          // padding: 0 30px;
          // padding: 0;
        }

        // h2 {
        //   font: unset;
        //   // font-size: 25px;
        //   margin-bottom: 20px;
        //   font-size: 26px;
        //   font-family: "AlmoniBold";
        //   height: 30px;
        // }

        .order-summary-list {
          margin-bottom: 20px;
          .order-summary-item:first-child {
            // padding-top: 0;
            border-top: 1px solid #c2cadb;
          }
        }

        .order-summary-item {
          padding: 15px 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          // &:first-of-type {
          //   border-top: 1px solid $color-border-finalpage;
          // }
          border-bottom: 1px solid $color-border-finalpage;

          .textarea {
            align-items: flex-start;
            flex-direction: column;
            border-bottom: unset;
            white-space: break-spaces;
            overflow: auto;
          }
          .handed-list {
            display: flex;
            flex-direction: column;
            .item {
              display: flex;
              align-items: center;
              gap: 15px;
            }
          }
          .submiter-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }

          img {
            width: 35px;
            height: 35px;
            cursor: pointer;
            &:hover {
              transform: scale(0.95);
            }
          }
          P.details {
            opacity: 0.65;
            line-height: 1.6;
          }
          p {
            line-height: 1.1;
          }
        }
      }
    }
    > div {
      // display: flex;
      // height: 100%;
      // width: 100%;
      // // background-color: lightcoral;
      // flex-direction: column;
      // justify-content: space-between;

      .form-container {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        // background-color: lightgreen;

        .main-form {
          // flex-grow: 1;
          height: calc(var(--app-height) / 0.9 - 65px - 100px - 52.3px); // 100vh - header - footer - progressbar
          // height: 60vh;
          @include larger-than-mobile {
            height: calc(var(--app-height) / 0.8 - 115px - 100px - 70.7px);
          }
          @include larger-than-tablet {
            height: calc(var(--app-height) / 0.78 - 115px - 135px);
          }
          @include larger-than-large {
            height: calc(var(--app-height) / 0.8 - 115px - 135px);
          }

          display: flex;
          flex-direction: column;
          // background-color: lightblue;
          .modal-content-container {
            flex-grow: 1;
            // background-color: lightgray;
          }
        }
      }
    }
  }

  .action-btns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include larger-than-tablet {
      width: 380px;
      width: 420px;
    }
    .icon-wrapper {
      border: 1px solid #bac2d4;
      width: 40px;
      height: 50px;
      border-radius: 10px;
      position: relative;
      background: white;
      .bell-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .bell-icon {
          height: 30px;
        }
      }
      .UserMsgs-container {
        .notifications-list {
          top: 55px;

          .notifications-list-title {
            border-bottom: none;
            display: none;
            &.is-visible {
              display: block;
            }
          }
        }
      }
    }
    .icon-wrapper::after {
      width: 18px;
      height: 18px;
      top: 2px;
      right: 1px;
    }
    .btn {
      @include larger-than-tablet {
        border-radius: 10px;
      }
      font-family: "Almoni";
      font-size: 17px;
      background-color: transparent;
      border: 1px solid #bac2d4;
      height: 50px;
      color: #96a1b8;
      &.cancel {
        width: 50%;
        @include larger-than-tablet {
          width: 150px;
        }
      }
      &.service {
        width: 50%;
        @include larger-than-tablet {
          width: 222px;
        }
      }
      &:hover {
        transform: scale(0.99);
      }
    }
    .service {
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      span {
        padding: 0 8px;
      }
    }
    .full-width {
      width: 100% !important;
    }
  }

  .action-done {
    justify-content: left;
    gap: 5px;
    .user-msgs {
      left: 0;
    }
  }

  .progress-bar-mobile {
    display: block;

    @include larger-than-tablet {
      display: none;
    }
  }

  .modal-footer {
    // background-color: lightseagreen;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    @include larger-than-tablet {
      height: 135px;
      justify-content: space-between;
    }

    width: 100%;
    border-top: 1px solid $color-form-border;
    padding: 20px 25px;

    .progress-bar-desktop {
      display: none;
      @include larger-than-tablet {
        display: inline-block;
        width: 620px;
      }
    }

    .btn-container {
      min-width: 330px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .back-icon {
      width: 60px;
      aspect-ratio: 1;
      border: none;
      background: $color-backgroung-button;
      cursor: pointer;
      border-radius: 50px;
      margin-left: 15px;

      &.hidden {
        visibility: hidden;
      }
    }
  }
  @include larger-than-normal {
    width: 74%;
  }
  @include larger-than-tablet {
    .modal-title-text {
      display: inline-block;
    }
    .draft-mobile-icon {
      display: none;
    }
    .close-icon {
      display: inline-block;
      width: 65px;
    }
  }
}
