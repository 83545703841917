.header-container {
  min-height: 60px;
  box-shadow: none;
  //FOR HEADER FIXED
  // position: fixed;
  width: 100%;
  z-index: 11;
  //For EmergencyMsgs
  &.emergency-msgs {
    background-color: #4d85ff;
    background-color: #4d85ff;

    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    min-height: auto;
    z-index: unset;
    p{
      color: white;
    }
    a{
      color: white;
    }
    @include larger-than-mobile {
      font-size: 19px;
    }
    .close-icon {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 10px;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      img {
        height: 15px;
        width: 15px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
  }
  > div {
    height: 100%;
    background-color: #4e71ff;

    .header-right-side {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    .inner-header-container {
      display: flex;
      gap: 10px;
      flex-grow: 1;
      justify-content: space-between;
      align-items: center;
      .logo-icon {
        cursor: pointer;
      }
    }
  }
  .cart-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    position: relative;
  }

  .menu-icon {
    color: white;
    cursor: pointer;
    > img {
      width: 24px;
      height: 24px;
    }
  }
  .avater-small {
    vertical-align: middle;
    border-radius: 50%;
  }
  .badge {
    position: absolute;
    font-size: 10px;
    line-height: 1.6;
    top: 11px;
    right: 0px;
    width: 14px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: white;
    color: $color-blue;
  }
  .login-icon {
    color: white;
    // border: 1px solid white;
    // padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    // display: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    > img {
      vertical-align: middle;
      border-radius: 50%;
      width: 34px;
      height: 34px;
      border-radius: 50px;
      object-fit: contain;
    }
    > p {
      color: white;
      font-family: "AlmoniLight";
      border: #cbd2e0;
      font-size: 18px;
      @include larger-than-mobile {
        font-size: 22px;
      }
    }
  }

  .icon-wrapper {
    position: relative;
    .bell-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      .bell-icon {
        height: 30px;
      }
    }
    .UserMsgs-container {
      .notifications-list {
        top: 45px;
        right: -58px;
        width: 475px;
        //For fixed notifications not done
        // position: fixed;
        // top: 60px;
        // right: 0;
        .action-info-msg-list {
          width: 475px;
          //For fixed notifications not done
          // position: fixed;
          // top: 150px;
          &.is-visible {
            height: calc(var(--app-height) / 0.8 - 130px);
          }
        }
        .notifications-header {
          .svg-div {
            margin-top: 5px;
          }
        }
        .notifications-list-title {
          font: 600 25px "AlmoniBold";
        }
      }
    }
  }
}
