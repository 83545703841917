.court-name {
    // padding-top: 65px !important;
    // padding-bottom: 65px !important;
    padding: 30px 45px 35px 45px !important;
    p.subtitle {
        // height: 60px;
        margin-bottom: 15px;
    }

    .optional-search {
        min-height: 130px;
        .input-container {
            margin-bottom: 15px;
            align-items: baseline;
            grid-row-gap: 0;
            grid-column-gap: 0;
            @include larger-than-mobile {
                grid-row-gap: 12px;
                grid-column-gap: 13px;
                margin: 0;
            }
            .field {
                width: 100%;
                height: auto;
                margin: 0;
                height: 110px;
                @include larger-than-mobile {
                    height: 110px;
                    width: fit-content;
                }
                .med {
                    width: 225px;
                }
                .small {
                    width: 85px;
                }
            }
            .field.extra-height {
                width: 100%;
                @include larger-than-mobile {
                    width: 300px;
                }
                height: 150px;
                input {
                    width: 100%;
                }
            }
            .field.large {
                width: 100%;
                @include larger-than-mobile {
                    width: 300px;
                }
            }
            .small {
                width: 85px;
            }
        }
    }

    .none {
        display: none;
    }

    .checkbox-d {
        margin-top: 10px;
        .checkbox-container {
            padding: 0;
            margin: 0;
            .checkmark {
                right: -25px;
            }
        }
    }
}
