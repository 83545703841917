.matching-recipients {
    width: 100%;
    .matching-recipient {
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        justify-content: center;
        width: 90%;
        .recipient-name {
            flex-grow: 1;
        }
        .counter {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            width: 140px;
            height: 45px;
            background-color: #f2f4f7;
            border-radius: 50px;

            .counter-button {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                text-align: center;
                width: 35px;
                height: 35px;
                background-color: #d9dde5;
                color: #fff;
                border-radius: 100%;
                cursor: pointer;
                user-select: none;

                &:hover {
                    opacity: 0.8;
                }
            }

            .counter-input {
                width: 50px;
                margin: 0 5px;
                text-align: center;
                padding: 0;
                border: none;
                background-color: transparent;
                font: 500 19px "AlmoniMedium";
                color: #222222;
            }
        }
    }
}
