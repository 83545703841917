.footer-layout.full.desktop-footer {
  // display: none;
  border-top: 1px solid $color-form-border;
  border-bottom: 1px solid $color-form-border;

  @include larger-than-tablet {
    display: grid;
  }
}

.footer-layout.full.mobile-footer {
  position: fixed;
  bottom: 0;
  height: 70px;
  width: 100%;
  background-color: #fff;
  display: flex;
  border-top: 1px solid $color-form-border;
  border-bottom: 1px solid $color-form-border;

  button {
    flex-grow: 1;
  }

  @include larger-than-tablet {
    display: none;
  }
}

.main-footer {
  justify-content: center;
  //  width: 1340px;
  display: flex;
  align-items: center;
  // flex-direction: column;
  padding: 30px 0;
  gap: 20px;

  .common-footer {
    display: none;

    @include larger-than-tablet {
      display: unset;
    }
    h3 {
      font: 600 25px "AlmoniDemiBold";
    }
    width: 100%;
    .actions {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px 5px;
      .action-footer {
        width: fit-content;
        padding: 7px 10px 7px 10px;
        border: 1px solid $color-form-border;
        border-radius: 7px;
        font-size: 14px;
        color: #8a8c8f;
        &:hover {
          background: rgba(199, 199, 199, 0.133);
          cursor: pointer;
        }
      }
    }
  }

  .chat-card {
    display: flex;
    flex-direction: row;
    align-self: start;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 150px;

    @include larger-than-mobile {
      flex-direction: row;
      align-self: center;
      padding: 15px;
      width: 50%;
    }
    @include larger-than-tablet {
      padding: 15px;
      width: 40%;
    }
    border-radius: 25px;
    min-width: 40%;
    height: 135px;
    min-width: 370px;
    padding: 20px;
    background: #f2f9ff;
    img {
      align-self: start;
      margin-top: 10px;
      @include larger-than-mobile {
        align-self: unset;
        margin-top: 0;
      }
    }
    .main-content {
      display: flex;
      flex-direction: column;
      gap: 15px;
      @include larger-than-mobile {
        flex-direction: row;
        align-items: center;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      p.p1 {
        font-weight: 500;
        color: $color-text;
        opacity: unset;
      }
      p.p4 {
        color: $color-text-system-msg;
      }
    }
  }

  @include larger-than-large {
    flex-direction: row;
    justify-content: space-between;

    .chat-card {
      min-width: 40%;
    }

    .common-footer {
      width: 60%;
    }
  }
}

.footer-end {
  display: none;

  @include larger-than-tablet {
    display: flex;
  }
  p {
    opacity: 0.65;
  }
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  color: #727989;
  border: none;

  .footer-nav {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      display: flex;
      align-items: center;
    }
  }
}

.mobile-footer {
  p {
    font-family: "Almoni";
  }
  box-shadow: 0px 5px 40px -6px $color-text;
  .btn-icon-footer {
    background-color: #fff;
    border: none;
    .cart-icon {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      position: relative;
      display: inline-block;
    }
    .badge {
      position: absolute;
      font-size: 7px;
      top: 11px;
      right: 0px;
      width: 14px;
      aspect-ratio: 1;
      border-radius: 50%;
      background-color: $color-blue;
      color: white;
    }
  }
}

.btn-icon-footer {
  position: relative;
  overflow: hidden;

  .coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #fcb948;
    color: white;
    left: -22px;
    top: 10%;
    text-align: center;
    transform: rotate(-47deg);
    width: 80px;
    height: 25px;
    position: absolute;
  }
}
