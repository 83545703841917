.user-orders {
  .filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #c2cadb;
    border-bottom: 1px solid #e6e9ef;
    flex-direction: row;
    // @include larger-than-normal {
    //   flex-direction: row;
    // }
    .filters {
      display: flex;
    }
    .dropdown-address-container {
      width: 275px;
      margin-left: 10px;
      .datePicker {
        visibility: hidden;
        height: 0;
        padding: 0;
        width: 0;
        position: absolute;
      }
      .dropdown-input {
        width: 275px;
        height: 50px;
        ::placeholder {
          color: #3f4956;
        }
        input {
          background-color: white;
        }
        button {
          width: 100px;
          background-color: white;
        }
      }
      /* width */
      .dropdown-list::-webkit-scrollbar {
        width: 0.5em;
        background-color: white;
        border-radius: 100%;
      }
      /* Handle */
      .dropdown-list::-webkit-scrollbar-thumb {
        background: #c2cadb;
        border-radius: 12px;
      }
      /* Handle */
      .dropdown-list::-webkit-scrollbar-track {
        background: #e6e9ef;
        border-radius: 12px;
        margin: 10px 0;
      }

      .dropdown-list {
        width: 275px;
        direction: ltr;
        transform: translateY(50px);
        .dropdown-option {
          width: 100%;
          padding-right: 25px;
          align-self: center;
          &:hover {
            background-color: #eee;
          }
          input[type="radio"] {
            width: 20px;
            height: 20px;
            padding: 0;
          }
          p {
            font-size: 16px;
          }
        }
      }
      input {
        font-size: 16px;
      }
    }

    .btn-filter-action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 131px;
      gap: 5px;
      background-color: #fafbfc;
      padding: 10px 15px;
      border: 1.5px solid #edf0f4;
      color: #8b939c;
      border-radius: 10px;
      font-size: 15px;
      font-family: "Almoni";
      // margin: 5px;
      margin-right: 7px;
      img {
        height: 15px;
        width: 15px;
      }
      &:hover {
        // border: 1px solid rgb(148, 147, 147);
        background-color: #eee;
      }
    }

    .filter-actions {
      display: flex;
      .dropdown-export-documents {
        .dropdown-address-container {
          width: 200px;
          margin-left: 0;
          @include larger-than-large {
            width: 250px;
          }
          .dropdown-input {
            width: 200px;
            @include larger-than-large {
              width: 250px;
            }
            button {
              width: 75px;
              @include larger-than-large {
                width: 100px;
              }
            }
          }
          .dropdown-list {
            width: 200px;
            overflow-y: hidden;
            max-height: 140px;
            @include larger-than-large {
              width: 250px;
              max-height: 155px;
            }
            .dropdown-option {
              padding-right: 0;
              .btn-filter-action {
                border: 0;
                width: 100%;
              }
            }
          }
        }
      }
      .btn-filter-action {
        width: 45px;
        height: 45px;
        margin-right: 0;
        @include larger-than-large {
          width: 92px;
          height: 50px;
          width: 100%;
          border: 0;
        }
        .spinner {
          svg {
            height: 25px;
            width: 25px;
          }
        }
      }
    }
  }
  .title-search-container {
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 0;
    @include larger-than-mobile {
      padding-top: 20px;
      padding-bottom: 10px;
      flex-direction: row;
    }
    input {
      width: 180px;
      height: 50px;
      font-size: 30px;
      color: $color-placeholder;
      border: none;
      line-height: 1;
    }
    .search-field {
      width: 100%;
      font-size: 17px;
      background-color: white;
      height: 55px;
      justify-content: space-between;
      @include larger-than-mobile {
        width: 300px;
      }
      &:focus-within {
        border-color: #727989;
      }
      padding: 0 15px;
      margin: 10px 0 10px 0;
      // margin: 10px 0 25px 0;
      border: 1px solid $color-form-border;
      // border: 1px solid #3f4956;
      border-radius: 15px;
      display: flex;
      gap: 15px;
      img {
        color: #e6e9ef;
        height: 50px;
        width: 20px;
      }
      .search-input {
        color: $color-text;
        width: 350px;
        font-size: 17px;
        padding: 0;
        opacity: 1;
        color: #212129;
        &::placeholder {
          color: $color-text;
          color: #a1a9b0;
          font-size: 16px;
          // @include larger-than-mobile {
          //   font-size: 25px;
          // }
          opacity: 0.65;
        }
        &:hover,
        &:focus {
          border: none;
        }
      }
    }
  }
  h3.title {
    // margin: 15px 0 15px 0;
    margin-bottom: 5px;
    margin-top: 25px;
    font-size: 21px;
    font-family:
      "AlmoniDemiBold",
      "Almoni",
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
    color: #37383f;
    @include larger-than-mobile {
      font-size: 28px;
      margin-bottom: 25px;
      // margin: 0;
    }
    &.in-progress {
      margin: 0;
    }
  }
  p {
    font-size: 15px;
    // margin-top: 10px;
    @include larger-than-tablet {
      font-size: 17px;
      // margin-top: 10px;
    }
  }
  .total-item {
    p {
      text-align: right;
      line-height: 1.58em;
    }
  }
  .s-title {
    color: #3f4956;
    opacity: 0.75;
  }
  padding: 10px 20px 0 20px;
  @include larger-than-tablet {
    padding: 40px 57px 0 57px;
  }

  .orders-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    height: 400px;
    overflow-y: scroll;
    padding-left: 10px;
    @include larger-than-mobile {
      justify-content: unset;
      // margin: 25px 0;
      // margin-top: 25px;
      margin-bottom: 0;
    }
  }

  .temp {
    display: none;
  }

  .order-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 15px;
    height: fit-content;
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    background-color: white;
    border: 1px solid $color-form-border;
    @include larger-than-mobile {
      width: 250px;
      min-height: 324px;
      height: auto;
    }
    .head {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        gap: 6px;
        font-size: 13px;
        align-items: center;
        p {
          margin: 0;
          line-height: 1.4;
          font-family:
            "AlmoniMedium",
            "Almoni",
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            sans-serif;
        }
        .notific-icon {
          width: 17.5px;
          .dot {
            //   fill: red;
          }
        }
      }
      img {
        cursor: pointer;
        &:hover {
          transform: scale(0.95);
        }
      }
    }
    .title {
      text-align: center;
      font-size: 19px;
      text-align: right;
      color: #37383f;
      font-family:
        "AlmoniDemiBold",
        "Almoni",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
      @include larger-than-mobile {
        text-align: center;
        font-size: 23px;
      }
    }
    .date-time {
      font-family: "Almoni";
      display: flex;
      justify-content: center;
      gap: 15px;
      justify-content: flex-start;
      @include larger-than-mobile {
        justify-content: center;
      }
    }
    .devider {
      height: 2px;
      width: 90%;
      border-bottom: 1px solid #eeeeee;
    }
    .details-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .details {
        // display: flex;
        // justify-content: space-between;
        // font-size: 13px;
        // opacity: 0.85;
        justify-content: center;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        grid-gap: 10px;
        min-height: 20px;
        padding: 2px 0;
        // gap: 10px;
        .lines {
          display: flex;
          flex-direction: column;
        }
        .icon {
          height: 36px;
        }
        h4 {
          font-size: 16px;
        }
        p {
          margin: 0;
          padding: 2px 0px;
          text-align: justify;
        }
      }
      .italic {
        justify-content: flex-end;
        font-weight: 600;
      }
      .action-header {
        justify-content: center;
        align-items: center;
        padding-bottom: 2px;
        h4 {
          font-family:
            "AlmoniBold",
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            sans-serif;
          font-size: 16px;
        }
        p {
          font-family:
            "Almoni",
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            "Roboto",
            "Oxygen",
            "Ubuntu",
            "Cantarell",
            "Fira Sans",
            "Droid Sans",
            "Helvetica Neue",
            sans-serif;
          font-weight: 400;
          font-size: 18px;
        }
      }
    }
    .btn {
      font-family: "Almoni";
      font-size: 16px;
      margin-top: 20px;
      background-color: transparent;
      border: 1px solid #37383f;
      width: 100%;
      height: 40px;
      border-radius: 10px;
      &:hover {
        transform: scale(0.99);
      }
      &.disabled {
        color: #b5bdcc;
        border: 1px solid #b5bdcc;
        &:hover {
          transform: unset;
          cursor: unset;
        }
      }
    }
  }
}
