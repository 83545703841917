.main-checkout {
  width: 100%;
  display: flex;
  flex-direction: column;

  background: linear-gradient(180deg, #edf3fc, #fff, #fff);

  .icon-input {
    width: 100%;
    @include larger-than-mobile {
      width: 350px;
    }
  }

  .iframe-container {
  }
  iframe {
    border: 1px solid $color-form-border;
    margin-top: 50px;
    width: 100%;
    @include larger-than-mobile {
      border: none;
      width: 765px;
    }
  }

  .back-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    // border-radius: 7px;
    @include larger-than-mobile {
      border-radius: 15px;
    }
    width: 125px;
    height: 30px;
    @include larger-than-mobile {
      width: 175px;
      height: 50px;
    }
    // background-color: transparent;
    // border: 1px solid $color-icon-regular;
    p {
      font-size: 13px;
      // color: #96a1b8;
      color: white;
      @include larger-than-mobile {
        font-size: 16px;
      }
    }
    &:hover {
      // border: 1px solid $color-icon-hover;
      // .back-small-icon {
      //   path {
      //     stroke: $color-icon-hover;
      //   }
      // }
      // p {
      //   color: $color-icon-hover;
      // }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    p {
      > span {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    // max-width: 800px;
    .content-bottom {
      h3 {
        font-size: 27px;
        font-family: "AlmoniBold";
        color: #3f4956;
      }
      & .inputs {
        display: flex;
        gap: 20px;
        flex-direction: column;
        .input-container {
          .disable-input {
            background-color: $color-background-disabled;
          }
        }
        & .field {
          height: unset;
        }
        @include larger-than-mobile {
          flex-direction: row;
        }
      }
    }
    .checkout-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h2 {
      font-size: 27px;
      // padding-bottom: 24px;
      border-bottom: 1px solid #dae0ec;
      @include larger-than-mobile {
        border-bottom: unset;
        // padding-bottom: 24px;
        font-size: 45px;
      }
    }
    h3 {
      font-size: 21px;
      margin-top: 20px;
      @include larger-than-mobile {
        font-size: 28px;
      }
      margin-bottom: 20px;
      width: 100%;
    }
    .main-input-container {
      display: flex;
      max-width: 800px;
      flex-direction: column;
      @include larger-than-mobile {
        flex-direction: row;
      }
      flex-wrap: wrap;
      // width: 938px;
      // gap: 20px;
      height: fit-content;

      .input-container {
        display: flex;
        flex-direction: column;
        gap: 0px;
        input {
          width: 100%;
          @include larger-than-mobile {
            width: 325px;
          }
        }
      }
    }
    .submit-area {
      display: flex;
      flex-direction: column;

      .input-container-checkbox {
        display: flex;
        flex-direction: row;
        // justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        // margin-top: 20px;
        label {
          margin: 0;
        }
        .red-terms {
          color: red;
        }
        .checkbox-input {
          width: 15px;
          height: 15px;
          border: 1px solid $color-form-border;
          cursor: text;
        }
        a {
          color: blue;
        }
      }
      button {
        width: 350px;
      }
    }
  }
}
