.what-served-regist {
  p.p-med-bold {
    // max-width: 55%;
    margin-bottom: 0;
    font-size: 18px;
    min-width: 160px;
    font-weight: 500;
    // max-width: 190px;
    @include larger-than-mobile {
      font-size: 22px;
      min-width: 225px;
    }
  }

  .unwidth {
    min-width: unset !important;
  }

  .selected-item.regist {
    display: flex;
    gap: 20px;
    @include larger-than-mobile {
      flex-direction: row;
    }
    &:last-child {
      border-bottom: 1px solid #e6e9ef;
    }
  }

  .input-container {
    margin-left: 30px;
    display: flex;
    align-items: center;
    & .tooltip {
      position: absolute;
      left: 0;
      @include larger-than-mobile {
        position: relative;
      }
    }
  }

  .checkbox-toll {
    display: flex;
    align-items: center;
    gap: 7px;
    input[type="checkbox"] {
      accent-color: $color-blue;
      width: 20px;
      height: 20px;
      margin: 0;
    }
    label {
      font-weight: 400;
      margin: 0;
    }
  }
  .tooltip-container {
    .btn.info {
      font-family: "Almoni";
      height: 35px;
      background-color: transparent;
      border: 1px solid $color-icon-regular;
      padding: 5px 10px;
      border-radius: 7px;
      white-space: nowrap;
      &:hover {
        border: 1px solid $color-text;
      }
    }
  }
  .btn-container {
    display: flex;
    gap: 10px;
  }
  .toggle-option-radio {
    display: none;
  }
  div.toggle {
    border: 1px solid $color-border;
    background-color: transparent;
    position: relative;
    width: 122px;
    height: 60px;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    span {
      display: none;
      background-color: $color-blue;
      box-shadow: 3px 3px 5px rgb(77, 98, 185, 0.2);
      border-radius: 10px;
      border: 1px solid $color-blue;
      width: 66px;
      height: 60px;
      position: absolute;
      z-index: 0;
      right: -1px;
      top: -1px;
      transition: 0.1s;
      &:active {
        border: 0;
      }
    }

    // overflow: hidden;
    .togglt-option {
      background-color: transperent;
      z-index: 1;
      height: 60px;
      width: 66px;
      display: flex;
      // border-radius: 10px;
      font-size: 18px;
      font-weight: 500;
      justify-content: center;
      align-items: center;
    }

    &.left {
      .togglt-option:nth-child(2) {
        color: #fff;
        opacity: unset;
      }
      .togglt-option:nth-child(2) {
        border: none;
      }
      span {
        right: 59px;
        display: unset;
      }
    }
    &.right {
      .togglt-option:nth-child(1) {
        color: #fff;
        border: none;
        opacity: unset;
      }
      span {
        right: -1px;
        display: unset;
      }
    }

    &.dis-company {
      .togglt-option:nth-child(1) {
        color: #3f4956;
      }
      span {
        background-color: #acb3c2;
        border: 1px solid #acb3c2;
      }
    }
  }
}
