.power-of-attorney {
  position: relative;
  .modal-content-container {
    align-items: center;
  }

  .modal-content-container.block {
    width: 100%;
    padding: 5px;
  }

  .form-main-toggle.left button:nth-child(3) {
    z-index: 0;
  }

  .upload-file {
    width: 100%;
    height: fit-content !important;
    h2 {
      margin: 10px;
      font-size: 30px;
    }
  }

  p.subtitle {
    margin-bottom: 15px;
  }
  .btns {
    display: flex;
    gap: 20px;
  }
  .btn-print {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: white;
    padding: 10px 15px;
    border: 1px solid $color-form-border;
    img {
      height: 20px;
      width: 20px;
    }
    &:hover {
      border: 1px solid rgb(148, 147, 147);
    }
    color: $color-text;
    border-radius: 10px;
    font-size: 16px;
    font-family: "Almoni";
    margin: 15px;
  }
  .to-print {
    align-self: center;
    margin-bottom: 30px;
    width: 794px;
  }
  // .pa-container {
  //   margin-top: 30px;
  //   text-align: right;
  //   background-color: white;
  //   width: 90%;
  //   @include larger-than-mobile {
  //     width: 50%;
  //   }
  //   display: flex;
  //   flex-direction: column;
  //   gap: 25px;
  //   height: fit-content;
  //   margin-bottom: 40px;
  //   border: 1px solid $color-form-border;
  //   p {
  //     font-size: 16px;
  //     @include larger-than-tablet {
  //       font-size: 17px;
  //     }
  //   }
  //   &.hidden {
  //     position: absolute;
  //     top: -9999px;
  //     left: -9999px;
  //   }
  // }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }

  .modal-main {
    position: fixed;
    background: white;
    width: 420px;
    height: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .container {
      .btn-container-modal {
        display: flex;
        gap: 10px;
        justify-content: center;
      }
    }
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }
  .sigContainer {
    zoom: 1.1;
    @include larger-than-mobile {
      zoom: 1.265;
    }
    width: 300px;
    height: 200px;
    margin: 20px auto;
    border: 1px solid #e6e9ef;
    .sigPad {
      width: 100%;
      height: 100%;
    }
  }
  .sigImage {
    align-self: center;
    margin-right: 80px;
    width: 50px;
    @include larger-than-mobile {
      width: unset;
    }
  }

  .btn-cont {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-bottom: 30px;
    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 20px;
    }
  }
}
