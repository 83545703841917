.pop-up-modal-order {
  .number-order {
    width: 300px;
    height: 65px;
    text-align: center;
    border: 1px solid #bac2d4;
    padding: 15px 20px;
    border-radius: 8px;
    p.number-order-text {
      font-size: 17px;
      color: #96a1b8;
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  backdrop-filter: blur(3px);

  .system-msg {
    position: relative;
    background: url("../../images/6464.png"),
      linear-gradient(180deg, #edf3fc, #fff, #fff);
    @include larger-than-mobile {
      height: 650px;
      width: fit-content;
    }
    background-size: 100% 100%;

    background-color: #fff;
    background-repeat: no-repeat;
    border: 1px solid $color-border;
    padding: 0 30px;
    border-radius: none;
    @include larger-than-mobile {
      border-radius: 40px;

      padding: 30px 50px;
    }
    width: 100%;
    height: 100%;
    @include larger-than-mobile {
      height: 650px;
      width: fit-content;
      background-size: 650px 700px;
    }
    display: flex;
    flex-direction: column;

    @include larger-than-mobile {
      justify-content: center;
    }
    align-items: center;
    gap: 15px;
    box-shadow: 5px 5px 10px 1px #0002;
    .btn-close {
      width: 40px;
      aspect-ratio: 1;
      cursor: pointer;
      position: absolute;
      z-index: 5;
      top: 20px;
      left: 20px;
    }
    .msg-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      width: 100%;
      margin: 15px 0;
      @include larger-than-mobile {
        margin-top: 90px;
      }
      margin-top: 130px;

      h3 {
        font-size: 40px;
        text-align: center;
        margin: 0;
      }
      p.decription {
        opacity: none;
        font-size: 19px;
        text-align: center;
        color: #000;
        margin-bottom: 20px;
      }
      .main-btn {
        font-size: 18px;
      }
    }
  }
}
