.dropdown-address-container {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    @include larger-than-mobile {
        // width: 300px;
    }
    &.open {
        .dropdown-input button img {
            transform: scale(1, -1);
        }
        .dropdown-list {
            height: 220px;
            text-align: right;
            border: 1px solid $color-form-border;
            box-shadow: 5px 5px 10px 1px #0001;
            border: 1px solid transparent;
            overflow-y: scroll;
            p{
                font-size: 20px;
            }
        }
    }

    &.med {
        width: 285px;
    }
    &.large {
        width: 300px;
    }

    .dropdown-input {
        right: 0;
        display: flex;
        width: 100%;
        height: 60px;
        // @include larger-than-mobile {
        //     width: 300px;
        // }
        // height: 60px;
        border: 1px solid $color-form-border;
        justify-content: space-between;
        align-items: center;
        border-radius: 15px;
        overflow: hidden;

        > input {
            border: none;
            font-size: 16px;
        }

        button {
            > img {
                margin: 0;
            }
            width: 50px;
            align-self: stretch;
            border: none;
            // background-color: #f6f7f9;
            background-color: white;
            opacity: 0.5;
            border-right: 1px solid $color-form-border;
        }
    }
    .dropdown-list {
        position: absolute;
        right: 0;
        transform: translateY(63px);
        display: flex;
        flex-direction: column;
        width: 100%;
        @include larger-than-mobile {
            width: 300px;
        }
        border-radius: 15px;
        height: 0;
        overflow: hidden;
        transition: height 0.2s;
        background-color: #fff;
        z-index: 2;
    }
    .dropdown-list.med {
        width: 100%;
        @include larger-than-mobile {
            width: 285px;
        }
    }

    .dropdown-option {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // gap: 15px;
        border-bottom: 1px solid $color-form-border;

        > p {
            margin: 0;
            width: 100%;
        }
        > div {
            display: flex;
            justify-content: flex-end;
        }
        .btn-container {
            img {
                height: 25px;
                width: 25px;
                &:hover {
                    transform: scale(0.9);
                }
            }
        }

        &:last-child {
            border-bottom: unset;
        }

        > * {
            // width: 100%;
            cursor: pointer;
            padding: 15px;
        }

        input {
            height: unset;
        }

        label {
            margin: 0;
        }
    }
}

.checkbox-container {
    display: flex;
    align-content: center;
    align-items: center;
    // margin-right: 10px;
    width: 100%;
    justify-content: right;
    // margin: 5px 0px 15px;
    // margin-top: 10px;
    // margin-bottom: 20px;
    margin: 10px 0;
    @include larger-than-mobile {
        // justify-content: center;
        margin-top: auto;
        margin-top: 5px;
        width: auto;
        margin-bottom: 12px;
    }
    .checkbox-content {
        margin-right: 25px;
    }
    .checkmark {
        top: 5px;
        margin-left: 5px;
    }
    label {
        margin: 0;
        font-family: "AlmoniDemiBold", "Almoni", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }
}

.save-contact-checkbox {
    .checkbox-content {
        font-size: 19px;
        font-weight: 600;
    }
    .checkmark {
        top: 5px;
    }
}
