.form7-first-submission-or-copy {
  .modal-content-container {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  h2.large-title {
    margin-bottom: 15px;
  }
  .label-txt {
    opacity: 0.65;
    max-width: 480px;
  }
  .agra-payment {
    margin: 20px 0;
    width: fit-content;
    h3 {
      font-size: 19px;
      margin-bottom: 15px;
      @include larger-than-mobile {
        // margin: 40px 0;
        font-size: 25px;
      }
    }
    @include larger-than-mobile {
      margin: 40px 0;
      // font-size: 25px;
    }

    label {
      font-size: 15px;
      margin-bottom: 5px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    &.hide {
      visibility: hidden;
    }

    .hidden-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      input {
        text-align: center;
        font: 400 19px "Almoni";
        color: #212129;
      }
      p {
        text-align: right;
      }

      .icon {
        font-size: 35px;
      }
    }
  }
}
